import { useEffect, useRef, useState } from "react";
import { API_ENDPOINTS, API_METHODS } from "../../constants/endpoints";
import { whitelabel_id } from "../../constants/ids";
import apiCall from "../../utils/apiCall";
import toastr from 'toastr'
import { Link, useNavigate } from "react-router-dom";
const CompanyBoardMembers = () => {
    const navigate = useNavigate()
    const authUser = JSON.parse(localStorage.getItem("auth_user"))
    const dateRef = useRef();
    const countryRef = useRef();
    const address_countryRef = useRef();
    const genderRef = useRef();
    const roleRef = useRef();
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState({})
    const [inputs, setInputs] = useState({ first_name: '', last_name: '', address: '', zip: '', city: '' })
    const [countries, setCountries] = useState([])
    const [roles, setRoles] = useState([])
    const [validationRules, setValidationRules] = useState({
        first_name: "First Name is required",
        last_name: "Last Name is required",
        address: "Residence address is required.",
        zip: "Zip is required",
        city: 'city is required',
    });
    const [selectValidationRules, setSelectValidationRules] = useState({
        gender: "Gender is required",
        dob: "Date of Birth is required",
        role: "Director role is required",
        country: 'Nationality is required',
        address_country: 'Residence country is required'
    })
    useEffect(() => {
        const scriptUrls = [
            "js/plugins-init/select2-init.js",
            "vendor/select2/js/select2.full.min.js",
            "vendor/bootstrap-material-datetimepicker/js/bootstrap-material-datetimepicker.js",
            "js/plugins-init/material-date-picker-init.js",
            "vendor/bootstrap-daterangepicker/daterangepicker.js",
            "vendor/moment/moment.min.js",
        ];
        scriptUrls.forEach((scriptUrl) => {
            const script = document.createElement("script");
            script.src = scriptUrl;
            script.async = true;
            document.body.appendChild(script);
        });

        return () => {
            scriptUrls.forEach((scriptUrl) => {
                const script = document.querySelector(`[src="${scriptUrl}"]`);
                if (script) {
                    script.remove();
                }

            });
        };


    }, []);
    useEffect(() => {
        if (authUser && authUser?.is_live == 1) {
            return navigate('/balance')
        }
        getCountryList()
        getDirectorRoles()
    }, [])

    const handleSubmit = async () => {
        setErrors({})
        if (!validateInputs()) {
            return;
        }
        setLoading(true)

        const { web_token, user_id } = authUser

        const body = {
            whitelabel_id: whitelabel_id,
            user_id: user_id,
            web_token: web_token,
            dob: dateRef.current.value,
            first_name: inputs.first_name,
            last_name: inputs.last_name,
            country: countryRef.current.value,
            address_country: address_countryRef.current.value,
            gender: genderRef.current.value,
            role: roleRef.current.value,
            address: inputs.address,
            city: inputs.city,
            zipcode: inputs.zip
        }

        const response = await apiCall(API_ENDPOINTS.ADD_COMPANY_DIRECTOR, API_METHODS.POST, body)
        if (response?.status == 1) {
            setLoading(false)
            toastr.success(response?.message || 'Director added  successfully.')
            navigate('/account-confirmation')

        } else if (response?.status == 0) {
            toastr.error(response?.message)
            setLoading(false)

        } else {
            toastr.error('Unable to update.')
        }
    }

    const validateInputs = () => {
        const errors = {};
        Object.keys(inputs).forEach((field) => {
            if (!inputs[field]) {
                errors[field] = validationRules[field];
            }
        });

        if (!countryRef.current.value) {
            errors.company_country = selectValidationRules.company_country;
        }
        if (!address_countryRef.current.value) {
            errors.address_country = selectValidationRules.address_country;
        }
        if (!countryRef.current.value) {
            errors.country = selectValidationRules.country;
        }
        if (!roleRef.current.value) {
            errors.role = selectValidationRules.role;
        }
        if (!genderRef.current.value) {
            errors.gender = selectValidationRules.gender;
        }
        if (!dateRef.current.value) {
            errors.dob = selectValidationRules.dob;
        }


        setErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const getCountryList = async () => {
        const body = {
            whitelabel_id: whitelabel_id
        }
        const url = API_ENDPOINTS.GET_COUNTRY_LIST
        const method = API_METHODS.POST
        const response = await apiCall(url, method, body)
        if (response?.status == 1) {
            const sortedCountries = response.country.sort((a, b) => a.name.localeCompare(b.name));
            setCountries(sortedCountries)
            setLoading(false)
        } else if (response?.status == 0) {
            toastr.error('Unable to get Countries list.')
            setLoading(false)
        } else {
            toastr.error('Unable to get Countries list.')
        }
    }
    const getDirectorRoles = async () => {
        const body = {
            whitelabel_id: whitelabel_id
        }
        const url = API_ENDPOINTS.GET_DIRECTOR_ROLE
        const method = API_METHODS.POST
        const response = await apiCall(url, method, body)
        if (response?.status == 1) {
            setRoles(roleObjectToArray(response.role))
            setLoading(false)
        } else if (response?.status == 0) {
            console.error('Unable to get roles list.')
            setLoading(false)
        } else {
            console.error('Unable to get roles list.')
        }
    }
    function roleObjectToArray(roleObject) {
        const roleArray = [];

        for (const role in roleObject) {
            roleArray.push({ value: role, name: roleObject[role] });
        }
        console.log("roleArray", roleArray)
        return roleArray;
    }
    return (
        <>
            <div class="row page-titles">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item active"><Link to={"/account-confirmation"}>Home</Link></li>
                    <li class="breadcrumb-item"><a href="javascript:void(0)">Company Director</a></li>
                </ol>
            </div>
            <div>
                <h2>Company Director</h2>
            </div>

            <div class="d-flex align-items-center mb-4 flex-wrap">
                <div class="card-tabs mt-3 mt-sm-0 me-auto">
                    <ul class="nav nav-tabs trans-tab" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link underline active" data-bs-toggle="tab" href="#corporate-documents" role="tab">Member Detail</a>
                        </li>
                        {/* <li class="nav-item">
                            <a class="nav-link underline" data-bs-toggle="tab" href="#address-details" role="tab">Address Details</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link underline" data-bs-toggle="tab" href="#pending" role="tab">Director Details</a>
                        </li> */}

                    </ul>
                </div>




            </div>


            <div className="row">

                <div className="col-12 col-lg-12 col-md-12">

                    <div className="card">
                        <div className="card-header">
                            <h4>Company Director</h4>
                        </div>
                        <div className="card-body">
                            <div className="text-start">
                                <h5>Add Company Director Details</h5>
                            </div>

                            <div class="basic-form">
                                <form>

                                    <div class="row">
                                        <div class="mb-3 col-md-6">
                                            <label class="form-label">First Name</label>
                                            <input
                                                onChange={(e) => setInputs((prevInputs) => ({ ...prevInputs, first_name: e.target.value }))}
                                                type="text" class="form-control" placeholder="Enter first name" />
                                            {errors?.first_name && (
                                                <span className="text-danger">{errors?.first_name}</span>
                                            )}
                                        </div>
                                        <div class="mb-3 col-md-6">
                                            <label class="form-label">Last Name</label>
                                            <input
                                                onChange={(e) => setInputs((prevInputs) => ({ ...prevInputs, last_name: e.target.value }))}
                                                type="text" class="form-control" placeholder="Enter last name" />
                                            {errors?.last_name && (
                                                <span className="text-danger">{errors?.last_name}</span>
                                            )}
                                        </div>
                                        <div class="mb-3 col-md-6">
                                            <label class="form-label">Role</label>
                                            <select class="default-placeholder form-control" ref={roleRef} placeholder="Select Country">
                                                <option disabled selected value={""}>Select Role</option>
                                                {roles?.length > 0 && roles.map((r) => {
                                                    return <option value={r.value}>{r.name}</option>
                                                })}
                                            </select>
                                            {errors?.role && (
                                                <span className="text-danger">{errors?.role}</span>
                                            )}
                                        </div>
                                        <div class="mb-3 col-md-6">
                                            <label class="form-label">Date of Birth</label>
                                            <input type="text" ref={dateRef} class="form-control" placeholder="2017-06-04" id="mdate" onChange={(e) => { console.log(e) }} />
                                            {errors?.dob && (
                                                <span className="text-danger">{errors?.dob}</span>
                                            )}
                                        </div>
                                        <div class="mb-3 col-md-6">
                                            <label class="form-label">Gender</label>
                                            <select class="default-placeholder form-control" ref={genderRef} placeholder="Select Gender">
                                                <option disabled selected value={""}>Select Gender</option>
                                                <option value={"m"}>Male</option>
                                                <option value={"f"}>Female</option>
                                            </select>
                                            {errors?.gender && (
                                                <span className="text-danger">{errors?.gender}</span>
                                            )}
                                        </div>
                                        <div class="mb-3 col-md-6">
                                            <label class="form-label">Nationality</label>
                                            <select class="default-placeholder form-control" ref={countryRef} placeholder="Select Country">
                                                <option disabled selected value={""}>Select Country</option>
                                                {countries?.length > 0 && countries.map((c) => {
                                                    return <option value={c.id}>{c.name}</option>
                                                })}
                                            </select>
                                            {errors?.country && (
                                                <span className="text-danger">{errors?.country}</span>
                                            )}
                                        </div>
                                        <div class="mb-3 col-12">
                                            <label class="form-label">Residence Address</label>
                                            <textarea
                                                onChange={(e) => setInputs((prevInputs) => ({ ...prevInputs, address: e.target.value }))}
                                                class="form-control" id="validationCustom04" rows="2" placeholder="Enter the residence address" required></textarea>
                                            {errors?.address && (
                                                <span className="text-danger">{errors?.address}</span>
                                            )}
                                        </div>
                                        <div class="mb-3 col-12">
                                            <label class="form-label">Residence Country</label>
                                            <select class="default-placeholder form-control" ref={address_countryRef} placeholder="Select Country">
                                                <option disabled selected value={""}>Select Country</option>
                                                {countries?.length > 0 && countries.map((c) => {
                                                    return <option value={c.id}>{c.name}</option>
                                                })}
                                            </select>
                                            {errors?.address_country && (
                                                <span className="text-danger">{errors?.address_country}</span>
                                            )}
                                        </div>
                                        <div class="mb-3 col-md-6">
                                            <label class="form-label">Residence City</label>
                                            <input
                                                onChange={(e) => setInputs((prevInputs) => ({ ...prevInputs, city: e.target.value }))}

                                                type="text" class="form-control" placeholder="Enter city name" />
                                            {errors?.city && (
                                                <span className="text-danger">{errors?.city}</span>
                                            )}
                                        </div>
                                        <div class="mb-3 col-md-6">
                                            <label class="form-label">Zip</label>
                                            <input
                                                onChange={(e) => setInputs((prevInputs) => ({ ...prevInputs, zip: e.target.value }))}
                                                type="text" class="form-control" placeholder="Enter zip" />
                                            {errors?.zip && (
                                                <span className="text-danger">{errors?.zip}</span>
                                            )}
                                        </div>
                                    </div>

                                    <button disabled={loading} type="button" onClick={handleSubmit} class="btn btn-default d-flex justify-content-center align-items-center gap-2">
                                        Save
                                        {loading && <div class="spinner-border" style={{ height: "16px", width: "16px" }} role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>}
                                    </button>
                                </form>
                            </div>
                        </div>

                    </div>
                </div>
            </div>


        </>
    )
}
export default CompanyBoardMembers;