import { useEffect, useState } from "react";
import { useTheme } from "../../Theme/themeContext";
import { API_ENDPOINTS, API_METHODS } from "../../../constants/endpoints";
import { whitelabel_id } from "../../../constants/ids";
import apiCall from "../../../utils/apiCall";
const POSTerminalSection = () => {
    const { lightTheme, toggleTheme } = useTheme();
    const [content, setContent] = useState({})
    const [loading, setLoading] = useState(false)
    useEffect(() => {

        const scriptUrls = [
            "js/custom.min.js",
            "js/dlabnav-init.js",

        ];

        scriptUrls.forEach((scriptUrl) => {
            const script = document.createElement("script");
            script.src = scriptUrl;
            script.async = true;
            document.body.appendChild(script);
        });
        // Load each script asynchronously

        return () => {
            // Clean up: Remove the script elements when the component unmounts
            scriptUrls.forEach((scriptUrl) => {
                const script = document.querySelector(`[src="${scriptUrl}"]`);
                if (script) {
                    script.remove();
                }
            });
        };
    }, []);
    useEffect(() => {
        getPOSPlan()
    }, [])
    const getPOSPlan = async () => {
        setLoading(true)

        const body = {
            whitelabel_id: whitelabel_id
        }
        const url = API_ENDPOINTS.POS_PLAN
        const method = API_METHODS.POST
        const response = await apiCall(url, method, body)

        if (response?.status == 1) {
            setContent(response)
            setLoading(false)
        } else if (response?.status == 0) {
            setLoading(false)
        }
    }
    const image = "https://res.cloudinary.com/hashfort/image/upload/v1698055930/pagecontent/nvj3necwf7gzd5pfpomo.png"
    return (
        <div className="business-container mx-auto">
            <div className="row  mx-auto" style={{ paddingTop: "100px" }}>
                <div className="col-xl-6 mt-sm-5 mt-md-5">

                    <h1 className="w-75 mx-auto">Merchant POS Terminal</h1>
                </div>
            </div>
            <div className="row  mx-auto" >

                <div className="col-xl-6 ">
                    <div className="w-75 w-sm-100 mx-auto " >
                        {/* <h1 style={{ fontSize: '40px', lineHeight: '44px' }}>{content?.section_3_title}</h1> */}
                        <div className="mt-5" >
                            <h4 className="text-white m-1 p-0 ">Monthly Fee :  <h1>€ {content?.plan?.monthly_fee}</h1></h4>
                            <h4 className="text-white m-1 p-0">Transaction Fee : <h1> {content?.plan?.card_trx_fee}%</h1></h4>
                            {/* <h5 className="text-white m-1 p-0">Reseller Transaction Fee : {content?.plan?.reseller_card_trx_fee}</h5> */}

                        </div>
                        <h5 className="mt-5 ">Corporate cards and cards outside the European community require a higher commission percentage starting from 1% to 1.8%</h5>

                        {/* <div dangerouslySetInnerHTML={{ __html: content?.section_3_description }} className={lightTheme ? 'black-text' : "white-text"} /> */}
                    </div>
                </div>
                <div className="col-xl-6 ">
                    <div className="d-flex justify-content-center align-items-start " >
                        <img
                            className=" responsive-image-business-2"
                            
                            src={require('../../../assets/POSTerminal.png')}
                            alt=""
                        />
                        {/* <h3 className="text-white m-1 p-0">Monthly Fee : € {content?.plan?.monthly_fee}</h3>
                        <h3 className="text-white m-1 p-0">Monthly Fee : € {content?.plan?.monthly_fee}</h3> */}
                    </div>

                </div>

            </div>
        </div>
    )
}
export default POSTerminalSection;