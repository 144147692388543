import { useEffect, useState } from "react";
import { API_ENDPOINTS, API_METHODS } from "../../constants/endpoints";
import { whitelabel_id } from "../../constants/ids";
import apiCall from "../../utils/apiCall";
import { Link } from "react-router-dom";

const CompanyShareHoldersList = () => {
    const [holders, setHolders] = useState([])
    const [loading, setLoading,] = useState([])
    const [countries, setCountries,] = useState([])


    useEffect(() => {
        const scriptUrls = [
            "js/plugins-init/select2-init.js",
            "vendor/select2/js/select2.full.min.js",
            "vendor/bootstrap-material-datetimepicker/js/bootstrap-material-datetimepicker.js",
            "js/plugins-init/material-date-picker-init.js",
            "vendor/bootstrap-daterangepicker/daterangepicker.js",
            "vendor/moment/moment.min.js",
        ];
        scriptUrls.forEach((scriptUrl) => {
            const script = document.createElement("script");
            script.src = scriptUrl;
            script.async = true;
            document.body.appendChild(script);
        });

        return () => {
            scriptUrls.forEach((scriptUrl) => {
                const script = document.querySelector(`[src="${scriptUrl}"]`);
                if (script) {
                    script.remove();
                }

            });
        };


    }, []);
    useEffect(() => {
        getShareHolders()
        getCountryList()
    }, [])
    const getCountryList = async () => {
        const body = {
            whitelabel_id: whitelabel_id
        }
        const url = API_ENDPOINTS.GET_COUNTRY_LIST
        const method = API_METHODS.POST
        const response = await apiCall(url, method, body)
        if (response?.status == 1) {
            const sortedCountries = response.country.sort((a, b) => a.name.localeCompare(b.name));
            setCountries(sortedCountries)
            setLoading(false)
        } else if (response?.status == 0) {
            console.error('Unable to get Countries list.')
            setLoading(false)
        } else {
            console.error('Unable to get Countries list.')
        }
    }
    const getShareHolders = async () => {
        const authUser = JSON.parse(localStorage.getItem("auth_user"))
        const { web_token, user_id } = authUser
        const body = {
            whitelabel_id: whitelabel_id,
            user_id: user_id,
            web_token: web_token,
            shareholder_id: ''
        }
        const url = API_ENDPOINTS.SHAREHOLDERS_LIST
        const method = API_METHODS.POST
        const response = await apiCall(url, method, body)
        if (response?.status == 1) {
            setHolders(response?.data)
            setLoading(false)
        } else if (response?.status == 0) {
            console.error(response?.message || 'Unable to get Share Holders.')
            setLoading(false)
        } else {
            console.error('Unable to get Share Holders.')
            setLoading(false)
        }
    }
    const getCountryName = (cID) => {
        const country = countries.find((country) => country.id === cID);
        return country ? country.name : 'Unknown Country';
    };
    return (
        <>
            <div class="row page-titles">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item active"><Link to={"/account-confirmation"}>Home</Link></li>
                    <li class="breadcrumb-item"><a href="javascript:void(0)">Company Share Holders</a></li>
                </ol>
            </div>
            <div className="d-flex justify-content-between mb-4">

                <h3>Share Holders List</h3>
                <Link to={'/company-share-holders'}>  <button type="button" class="btn btn-success btn-sm">Add Share Holder</button></Link>
            </div>
            <div className="col-lg-12">
                <div className="card">

                    {!loading ? <div className="card-body">
                        {holders?.length > 0 ? <div className="table-responsive">
                            <table className="table table-responsive-md">
                                <thead>
                                    <tr>

                                        <th >
                                            <strong>First Name</strong>
                                        </th>
                                        <th >
                                            <strong>Last Name</strong>
                                        </th>
                                        <th>
                                            <strong>Role</strong>
                                        </th>
                                        <th >
                                            <strong>Date Of Birth</strong>
                                        </th>
                                        <th>
                                            <strong>Gender</strong>
                                        </th>
                                        <th>
                                            <strong>Nationality</strong>
                                        </th>
                                        <th >
                                            <strong>Residence Address</strong>
                                        </th>
                                        <th>
                                            <strong>Country</strong>
                                        </th>
                                        <th >
                                            <strong>Residence City</strong>
                                        </th>
                                        <th>
                                            <strong>Percentage</strong>
                                        </th>
                                        <th >
                                            <strong>ID Proof</strong>
                                        </th>
                                        <th >
                                            <strong>Address Prof</strong>
                                        </th>
                                        <th >
                                            <strong>Proof of Fund</strong>
                                        </th>
                                        <th >
                                            <strong>Source of Wealth</strong>
                                        </th>
                                        <th>
                                            <strong>ACTION</strong>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {holders?.length > 0 && holders?.map((holder) => {
                                        return <tr>


                                            <td>
                                                {holder?.first_name}
                                            </td>
                                            <td>{holder?.last_name} </td>
                                            <td>{holder?.role}</td>
                                            <td>
                                                {holder?.dob}
                                            </td>
                                            <td className="capitalize-first">
                                                {holder?.gender}
                                            </td>
                                            <td>
                                                {getCountryName(holder?.nationality)}
                                            </td>
                                            <td>
                                                {holder?.address}
                                            </td>
                                            <td>
                                                {getCountryName(holder?.address_country)}
                                            </td>
                                            <td>
                                                {holder?.city}
                                            </td>
                                            <td>
                                                {holder?.shareholder_percentage}
                                            </td>
                                            <td className={`${holder?.is_id_proof == "3" ? 'text-danger' : holder?.is_id_proof == "2" ? 'text-success' : holder?.is_id_proof == "1" ? 'text-info' : ''}`}>
                                                {holder?.is_id_proof == "0" ? 'not uploaded' : holder?.is_id_proof == "1" ? "uploaded" : holder?.is_id_proof == "2" ? "Approved" : holder?.is_id_proof == "3" ? "Rejected" : ''}
                                            </td>

                                            <td className={`${holder?.is_address_proof == "3" ? 'text-danger' : holder?.is_address_proof == "2" ? 'text-success' : holder?.is_address_proof == "1" ? 'text-info' : ''}`}>
                                                {holder?.is_address_proof == "0" ? 'not uploaded' : holder?.is_address_proof == "1" ? "uploaded" : holder?.is_address_proof == "2" ? "Approved" : holder?.is_address_proof == "3" ? "Rejected" : ''}
                                            </td>
                                            <td className={`${holder?.is_proof_of_fund == "3" ? 'text-danger' : holder?.is_proof_of_fund == "2" ? 'text-success' : holder?.is_proof_of_fund == "1" ? 'text-info' : ''}`}>
                                                {holder?.is_proof_of_fund == "0" ? 'not uploaded' : holder?.is_proof_of_fund == "1" ? "uploaded" : holder?.is_proof_of_fund == "2" ? "Approved" : holder?.is_proof_of_fund == "3" ? "Rejected" : ''}
                                            </td>
                                            <td className={`${holder?.is_source_wealth == "3" ? 'text-danger' : holder?.is_source_wealth == "2" ? 'text-success' : holder?.is_source_wealth == "1" ? 'text-info' : ''}`}>
                                                {holder?.is_source_wealth == "0" ? 'not uploaded' : holder?.is_source_wealth == "1" ? "uploaded" : holder?.is_source_wealth == "2" ? "Approved" : holder?.is_source_wealth == "3" ? "Rejected" : ''}
                                            </td>
                                            <td>

                                                <div className="d-flex justify-content-center">
                                                    {/* <a data-bs-toggle="modal" data-bs-target="#sendModal"
                                                            href="#"
                                                            className="btn btn-danger shadow btn-xs sharp me-1"
                                                        >
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-send mx-auto" viewBox="0 0 16 16">
                                                                <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576 6.636 10.07Zm6.787-8.201L1.591 6.602l4.339 2.76 7.494-7.493Z" />
                                                            </svg>
                                                        </a> */}
                                                    {holder?.status == "0" && <Link
                                                        to={`/update-share-holder/${holder?.shareholder_id}`} key={holder?.shareholder_id}
                                                        className="btn btn-primary shadow btn-xs sharp me-1"
                                                    >
                                                        <i className="fas fa-pencil-alt" />
                                                    </Link>}
                                                    {/* <a href="#" className="btn btn-danger shadow btn-xs sharp">
                                                        <i className="fa fa-trash" />
                                                    </a> */}
                                                </div>
                                            </td>
                                        </tr>
                                    })}



                                </tbody>
                            </table>
                        </div> : <div class="container h-100">
                            <div class="row justify-content-center h-100 align-items-center">
                                <div class="col-md-7">
                                    <div class="form-input-content text-center error-page">
                                        {/* <h1 class="error-text fw-bold">OOPS</h1> */}
                                        <h4><i class="fa fa-exclamation-triangle text-warning"></i></h4>
                                        <p>No share holders have been added yet .</p>
                                        <Link to={'/company-share-holders'}>  <button type="button" class="btn btn-success btn-sm">Add Share Holder</button></Link>
                                    </div>
                                </div>
                            </div>

                        </div>}
                    </div> : <div className="text-center my-5 ">
                        <div class="spinner-border" style={{ height: "50px", width: "50px" }} role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>}
                </div>
            </div>
        </>
    )
}
export default CompanyShareHoldersList;