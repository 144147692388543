import { Form } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import BusinessNavbar from '../Home/businessNavbar';
import apiCall from '../../../utils/apiCall';
import { API_ENDPOINTS, API_METHODS } from '../../../constants/endpoints';
import { whitelabel_id } from '../../../constants/ids';
import Footer from '../Home/footerBusiness';
import toastr from 'toastr';

const ContactUs = () => {
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState('')
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState({})
    const [inputs, setInputs] = useState({
        name: '', email: '', msg_subject: '', message: ''
    })
    const [validationRules, setValidationRules] = useState({
        name: "Name is required",
        email: "Email is required",
        msg_subject: "Subject is required",
        message: 'Message is reqruied',

    });
    const onFinish = async (values) => {
        setErrors({});
        if (!validateInputs()) {
            return;
        }
        setLoading(true)

        const body = {
            name: inputs?.name,
            email: inputs?.email,
            msg_subject: inputs?.msg_subject,
            message: inputs?.message,
            whitelabel_id: whitelabel_id
        }
        const url = API_ENDPOINTS.CONTACT_US
        const method = API_METHODS.POST
        const response = await apiCall(url, method, body)
        if (response?.status == 1) {
            toastr.success(response?.message)
            setInputs({ name: '', email: '', msg_subject: '', message: '' })
            setLoading(false)
        } else if (response?.status == 0) {
            setErrorMessage(response.message)
            setLoading(false)
        }
    };
    const validateInputs = () => {
        const errors2 = {};
        Object.keys(validationRules).forEach((field) => {
            if (!inputs[field]) {
                errors2[field] = validationRules[field];
            }
        });
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (inputs?.email !== '') {
            if (!emailRegex.test(inputs?.email)) {
                setErrors({ ...errors2, email: 'Please enter a valid email address' });
                return;

            }
        }
        setErrors(errors2);
        return Object.keys(errors2).length === 0;
    }
    return (
        <>
            <BusinessNavbar>
                <div class="container" style={{ paddingTop: "100px" }}>
                    <div className='text-center  mt-5'>
                        <h1>Contact Us</h1>
                    </div>
                    <div className='row mt-5' >
                        <div className='col-12 col-md-6 col-lg-4 col-sm-12' >
                            <div className='card'>
                                <div className='card-body py-5 text-center'>
                                    <div className='d-flex justify-content-center align-items-center p-1 mx-auto  rounded-circle' style={{ width: '60px', height: '60px', borderColor: '#C2C2C2', borderWidth: '0.5px', borderStyle: 'solid' }}>
                                        <i className="fas fa-map-marker-alt" style={{ fontSize: '30px' }}></i>
                                    </div>
                                    <h3 className='mt-4'>Visit Us Daily</h3>
                                    <span>Codego SRL, Via Monte Napoleone 8 - 20100 Milan Italy</span>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-md-6 col-lg-4 col-sm-12'>

                            <div className='card'>
                                <div className='card-body py-5 text-center'>
                                    <div className='d-flex justify-content-center align-items-center p-1 mx-auto  rounded-circle' style={{ width: '60px', height: '60px', borderColor: '#C2C2C2', borderWidth: '0.5px', borderStyle: 'solid' }}>
                                        <i class="fas fa-phone-volume" style={{ fontSize: '30px' }}></i>
                                    </div>
                                    <h3 className='mt-4'>Contact Us</h3>
                                    <span>++390287165469</span>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-md-6 col-lg-4 col-sm-12'>
                            <div className='card'>
                                <div className='card-body py-5 text-center'>
                                    <div className='d-flex justify-content-center align-items-center p-1 mx-auto  rounded-circle' style={{ width: '60px', height: '60px', borderColor: '#C2C2C2', borderWidth: '0.5px', borderStyle: 'solid' }}>
                                        <i class="fas fa-envelope" style={{ fontSize: '30px' }}></i>
                                    </div>
                                    <h3 className='mt-4'>Email Us</h3>
                                    <span>banking@codegotech.com</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row mt-5 '>
                        <div className='text-center mt-5'>
                            <h1>Send us a Message :</h1>
                        </div>
                        <div className='col-md-6 mb-4'>
                            <input value={inputs?.name || ""} onChange={(e) => setInputs((prevInputs) => ({ ...prevInputs, name: e.target.value }))} type="text" name="name" class="form-control" placeholder="Enter Name" />
                            {errors.name && (
                                <span className="text-danger">{errors.name}</span>
                            )}
                        </div>
                        <div className='col-md-6 mb-4'>
                            <input value={inputs?.email || ""} onChange={(e) => setInputs((prevInputs) => ({ ...prevInputs, email: e.target.value }))} type="email" name="email" class="form-control" placeholder="Enter Email Address" />
                            {errors.email && (
                                <span className="text-danger">{errors.email}</span>
                            )}
                        </div>
                        <div className='col-12'>
                            <input value={inputs?.msg_subject || ""} onChange={(e) => setInputs((prevInputs) => ({ ...prevInputs, msg_subject: e.target.value }))} type="text" name="subject" class="form-control" placeholder="Subject" />
                            {errors.msg_subject && (
                                <span className="text-danger">{errors.msg_subject}</span>
                            )}
                        </div>
                        <div className='col-12 mt-4'>
                            <textarea value={inputs?.message || ""} onChange={(e) => setInputs((prevInputs) => ({ ...prevInputs, message: e.target.value }))} class="form-control" rows="4" placeholder="Type Your massage here" ></textarea>
                            {errors.message && (
                                <span className="text-danger">{errors.message}</span>
                            )}

                        </div>
                        <div className='col-12 d-flex justify-content-center align-items-center mt-4'>
                            <div>

                                <button type="button" onClick={onFinish} disabled={loading} class="btn btn-primary btn-block d-flex justify-content-center align-items-center gap-2">
                                    Send Message
                                    {loading && <div class="spinner-border" style={{ height: "16px", width: "16px" }} role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </BusinessNavbar>
            <Footer title={"contact"} />
        </>
    )
}
export default ContactUs