// import { toast } from "react-toastify";



const apiCall = async (url, method, body = null, isFormData = false) => {
  
  
  const headers = {
    Accept: 'application/json',
    
  //  "Content-Type":"application/json",
  //  "Access-Control-Allow-Origin":"*",
  //  "Access-Control-Allow-Credentials":true,
  //  "Access-Control-Max-Age": "86400",
  //  "Access-Control-Allow-Methods":"GET, POST",
  //  "Access-Control-Allow-Headers":"X-Requested-With"
  };

  let requestOptions = {
    method: method,
    headers: headers,
  };
  const authUser = JSON.parse(localStorage.getItem('auth_user'))
  //console.log("authUser",authUser)
  if(authUser !== null){
    const { web_token } = authUser;
  if (web_token) {
    headers['web_token'] = web_token;
  }
  }
  if (body) {
    if (isFormData) {
      // headers['Content-Type'] = 'application/x-www-form-urlencoded';
      requestOptions.body = body;
    } else {
      headers['Content-Type'] = 'application/json';
      requestOptions.headers = headers;
      requestOptions.body = JSON.stringify(body);
    }
  }

  try {
    const response = await fetch(url, requestOptions);
    
    const data = await response.json();
    return data;
    
  } catch (error) {
    console.error(error);
    // toast.error(error)
  
  }
};

export default apiCall;