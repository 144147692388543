import { useEffect, useState } from "react"
import { API_ENDPOINTS, API_METHODS } from "../../constants/endpoints"
import apiCall from "../../utils/apiCall"
import { whitelabel_id } from "../../constants/ids"
import toastr from 'toastr';
import { useNavigate } from "react-router-dom";
const CompanyDetails = ({company,user}) => {
    const [loading,setLoading]=useState(false)
    const navigate = useNavigate()
    const statuses = JSON.parse(localStorage.getItem('statuses'))
    const authUser = JSON.parse(localStorage.getItem('auth_user'))

    useEffect(() => {
        if (!authUser) {
            navigate('/login')
        }
        if (authUser && authUser?.is_live !== 1) {
            if (statuses.company_status != "2" || statuses.director_status != "2" || statuses.document_status != "2") {
                navigate('/account-confirmation')
            }
        }
    })
    const getCompanyDetails = async () => {
        const authUser = JSON.parse(localStorage.getItem("auth_user"))
        const { web_token, user_id } = authUser
        const url = API_ENDPOINTS.PROFILE_DETAILS
        const body = {
            whitelabel_id: whitelabel_id,
            web_token: web_token,
            user_id: user_id,
        }
        const response = await apiCall(url, API_METHODS.POST, body)
        debugger
        if (response?.status == 1) {

        //    setCompany

            toastr.success('company information fetched successfully.')
            setLoading(false)
        } else if (response?.status == 0) {
            toastr.error(response?.message)
            setLoading(false)

        } else {
            toastr.error('Unable to login.')
        }
        console.log(response)
    }
    return (
        <>
            <div className="col-lg-8 col-md-10 col-sm-12">
                <div className="card">
                    <div className="card-header border-0">

                        <h3>Company Details</h3>
                    </div>
                    <div className="card-body">

                        <div className="d-flex justify-content-between">
                            <span >Company Name</span>
                            <span >{company?.company_name}</span>
                        </div>
                        <hr />
                        <div className="d-flex justify-content-between">
                            <span >Email</span>
                            <span >{user?.email}</span>
                        </div>
                        <hr />
                        <div className="d-flex justify-content-between">
                            <span >Company Contact Number</span>
                            <span >{user?.phone_number}</span>
                        </div>
                        <hr />
                        <div className="d-flex justify-content-between">
                            <span >Date of incorporation</span>
                            <span >{company?.date_of_incorporation}</span>
                        </div>
                        <hr />
                        <div className="d-flex justify-content-between">
                            <span >Registration Number</span>
                            <span >{company?.registration_number}</span>
                        </div>
                        <hr />
                        <div className="d-flex justify-content-between">
                            <span >VAT Number</span>
                            <span >{company?.vat_number}</span>
                        </div>
                        <hr />
                        <div className="d-flex justify-content-between">
                            <span >Company Website</span>
                            <span >{company?.website}</span>
                        </div>
                        <hr />
                        <div className="d-flex justify-content-between">
                            <span >Number of Employees</span>
                            <span >{company?.no_employees}</span>
                        </div>
                        <hr />
                        <div className="d-flex justify-content-between">
                            <span >Industry</span>
                            <span >{company?.industry}</span>
                        </div>
                        <hr />
                       


                    </div>
                </div>
            </div>
        </>
    )
}
export default CompanyDetails