import { useEffect } from "react";

const Section4 = ({content}) => {
    useEffect(() => {

        const scriptUrls = [
            "js/custom.min.js",
            "js/dlabnav-init.js",
            
        ];

        scriptUrls.forEach((scriptUrl) => {
            const script = document.createElement("script");
            script.src = scriptUrl;
            script.async = true;
            document.body.appendChild(script);
        });
        // Load each script asynchronously

        return () => {
            // Clean up: Remove the script elements when the component unmounts
            scriptUrls.forEach((scriptUrl) => {
                const script = document.querySelector(`[src="${scriptUrl}"]`);
                if (script) {
                    script.remove();
                }
            });
        };
    }, []);
    return (
        <div className="row  w-75 mx-auto" style={{ marginTop: "15%" }}>
            <h2>{content?.section_4_title}</h2>
            <div className="row">
                <div className="col-lg-4 col-md-6">
                    <div className="card card-animate-2 mx-auto p-5"
                    >
                        <img className="bounce-image" style={{ width: '50px', height: '50px' }} src="https://codegopay.com/business/assets/business_panel/img/service/service-icon-1.png" alt="na" />
                        <h5 className="transitionOnHover" style={{ lineHeight: '30px', marginTop: '50px' }} >{content?.section_4_box1_title}</h5>
                    </div>

                </div>
                <div className="col-lg-4 col-md-6">
                    <div className="card  card-animate-2 mx-auto p-5"
                    >
                        <img className="bounce-image" style={{ width: '50px', height: '50px' }} src="https://codegopay.com/business/assets/business_panel/img/service/service-icon-2.png" alt="na" />
                        <h5 className="transitionOnHover" style={{ lineHeight: '30px', marginTop: '50px' }} >{content?.section_4_box2_title}</h5>
                    </div>

                </div>
                <div className="col-lg-4 col-md-6">
                    <div className="card  card-animate-2 mx-auto p-5"
                    >
                        <img className="bounce-image" style={{ width: '50px', height: '50px' }} src="https://codegopay.com/business/assets/business_panel/img/service/service-icon-3.png" alt="na" />
                        <h5 className="transitionOnHover" style={{ lineHeight: '30px', marginTop: '50px' }} >{content?.section_4_box3_title}</h5>
                    </div>

                </div>
                <div className="col-lg-4 col-md-6">
                    <div className="card  card-animate-2 mx-auto p-5"
                    >
                        <img className="bounce-image" style={{ width: '50px', height: '50px' }} src="https://codegopay.com/business/assets/business_panel/img/service/service-icon-4.png" alt="na" />
                        <h5 className="transitionOnHover" style={{ lineHeight: '30px', marginTop: '50px' }} >{content?.section_4_box4_title}</h5>
                    </div>

                </div>
                <div className="col-lg-4 col-md-6">
                    <div className="card  card-animate-2 mx-auto p-5"
                    >
                        <img className="bounce-image" style={{ width: '50px', height: '50px' }} src="https://codegopay.com/business/assets/business_panel/img/service/service-icon-5.png" alt="na" />
                        <h5 className="transitionOnHover" style={{ lineHeight: '30px', marginTop: '50px' }} >{content?.section_4_box5_title}</h5>
                    </div>

                </div>
                <div className="col-lg-4 col-md-6">
                    <div className="card  card-animate-2 mx-auto p-5"
                    >
                        <img className="bounce-image" style={{ width: '50px', height: '50px' }} src="https://codegopay.com/business/assets/business_panel/img/service/service-icon-6.png" alt="na" />
                        <h5 className="transitionOnHover" style={{ lineHeight: '30px', marginTop: '50px' }} >{content?.section_4_box6_title}</h5>
                    </div>

                </div>

            </div>
        </div>
    )
}
export default Section4;