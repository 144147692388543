import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { API_ENDPOINTS, API_METHODS } from "../../constants/endpoints"
import apiCall from "../../utils/apiCall"
import { whitelabel_id } from "../../constants/ids"

const AddressDetails = ({company}) => {
    const navigate= useNavigate()
    const statuses = JSON.parse(localStorage.getItem('statuses'))
    const authUser = JSON.parse(localStorage.getItem('auth_user'))
    const [loading,setLoading]=useState(false)
    const [countries,setCountries]=useState([])
    useEffect(() => {
        if (!authUser) {
            navigate('/login')
        }
        if (authUser && authUser?.is_live !== 1) {
            if (statuses.company_status != "2" || statuses.director_status != "2" || statuses.document_status != "2") {
                navigate('/account-confirmation')
            }
        }
        getCountryList()
    },[])
    const getCountryName = (cID) => {
        const country = countries.find((country) => country.id === cID);
        return country ? country.name : 'Unknown Country';
    };
    const getCountryList = async () => {
        const body = {
            whitelabel_id: whitelabel_id
        }
        const url = API_ENDPOINTS.GET_COUNTRY_LIST
        const method = API_METHODS.POST
        const response = await apiCall(url, method, body)
        if (response?.status == 1) {
            const sortedCountries = response.country.sort((a, b) => a.name.localeCompare(b.name));
            setCountries(sortedCountries)
            setLoading(false)
        } else if (response?.status == 0) {
            console.error('Unable to get Countries list.')
            setLoading(false)
        } else {
            console.error('Unable to get Countries list.')
        }
    }
    return (
        <>
            <div className="col-lg-8 col-md-10 col-sm-12">
                <div className="card">
                    <div className="card-header border-0">

                        <h3>Address Details</h3>
                    </div>
                    <div className="card-body">

                    <div className="d-flex justify-content-between">
                            <span >Registration Number</span>
                            <span >{company?.registration_number}</span>
                        </div>
                        <hr />
                        <div className="d-flex justify-content-between">
                            <span >Registration Address </span>
                            <span >{company?.registration_address1}</span>
                        </div>
                        <hr />
                        {/* <div className="d-flex justify-content-between">
                        <span >Registration Address 2</span>
                            <span >{company?.registration_address2}</span>
                        </div>
                        <hr /> */}
                        <div className="d-flex justify-content-between">
                            <span >Registration Country</span>
                            <span >{getCountryName(company?.registration_country)}</span>
                        </div>
                        <hr />
                        <div className="d-flex justify-content-between">
                            <span >Registration Region</span>
                            <span >{company?.registration_region}</span>
                        </div>
                        <hr />
                        <div className="d-flex justify-content-between">
                            <span >Registration City</span>
                            <span >{company?.registration_city}</span>
                        </div>
                        <hr />
                        <div className="d-flex justify-content-between">
                            <span >Registration Zip</span>
                            <span >{company?.registration_zip}</span>
                        </div>
                        <hr />


                    </div>
                </div>
            </div>
        </>
    )
}
export default AddressDetails