import Footer from "./footerBusiness"
import BusinessNavbar from "./businessNavbar"
import Section1 from "./section1"
import Section2 from "./section2"
import Section3 from "./section3"
import Section4 from "./section4"
import { useEffect, useState } from "react"
import apiCall from "../../../utils/apiCall"
import { API_ENDPOINTS, API_METHODS } from "../../../constants/endpoints"
import { whitelabel_id } from "../../../constants/ids"
import Section1R from "./section1R"
import Section2R from "./section2R"
import Section3R from "./section3R"
import Section4R from "./section4R"
import POSTerminalSection from "./POSTerminalSection"

const BusinessHome = () => {
    const [loading, setLoading] = useState(false)
    const [content, setContent] = useState({})
    useEffect(() => {

        const scriptUrls = [
            "js/custom.min.js",
            "js/dlabnav-init.js",

        ];

        scriptUrls.forEach((scriptUrl) => {
            const script = document.createElement("script");
            script.src = scriptUrl;
            script.async = true;
            document.body.appendChild(script);
        });
        // Load each script asynchronously

        return () => {
            // Clean up: Remove the script elements when the component unmounts
            scriptUrls.forEach((scriptUrl) => {
                const script = document.querySelector(`[src="${scriptUrl}"]`);
                if (script) {
                    script.remove();
                }
            });
        };
    }, []);
    useEffect(() => {
        getHomeContent()
    }, [])
    const getHomeContent = async () => {
        setLoading(true)

        const body = {
            page: "home_business",
            whitelabel_id: whitelabel_id
        }
        const url = API_ENDPOINTS.GET_HOME_CONTENT
        const method = API_METHODS.POST
        const response = await apiCall(url, method, body)

        if (response?.status == 1) {
            setContent(response?.content_data)
            setLoading(false)
        } else if (response?.status == 0) {
            setLoading(false)
        }
    }
    return (
        <>
            <BusinessNavbar>
                {!loading ? <>
                    {/* <Section1 content={content} />
                    <Section2 content={content} />
                    <Section3 content={content} />
                    <Section4 content={content} /> */}
                    <Section1R content={content} />
                    <Section2R content={content} />
                    <Section3R content={content} />
                    <POSTerminalSection content={content} />
                    <Section4R content={content} />
                </> : <div className="text-center my-5 ">
                    <div class="spinner-border" style={{ height: "150px", width: "150px",paddingTop: "100px",marginTop:'200px' }} role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>}
                <Footer title="home2" />
            </BusinessNavbar>
        </>
    )
}
export default BusinessHome