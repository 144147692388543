import { useEffect, useState } from "react"
import BusinessNavbar from "../Home/businessNavbar"
import Footer from "../Home/footerBusiness"
import { whitelabel_id } from "../../../constants/ids"
import { API_ENDPOINTS, API_METHODS } from "../../../constants/endpoints"
import apiCall from "../../../utils/apiCall"
import { Link } from "react-router-dom"

const PrivacyPolicy = () => {
    const [content, setContent] = useState('')
    const [loading, setLoading] = useState('')
    const [pageNotFound, setPageNotFound] = useState(false)
    useEffect(() => { getHomeContent() }, [])
    const getHomeContent = async () => {
        setLoading(true)

        const body = {
            link: "tc",
            whitelabel_id: whitelabel_id
        }
        const url = API_ENDPOINTS.GET_PRIVACY_POLICY
        const method = API_METHODS.POST
        const response = await apiCall(url, method, body)

        if (response?.status == 1) {
            setContent(response?.content_data)
            setLoading(false)
        } else if (response?.status == 0) {
            setLoading(false)
            setPageNotFound(true)
        } else {
            setPageNotFound(true)
        }
    }
    return (
        <>
            {!pageNotFound ? <> <BusinessNavbar>
                {!loading ? <div class="container" style={{ paddingTop: "100px" }}>
                    <div className="text-center">
                        <h2>{content?.privacy?.title}</h2>
                    </div>
                    <div dangerouslySetInnerHTML={{ __html: content?.privacy?.content }} />
                </div> : <div className="text-center my-5 ">
                    <div class="spinner-border" style={{ height: "150px", width: "150px", paddingTop: "100px", marginTop: '200px' }} role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>}
            </BusinessNavbar>
                <Footer title={"tc"} />
            </> :

                <div class="row justify-content-center h-100 align-items-center" style={{ paddingTop: "150px" }}>
                    <div class="col-md-7">
                        <div class="form-input-content text-center error-page">
                            <h1 class="error-text fw-bold">404</h1>
                            <h4><i class="fa fa-exclamation-triangle text-warning"></i> The page you were looking for is not found!</h4>
                            
                            <div>
                                <Link class="btn btn-primary" to={'/business'}>Back to Home</Link>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}
export default PrivacyPolicy
