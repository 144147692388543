import { useState } from "react"
import moment from "moment";
import { useTheme } from "../../pages/Theme/themeContext";
import { API_ENDPOINTS, API_METHODS } from "../../constants/endpoints";
import { whitelabel_id } from "../../constants/ids";
import apiCall from "../../utils/apiCall";
const NotificationsModal = ({notifications,getNotifications,setSelectedNoti})=>{
    const [authUser, setAuthUser] = useState(JSON.parse(localStorage.getItem("auth_user")) || {})
    const [loading,setLoading]=useState(false)
    const { lightTheme, toggleTheme } = useTheme();
    // const [selectedNoti,setSelectedNoti]=useState('')
    const updateNotification = async (id)=>{
       
        const { web_token, user_id } = authUser
        const url = API_ENDPOINTS.UPDATE_NOTIFICATION
        const body = {
          whitelabel_id: whitelabel_id,
          web_token: web_token,
          user_id: user_id,
          notification_id:id
        }
        const response = await apiCall(url, API_METHODS.POST, body)
        if (response?.status == 1) {
         getNotifications()
        } else if (response?.status == 0) {
          console.error(response?.message || 'Unable to update notification.')
        } else {
          console.error(response?.message || 'Unable to update notification.')
        }
      }
    return (
        <div class="modal fade" id="allNotifications">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">All Notifications</h5>
                    <button  type="button" class="btn-close" data-bs-dismiss="modal" >
                                </button>
                </div>
                {!loading ? <div class="modal-body">
                
  
 
    <div
      id="DZ_W_Todo3"
      className="widget-media  ps overflow-auto scrollHide"style={{maxHeight:'500px'}}
    >
      <ul className="timeline">
       {notifications?.length>0 ?notifications?.map((noti)=>{
        return <li>
        <div style={{cursor:'pointer'}} className="timeline-panel" data-bs-toggle="modal" data-bs-target="#notification" onClick={()=>{setSelectedNoti(noti);updateNotification(noti?.id)}}>
          <div className="media me-2 " style={{background:lightTheme?'#CFCFCF99':'#5E3ED099'}}>
          <svg
                          width={20}
                          height={21}
                          viewBox="0 0 20 21"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M15.8333 11H17.5833C18.5498 11 19.3333 11.7835 19.3333 12.75C19.3333 13.7165 18.5498 14.5 17.5833 14.5H2.41666C1.45016 14.5 0.666656 13.7165 0.666656 12.75C0.666656 11.7835 1.45016 11 2.41666 11H4.16666L4.81798 5.13812C5.11136 2.49763 7.34325 0.5 9.99999 0.5C12.6567 0.5 14.8886 2.49763 15.182 5.13812L15.8333 11Z"
                            fill="#B9A8FF"
                          />
                          <path
                            opacity="0.3"
                            d="M12.3333 18C12.3333 16.7114 11.2887 15.6667 9.99999 15.6667C8.71133 15.6667 7.66666 16.7114 7.66666 18C7.66666 19.2887 8.71133 20.3334 9.99999 20.3334C11.2887 20.3334 12.3333 19.2887 12.3333 18Z"
                            fill="#B9A8FF"
                          />
                        </svg>
          </div>
          <div className="media-body">
            <h5 className="mb-1">
              {noti?.title} <small className=""> {moment(noti?.date).format("DD MMM YYYY - hh:mm A")}</small>
            </h5>
            <p className="mb-1">
            {noti?.description}
            </p>
        
          </div>
          
        </div>
      </li>
       })  :<li>No Notifications</li>
      }
      </ul>
      <div className="ps__rail-x" style={{ left: 0, bottom: "-73px" }}>
        <div
          className="ps__thumb-x"
          tabIndex={0}
          style={{ left: 0, width: 0 }}
        />
      </div>
      <div className="ps__rail-y" style={{ top: 73, height: 370, right: 432 }}>
        <div
          className="ps__thumb-y"
          tabIndex={0}
          style={{ top: 42, height: 212 }}
        />
      </div>
    </div>
  

                   
                </div> :
                    <div class="modal-body text-center">
                        <div class="spinner-border" style={{ height: "50px", width: "50px" }} role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                }

            </div>
        </div>
    </div>
    )
}
export default NotificationsModal