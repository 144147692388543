import { useEffect, useState } from "react"
import { API_ENDPOINTS, API_METHODS } from "../../../constants/endpoints"
import { whitelabel_id } from "../../../constants/ids"
import apiCall from "../../../utils/apiCall"
import BusinessNavbar from "../Home/businessNavbar"
import Footer from "../Home/footerBusiness"
import Section1 from "./section1"
import Section2 from "./section2"
import Section3 from "./section3"
import Section4 from "./section4"

const Pricing = () => {
    const [selectedCountry, setSelectedCountry] = useState(0)
    const [countries, setCountries] = useState([])
    const [lowPlans, setLowPlans] = useState([])
    const [mediumPlans, setMediumPlans] = useState([])
    const [highPlans, setHighPlans] = useState([])
    useEffect(() => {
        getPricingList()

    }, [])
    useEffect(() => {

        const scriptUrls = [
            "js/custom.min.js",
            "js/dlabnav-init.js",

        ];

        scriptUrls.forEach((scriptUrl) => {
            const script = document.createElement("script");
            script.src = scriptUrl;
            script.async = true;
            document.body.appendChild(script);
        });
        // Load each script asynchronously

        return () => {
            // Clean up: Remove the script elements when the component unmounts
            scriptUrls.forEach((scriptUrl) => {
                const script = document.querySelector(`[src="${scriptUrl}"]`);
                if (script) {
                    script.remove();
                }
            });
        };
    }, []);

    const getPricingList = async () => {
        const body = {
            whitelabel_id: whitelabel_id
        }
        const url = API_ENDPOINTS.GET_PRICING_PLANS
        const method = API_METHODS.POST
        const response = await apiCall(url, method, body)
        if (response?.status == 1) {
            console.log("response", response)
            setCountries(response?.countries)
            setLowPlans(response?.low_plans)
            setMediumPlans(response?.medium_plans)
            setHighPlans(response?.high_plans)
        } else if (response?.status == 0) {
            console.error('Unable to get plan list.')
        } else {
            console.error('Unable to get plan list.')
        }
    }

    return (
        <>
            <BusinessNavbar>
                <Section1 selectedCountry={setSelectedCountry} countries={countries} />
                <div className="row cardsContainer mx-auto" style={{ marginTop: "5%",  }}>
                    {(selectedCountry == 3 || selectedCountry == 0) && <Section2 lowPlans={lowPlans} />}
                    {(selectedCountry == 2 || selectedCountry == 0) && <Section3 mediumPlans={mediumPlans} />}
                    {(selectedCountry == 1 || selectedCountry == 0) &&  <Section4 highPlans={highPlans} />}

                </div>
                <Footer title={"home2"} />
            </BusinessNavbar>
        </>
    )
}
export default Pricing;