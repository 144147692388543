import React, { createContext, useContext, useState, useEffect } from 'react';

const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const [lightTheme, setLightTheme] = useState(JSON.parse(localStorage.getItem("lightTheme")) );
  const toggleTheme = () => {
    setLightTheme((prevTheme) => !prevTheme);
  };
  useEffect(() => {

    const themeValue = lightTheme ? "theme_9" : "theme_1";
    localStorage.setItem('lightTheme', lightTheme)
    document.body.setAttribute('data-theme', themeValue);
  }, [lightTheme]);
  useEffect(() => {
    let timeoutId;
    const handleResize = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        const localTheme = JSON.parse(localStorage.getItem("lightTheme"))
        const themeValue = localTheme ? "theme_9" : "theme_1";
        document.body.setAttribute('data-theme', themeValue);
      }, 50);
    };

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <ThemeContext.Provider value={{ lightTheme, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => {
  return useContext(ThemeContext);
};