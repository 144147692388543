import { Link, useNavigate } from "react-router-dom"
import { whitelabel_id } from "../../constants/ids"
import { API_ENDPOINTS, API_METHODS } from "../../constants/endpoints"
import { useEffect, useState } from "react"
import apiCall from "../../utils/apiCall"
import { useParams } from 'react-router-dom';
import QRCode from 'qrcode.react';
const Wallet = () => {
    const { coin } = useParams();
    const navigate = useNavigate()
    const authUser = JSON.parse(localStorage.getItem('auth_user'))
    const statuses = JSON.parse(localStorage.getItem('statuses'))
    const [coinTransactions, setCoinTransactions] = useState([])
    const [isTRC20, setTRC20] = useState(true)
    const [wallet, setWallet] = useState({})
    const [loading, setLoading] = useState(true)
    useEffect(() => {

        if (!authUser) {
            return navigate('/login')
        }
        if (authUser && !authUser?.web_token) {
            return navigate('/login')
        }
        if (authUser && authUser?.is_live !== 1) {
            if (statuses.company_status != "2" || statuses.director_status != "2" || statuses.document_status != "2") {
                return navigate('/account-confirmation')
            }
            return navigate('/account-confirmation')

        }
        cryptoWallet()
        getCoinTransactions()
    }, [])

    const cryptoWallet = async () => {
        setLoading(true)
        const { web_token, user_id } = authUser
        const body = {
            whitelabel_id: whitelabel_id,
            web_token: web_token,
            user_id: user_id,
            coin: coin
        }
        const url = API_ENDPOINTS.CRYPTO_WALLET
        const method = API_METHODS.POST
        const response = await apiCall(url, method, body)
        if (response?.status == 1) {
            const coinsInfo = extractCoinInfo(response.coin);
            const addressesInfo = extractAddressInfo(response.address);
            const JsonObj = { ...response, address: addressesInfo[0], coin: coinsInfo[0] }
            console.log("JsonObj", JsonObj)
            setWallet(JsonObj)
            setLoading(false)
        } else if (response?.status == 0) {
            console.error('Unable to get Countries list.')
            setLoading(false)
        } else {
            setLoading(false)
            console.error('Unable to get Countries list.')
        }
    }

    const getCoinTransactions = async () => {
        setLoading(true)
        const { web_token, user_id } = authUser
        const body = {
            whitelabel_id: whitelabel_id,
            web_token: web_token,
            user_id: user_id,
            coin: coin
        }
        const url = API_ENDPOINTS.GET_TRX_COIN
        const method = API_METHODS.POST
        const response = await apiCall(url, method, body)
        if (response?.status == 1) {
            setCoinTransactions(response?.trx)
        } else if (response?.status == 0) {
            console.error('Unable to get Countries list.')
            setLoading(false)
        } else {
            setLoading(false)
            console.error('Unable to get Countries list.')
        }
    }
    const extractCoinInfo = (coinData) => {
        const coinInfo = [];

        for (const coinKey in coinData) {
            if (coinData.hasOwnProperty(coinKey)) {
                const coin = coinData[coinKey];
                coinInfo.push({
                    ...coin
                    // Add other details you want to include
                });
            }
        }

        return coinInfo;
    };

    const extractAddressInfo = (addressData) => {
        const addressInfo = [];

        for (const addressKey in addressData) {
            if (addressData.hasOwnProperty(addressKey)) {
                const address = addressData[addressKey];
                addressInfo.push({
                    ...address
                });
            }
        }

        return addressInfo;
    };
    const openLink = (link) => {

        window.open(link, '_blank');
    };
    return (
        <>
            {!loading ? <div>
                <div className="row">
                    <div className="col-lg-8 col-xl-8 col-md-12 ">
                        <div className="card p-4 ">
                            <div className="d-flex align-items-center gap-5 mt-4">

                                <img src={wallet?.coin?.image}
                                    style={{ maxWidth: '100px', maxHeight: '100px' }}
                                    className="rounded"
                                    alt="" />


                                <div className="d-flex align-items-center gap-1">
                                    <h3>{wallet?.coin?.title}</h3>
                                    <h3 className="text-muted " style={{ textTransform: 'uppercase' }}>({wallet?.coin?.currency_symbol})</h3>
                                </div>
                            </div>
                            <div className="d-flex flex-wrap align-items-center gap-3 mt-5 ">
                                <button onClick={() => openLink(wallet?.coin?.official_link)} type="button" class="btn  btn-outline-primary  d-flex align-items-center gap-2" >

                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-repeat" viewBox="0 0 16 16">
                                        <path d="M11 5.466V4H5a4 4 0 0 0-3.584 5.777.5.5 0 1 1-.896.446A5 5 0 0 1 5 3h6V1.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384l-2.36 1.966a.25.25 0 0 1-.41-.192m3.81.086a.5.5 0 0 1 .67.225A5 5 0 0 1 11 13H5v1.466a.25.25 0 0 1-.41.192l-2.36-1.966a.25.25 0 0 1 0-.384l2.36-1.966a.25.25 0 0 1 .41.192V12h6a4 4 0 0 0 3.585-5.777.5.5 0 0 1 .225-.67Z" />
                                    </svg>
                                    OFFICIAL LINK </button>
                                <button onClick={() => openLink(wallet?.coin?.expoloer_link)} type="button" class="btn  btn-outline-primary  d-flex align-items-center gap-2" >

                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-repeat" viewBox="0 0 16 16">
                                        <path d="M11 5.466V4H5a4 4 0 0 0-3.584 5.777.5.5 0 1 1-.896.446A5 5 0 0 1 5 3h6V1.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384l-2.36 1.966a.25.25 0 0 1-.41-.192m3.81.086a.5.5 0 0 1 .67.225A5 5 0 0 1 11 13H5v1.466a.25.25 0 0 1-.41.192l-2.36-1.966a.25.25 0 0 1 0-.384l2.36-1.966a.25.25 0 0 1 .41.192V12h6a4 4 0 0 0 3.585-5.777.5.5 0 0 1 .225-.67Z" />
                                    </svg>
                                    EXPLORERS</button>
                                <Link to={"/crypto-currency"}>  <button type="button" class="btn  btn-outline-primary  d-flex align-items-center gap-2" >

                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-repeat" viewBox="0 0 16 16">
                                        <path d="M11 5.466V4H5a4 4 0 0 0-3.584 5.777.5.5 0 1 1-.896.446A5 5 0 0 1 5 3h6V1.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384l-2.36 1.966a.25.25 0 0 1-.41-.192m3.81.086a.5.5 0 0 1 .67.225A5 5 0 0 1 11 13H5v1.466a.25.25 0 0 1-.41.192l-2.36-1.966a.25.25 0 0 1 0-.384l2.36-1.966a.25.25 0 0 1 .41.192V12h6a4 4 0 0 0 3.585-5.777.5.5 0 0 1 .225-.67Z" />
                                    </svg>
                                    BACK TO WALLET</button></Link>
                            </div>

                            {/* <div className="row mt-4">
                                <div className="col-12 col-xl-4 col-md-6 col-lg-5 col-sm-6">
                                    <div className="card   text-center d-flex align-items-center">
                                        <h4 className="m-0 p-0 py-4">Blockchain:<span className="text-success">{" "}Active</span> </h4>

                                    </div>
                                </div>
                            </div> */}

                            <div className="row mt-5">
                                <div className="col-md-6 col-lg-12 col-12 col-xl-6 ">
                                    <div className="widget-stat card">
                                        <div class="card-body p-4">
                                            <div class="media ai-icon">
                                                <span class="me-3 bgl-warning text-warning">
                                                    <svg id="icon-orders" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-file-text">
                                                        <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                                                        <polyline points="14 2 14 8 20 8"></polyline>
                                                        <line x1="16" y1="13" x2="8" y2="13"></line>
                                                        <line x1="16" y1="17" x2="8" y2="17"></line>
                                                        <polyline points="10 9 9 9 8 9"></polyline>
                                                    </svg>
                                                </span>
                                                <div class="media-body">
                                                    <p class="mb-1">Total {wallet?.coin?.title} Balance</p>
                                                    <h4 class="mb-0">€ {wallet?.coin?.crypto_balance}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-12 col-12 col-xl-6">
                                    <div className="widget-stat card">
                                        <div class="card-body p-4">
                                            <div class="media ai-icon">
                                                <span class="me-3 bgl-warning text-warning">
                                                    <svg id="icon-orders" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-file-text">
                                                        <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                                                        <polyline points="14 2 14 8 20 8"></polyline>
                                                        <line x1="16" y1="13" x2="8" y2="13"></line>
                                                        <line x1="16" y1="17" x2="8" y2="17"></line>
                                                        <polyline points="10 9 9 9 8 9"></polyline>
                                                    </svg>
                                                </span>
                                                <div class="media-body">
                                                    <p class="mb-1">EUR Balance</p>
                                                    <h4 class="mb-0">€ {wallet?.coin?.eur_balance}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-12 col-12 col-xl-6">
                                    <div className="widget-stat card">
                                        <div class="card-body p-4">
                                            <div class="media ai-icon">
                                                <span class="me-3 bgl-warning text-warning">
                                                    <svg id="icon-orders" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-file-text">
                                                        <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                                                        <polyline points="14 2 14 8 20 8"></polyline>
                                                        <line x1="16" y1="13" x2="8" y2="13"></line>
                                                        <line x1="16" y1="17" x2="8" y2="17"></line>
                                                        <polyline points="10 9 9 9 8 9"></polyline>
                                                    </svg>
                                                </span>
                                                <div class="media-body">
                                                    <p class="mb-1">1 {wallet?.coin?.title} Price</p>
                                                    <h4 class="mb-0">€ {wallet?.coin?.eur_price}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-12 col-12 col-xl-6">
                                    <div className="widget-stat card">
                                        <div class="card-body p-4">
                                            <div class="media ai-icon">
                                                <span class="me-3 bgl-warning text-warning">
                                                    <svg id="icon-orders" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-file-text">
                                                        <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                                                        <polyline points="14 2 14 8 20 8"></polyline>
                                                        <line x1="16" y1="13" x2="8" y2="13"></line>
                                                        <line x1="16" y1="17" x2="8" y2="17"></line>
                                                        <polyline points="10 9 9 9 8 9"></polyline>
                                                    </svg>
                                                </span>
                                                <div class="media-body">
                                                    <p class="mb-1">Coin Rank</p>
                                                    <h4 class="mb-0">#{wallet?.coin?.cmc_rank}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-12 col-12 col-xl-6">
                                    <div className="widget-stat card">
                                        <div class="card-body p-4">
                                            <div class="media ai-icon">
                                                <span class="me-3 bgl-warning text-warning">
                                                    <svg id="icon-orders" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-file-text">
                                                        <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                                                        <polyline points="14 2 14 8 20 8"></polyline>
                                                        <line x1="16" y1="13" x2="8" y2="13"></line>
                                                        <line x1="16" y1="17" x2="8" y2="17"></line>
                                                        <polyline points="10 9 9 9 8 9"></polyline>
                                                    </svg>
                                                </span>
                                                <div class="media-body">
                                                    <p class="mb-1">Coin Type</p>
                                                    <h4 class="mb-0">{wallet?.coin?.coin_type}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                        <div className="row">
                            <div className="col-12">
                                <div className="card p-4 pb-5">
                                    <div className="text-center">
                                        <h3>{wallet?.coin?.title} QR Code</h3>
                                    </div>
                                    <div className="d-flex justify-content-center align-items-center mt-5">
                                        {/* <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" fill="currentColor" class="bi bi-qr-code" viewBox="0 0 16 16">
                                            <path d="M2 2h2v2H2z" />
                                            <path d="M6 0v6H0V0zM5 1H1v4h4zM4 12H2v2h2z" />
                                            <path d="M6 10v6H0v-6zm-5 1v4h4v-4zm11-9h2v2h-2z" />
                                            <path d="M10 0v6h6V0zm5 1v4h-4V1zM8 1V0h1v2H8v2H7V1zm0 5V4h1v2zM6 8V7h1V6h1v2h1V7h5v1h-4v1H7V8zm0 0v1H2V8H1v1H0V7h3v1zm10 1h-1V7h1zm-1 0h-1v2h2v-1h-1zm-4 0h2v1h-1v1h-1zm2 3v-1h-1v1h-1v1H9v1h3v-2zm0 0h3v1h-2v1h-1zm-4-1v1h1v-2H7v1z" />
                                            <path d="M7 12h1v3h4v1H7zm9 2v2h-3v-1h2v-1z" />
                                        </svg> */}
                                        <QRCode
                                            value={wallet?.coin?.currency_symbol == 'usdt' ?
                                                (!isTRC20 ? wallet?.address?.networks[0].qr_address : wallet?.address?.networks[1].qr_address)
                                                : (wallet?.address != undefined && wallet?.address?.networks?.length > 0) ? wallet?.address?.networks[0]?.qr_address
                                                    // : (wallet?.coin?.currency_symbol == 'aave' || wallet?.coin?.currency_symbol == 'mir' || wallet?.coin?.currency_symbol == 'bat' || wallet?.coin?.currency_symbol == 'zrx' || wallet?.coin?.currency_symbol == 'mana' || wallet?.coin?.currency_symbol == 'shib' || wallet?.coin?.currency_symbol == 'chz') ? wallet?.address?.networks[0].qr_address
                                                    : wallet?.address?.address
                                            } />
                                    </div>
                                    <div className="mt-5 text-center">
                                        <strong>Wallet:</strong>   {wallet?.coin?.currency_symbol == 'usdt' ?
                                            (!isTRC20 ? wallet?.address?.networks[0].qr_address : wallet?.address?.networks[1].qr_address)
                                            : (wallet?.address != undefined && wallet?.address?.networks?.length > 0) ? wallet?.address?.networks[0]?.qr_address
                                                // : (wallet?.coin?.currency_symbol == 'aave' || wallet?.coin?.currency_symbol == 'mir' || wallet?.coin?.currency_symbol == 'bat' || wallet?.coin?.currency_symbol == 'zrx' || wallet?.coin?.currency_symbol == 'mana' || wallet?.coin?.currency_symbol == 'shib' || wallet?.coin?.currency_symbol == 'chz') ? wallet?.address?.networks[0].qr_address
                                                : wallet?.address?.address}
                                    </div>
                                    {wallet?.coin?.currency_symbol == 'usdt' && <div className="mt-3 d-flex flex-wrap justify-content-center gap-3">
                                        <button onClick={() => setTRC20(true)} type="button" class="btn btn-success btn-sm">TRC20</button>
                                        <button onClick={() => setTRC20(false)} type="button" class="btn btn-success btn-sm">ERC20</button>
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div class="col-lg-12">
                        <div class="card">
                            <div class="card-header">
                                <h4 class="card-title">Bitcoin Recent Transactions</h4>
                            </div>
                            {coinTransactions?.length > 0 ? <div class="card-body">
                                <div class="table-responsive">
                                    <table class="table table-responsive-sm">
                                        <thead>
                                            <tr>
                                                <th>Total Amount</th>
                                                <th>Type</th>
                                                <th>Description</th>
                                                <th>TRXID</th>
                                                <th>Status</th>
                                                <th>Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {coinTransactions?.length > 0 && coinTransactions?.map((trx) => {
                                                return <tr>
                                                    <td className={`${trx?.type == 'Deposit' ? 'text-success' : 'text-danger'}`}>{trx?.amount} <span style={{ textTransform: 'uppercase' }}>{trx?.coinsymbol}</span> </td>
                                                    <td><span class={`badge light ${trx?.type == 'Deposit' ? 'badge-success' : 'badge-danger'}`}>{trx?.type}{trx?.type == 'Deposit' ? '(Sell)' : '(Buy)'}</span></td>
                                                    <td style={{ maxWidth: '200px' }}>{trx?.description}</td>
                                                    <td className="text-primary">{trx?.transaction_id}</td>
                                                    <td className="text-success"> <i class={`bi bi-check2-circle me-1 text-${trx?.status_color}`}></i>{trx?.status}</td>
                                                    <td>{trx?.created}</td>

                                                </tr>
                                            })}
                                            {/* <tr>
                                                <td className="text-success">0.00124986 BTC</td>
                                                <td><span class="badge light badge-success">Deposit(sell)</span></td>
                                                <td style={{ maxWidth: '200px' }}>Recieved 0.04644133 ETH @0.07126560 with 0.00331000 BTC and conversion Fee is 0.00 BTC</td>
                                                <td className="text-primary">07SERN-37DCO-HVUBPC</td>
                                                <td className="text-success"> <i class="bi bi-check2-circle me-1 text-success"></i>successfull</td>
                                                <td>06 Jun 2023</td>
                                            </tr>
                                            <tr>
                                                <td className="text-danger">0.04644133 ETH</td>
                                                <td><span class="badge light badge-danger">Withdraw(buy)</span></td>
                                                <td style={{ maxWidth: '200px' }}>Recieved 0.04644133 ETH @0.07126560 with 0.00331000 BTC and conversion Fee is 0.00 BTC</td>
                                                <td className="text-primary">07SERN-37DCO-HVUBPC</td>
                                                <td className="text-success"> <i class="bi bi-check2-circle me-1 text-success"></i>successfull</td>
                                                <td>06 Jun 2023</td>

                                            </tr>
                                            <tr>
                                                <td className="text-success">0.00124986 BTC</td>
                                                <td><span class="badge light badge-success">Deposit(sell)</span></td>
                                                <td style={{ maxWidth: '200px' }}>Recieved 0.04644133 ETH @0.07126560 with 0.00331000 BTC and conversion Fee is 0.00 BTC</td>
                                                <td className="text-primary">07SERN-37DCO-HVUBPC</td>
                                                <td className="text-success"> <i class="bi bi-check2-circle me-1 text-success"></i>successfull</td>
                                                <td>06 Jun 2023</td>
                                            </tr>
                                            <tr>
                                                <td className="text-danger">0.04644133 ETH</td>
                                                <td><span class="badge light badge-danger">Withdraw(buy)</span></td>
                                                <td style={{ maxWidth: '200px' }}>Recieved 0.04644133 ETH @0.07126560 with 0.00331000 BTC and conversion Fee is 0.00 BTC</td>
                                                <td className="text-primary">07SERN-37DCO-HVUBPC</td>
                                                <td className="text-success"> <i class="bi bi-check2-circle me-1 text-success"></i>successfull</td>
                                                <td>06 Jun 2023</td>

                                            </tr>
                                            <tr>
                                                <td className="text-success">0.00124986 BTC</td>
                                                <td><span class="badge light badge-success">Deposit(sell)</span></td>
                                                <td style={{ maxWidth: '200px' }}>Recieved 0.04644133 ETH @0.07126560 with 0.00331000 BTC and conversion Fee is 0.00 BTC</td>
                                                <td className="text-primary">07SERN-37DCO-HVUBPC</td>
                                                <td className="text-success"> <i class="bi bi-check2-circle me-1 text-success"></i>successfull</td>
                                                <td>06 Jun 2023</td>
                                            </tr>
                                            <tr>
                                                <td className="text-danger">0.04644133 ETH</td>
                                                <td><span class="badge light badge-danger">Withdraw(buy)</span></td>
                                                <td style={{ maxWidth: '200px' }}>Recieved 0.04644133 ETH @0.07126560 with 0.00331000 BTC and conversion Fee is 0.00 BTC</td>
                                                <td className="text-primary">07SERN-37DCO-HVUBPC</td>
                                                <td className="text-success"> <i class="bi bi-check2-circle me-1 text-success"></i>successfull</td>
                                                <td>06 Jun 2023</td>

                                            </tr>
                                            <tr>
                                                <td className="text-success">0.00124986 BTC</td>
                                                <td><span class="badge light badge-success">Deposit(sell)</span></td>
                                                <td style={{ maxWidth: '200px' }}>Recieved 0.04644133 ETH @0.07126560 with 0.00331000 BTC and conversion Fee is 0.00 BTC</td>
                                                <td className="text-primary">07SERN-37DCO-HVUBPC</td>
                                                <td className="text-success"> <i class="bi bi-check2-circle me-1 text-success"></i>successfull</td>
                                                <td>06 Jun 2023</td>
                                            </tr> */}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                                :
                                <div className="m-5">
                                    No Transaction Found
                                </div>}
                        </div>
                    </div>
                </div>
            </div> : <div className="text-center my-5 ">
                <div class="spinner-border" style={{ height: "50px", width: "50px" }} role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>}

        </>

    )
}
export default Wallet;