import { useState, useEffect } from "react"
import { API_ENDPOINTS, API_METHODS } from "../../constants/endpoints"
import { whitelabel_id } from "../../constants/ids"
import apiCall from "../../utils/apiCall"
import toastr from "toastr";
import { Link, useNavigate } from "react-router-dom";

const AccountConfirmation = () => {
    const navigate = useNavigate()
    const [authUser, setAuthUser] = useState(JSON.parse(localStorage.getItem("auth_user")) || {})
    const [statuses, setStatuses] = useState({ company_status: "0", director_status: "0", document_status: "0", director_kyc: null, resend: '' })
    const [loading, setLoading] = useState(false)
    const [directorDetails, setDirectorDetails] = useState({})
    const [countries, setCountries,] = useState([])

    useEffect(() => {
        if (authUser && authUser?.is_live == 1) {
            return navigate('/balance')
        }
        getCompanyStatus()
        getCountryList()
    }, [authUser])

    const getCompanyStatus = async () => {
        setLoading(true)
        const { web_token, user_id } = authUser
        const url = API_ENDPOINTS.GET_COMPANY_STATUS
        const body = {
            whitelabel_id: whitelabel_id,
            web_token: web_token,
            user_id: user_id,
        }
        const response = await apiCall(url, API_METHODS.POST, body)
        if (response?.status == 1) {
            setStatuses((prevState) => ({
                ...prevState,
                company_status: response?.user?.company_status,
                director_status: response?.user?.director_status,
                document_status: response?.user?.document_status,
                director_kyc: response?.director_kyc,
                resend: response?.resend
            }))
            setDirectorDetails(response?.director)
            toastr.success('company information fetched successfully.')
            setLoading(false)
        } else if (response?.status == 0) {
            toastr.error(response?.message)
            setLoading(false)

        } else {
            toastr.error('Unable to login.')
        }
        console.log(response)
    }


    const resendKYCLink = async () => {

        const { web_token, user_id } = authUser
        const url = API_ENDPOINTS.RESEND_KYC_LINK
        const body = {
            whitelabel_id: whitelabel_id,
            web_token: web_token,
            user_id: user_id,
        }
        const response = await apiCall(url, API_METHODS.POST, body)
        if (response?.status == 1) {


            toastr.success(response?.message || 'KYC link sent successfully .')
            setLoading(false)
        } else if (response?.status == 0) {
            toastr.error(response?.message)
            setLoading(false)

        } else {
            toastr.error('Unable to login.')
        }
        console.log(response)
    }
    const getCountryName = (cID) => {
        const country = countries.find((country) => country.id === cID);
        return country ? country.name : 'Unknown Country';
    };
    const getCountryList = async () => {
        const body = {
            whitelabel_id: whitelabel_id
        }
        const url = API_ENDPOINTS.GET_COUNTRY_LIST
        const method = API_METHODS.POST
        const response = await apiCall(url, method, body)
        if (response?.status == 1) {
            const sortedCountries = response.country.sort((a, b) => a.name.localeCompare(b.name));
            setCountries(sortedCountries)
            setLoading(false)
        } else if (response?.status == 0) {
            console.error('Unable to get Countries list.')
            setLoading(false)
        } else {
            console.error('Unable to get Countries list.')
        }
    }
    return (
        <>
            <div class="row page-titles">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item active"><a href="javascript:void(0)">Home</a></li>
                    <li class="breadcrumb-item"><a href="javascript:void(0)">Dashboard</a></li>
                </ol>
            </div>
            <div>
                <h2>{authUser?.company_name}, Welcome</h2>
            </div>

            <div className="mb-4">
                <span>Please provide us with more information about your company to complete your account verification.</span>
            </div>



            {!loading ? <div className="row">

                <div className="col-12 col-lg-12 col-md-12">
                    {/* <div class="alert alert-danger alert-dismissible fade show">
                       
                        <strong>Company Rejection!:</strong> You provided company information is not valid.
                    </div> */}
                    <div className="card">
                        <div class="card-body">
                            <div class="nestable col-12 col-lg-4 col-md-8 col-sm-8 col-xs-12  " style={{ cursor: 'default' }}>
                                <div class="dd" id="nestable">
                                    <ol class="dd-list p-0">
                                        <li class="dd-item" data-id="1">
                                            <div class="dd-handle" style={{ pointerEvents: 'none' }}>Complete Your Company Profile</div>
                                        </li>
                                    </ol>
                                </div>
                            </div>
                            <span>Follow these steps to complete your profile.</span>

                            <div className="mt-4 mx-3 mb-4">
                                <ul class="unorder-list">
                                    <li><strong>Step 1 - </strong>Company Information{statuses.company_status == "3" && "-"} {statuses.company_status == "3" && <strong className="text-danger">Rejected</strong>}</li>
                                    <p class="fst-italic text-primary">
                                        {statuses.company_status == "0" ? <Link to={"/company-profile"}>Add Information.</Link> :
                                            statuses.company_status == "1" ? "Awaiting verification" :
                                                statuses.company_status == "2" ? "Verified" :
                                                    statuses.company_status == "3" ? <Link to={"/company-profile"}>Update Information.</Link> :
                                                        ''}
                                    </p>
                                    <li><strong>Step 2 - </strong>Corporate Documents{statuses.document_status == "3" && "-"} {statuses.document_status == "3" && <strong className="text-danger">Rejected</strong>}</li>
                                    <p class="fst-italic text-primary">
                                        {statuses.document_status == "0" ? <Link to={"/corporate-documents"}>Upload Documents.</Link> :
                                            statuses.document_status == "1" ? "Awaiting verification" :
                                                statuses.document_status == "2" ? "Verified" :
                                                    statuses.document_status == "3" ? <Link to={"/corporate-documents"}>Update Corporate  Documents.</Link> :
                                                        ''}
                                    </p>
                                    <li><strong>Step 3 - </strong>Director Details{statuses.director_status == "3" && "-"} {statuses.director_status == "3" && <strong className="text-danger">Rejected</strong>} 
                                    {statuses?.director_status == "1" && <i class="bi bi-eye" data-bs-toggle="modal" data-bs-target="#directorDetails" style={{ cursor: 'pointer' }}></i>}
                                    </li>
                                    <p class="fst-italic text-primary">
                                        {statuses?.director_status == 0 ? <Link to={"/company-board-members"}>Add Director Information.</Link> :
                                            (statuses.director_status == "1" && statuses?.director_kyc?.kyc_status == '1' && statuses?.resend == '1') ? "A KYC link has already been sent to your email address. You can request another link after 30 minutes" :
                                                (statuses.director_status == "1" && statuses?.director_kyc?.kyc_status == '1' && statuses?.resend == '2') ?

                                                    <div>
                                                        <p>Please click on below link to generate KYC link again.</p>
                                                        <button type="button" class="btn tp-btn-light btn-primary m-0" onClick={() => { resendKYCLink() }}>Send Link</button>
                                                    </div>
                                                    :
                                                    statuses.director_status == "3" ? <div>
                                                        <p>Please click on below link to generate KYC link again.</p>
                                                        <button type="button" class="btn tp-btn-light btn-primary m-0" onClick={() => { resendKYCLink() }}>Send Link</button>
                                                    </div> :
                                                        ''
                                        }

                                    </p>
                                    <li><strong>Step 4 - </strong> Share Holder</li>
                                    <p>
                                        <Link to={"/company-share-holders-list"}>Share Holder List</Link></p>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div> :
                <div className="text-center my-5 ">
                    <div class="spinner-border" style={{ height: "50px", width: "50px" }} role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {/* Notification Modal */}
            <div class="modal fade" id="directorDetails">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">Director Details</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" >
                            </button>
                        </div>
                        <div class="modal-body">
                            <div className="d-flex justify-content-between">
                                <span >First Name</span>
                                <span >{directorDetails?.first_name}</span>
                            </div>
                            <hr />
                            <div className="d-flex justify-content-between">
                                <span >Last Name</span>
                                <span >{directorDetails?.last_name}</span>
                            </div>
                            <hr />
                            <div className="d-flex justify-content-between">
                                <span >Date of Birth</span>
                                <span >{directorDetails?.dob}</span>
                            </div>
                            <hr />
                            <div className="d-flex justify-content-between">
                                <span >Gender</span>
                                <span >{directorDetails?.gender == 'm' ? 'Male':'Female'}</span>
                            </div>
                            <hr />
                            <div className="d-flex justify-content-between">
                                <span >Role</span>
                                <span className="capitalize" >{directorDetails?.role}</span>
                            </div>
                            <hr />
                            <div className="d-flex justify-content-between">
                                <span >City</span>
                                <span className="capitalize" >{directorDetails?.city}</span>
                            </div>
                            <hr />
                            <div className="d-flex justify-content-between">
                                <span >Zip Code</span>
                                <span className="capitalize" > {directorDetails?.zipcode}</span>
                            </div>
                            <hr />
                            <div className="d-flex justify-content-between">
                                <span >Country</span>
                                <span className="capitalize" > {getCountryName(directorDetails?.country)}</span>
                            </div>
                            <hr />
                            <div className="d-flex justify-content-between">
                                <span >Address Country</span>
                                <span className="capitalize" > {getCountryName(directorDetails?.address_country)}</span>
                            </div>
                            <hr />
                            <div className="d-flex justify-content-between">
                                <span >Address</span>
                                <span className="capitalize" > {directorDetails?.address}</span>
                            </div>
                            <hr />
                           
                        </div>

                    </div>
                </div>
            </div>
            {/* Notification Modal */}
        </>
    )
}
export default AccountConfirmation