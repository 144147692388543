import { useEffect, useRef, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import apiCall from "../../utils/apiCall"
import { API_ENDPOINTS, API_METHODS } from "../../constants/endpoints"
import { whitelabel_id } from "../../constants/ids"
import toastr from "toastr"

const TransactionsHistory = () => {
    const $ = window.jQuery
    const navigate = useNavigate()
    const statuses = JSON.parse(localStorage.getItem('statuses'))
    const authUser = JSON.parse(localStorage.getItem('auth_user'))
    const startDateRef = useRef()
    const endDateRef = useRef()
    const ibanRef = useRef()
    const [transactions, setTransactions] = useState([])
    const [filteredTransactions, setFilteredTransactions] = useState(null)
    const [trxDetails, setTrxDetails] = useState(null)
    const closeRef = useRef()
    const docCloseRef = useRef()
    const [loading, setLoading] = useState(false)
    const [search, setSearch] = useState('')
    const [selectedTransaction, setSelectedTransaction] = useState(null)
    const [trxDocument, setTRXDocument] = useState(null)
    const [downloadInvoiceLoading, setDownloadInvoiceLoading] = useState('')
    const [ibansList, setIbanList] = useState([])
    const [ibansLoading, setIbanLoading] = useState(false)
    const [downloadPDFLoading, setDownloadPDFLoading] = useState(false)
    const [uploadTrxDocLoading,setUploadTrxDocLoading]=useState(false)
    const [pagination, setPagination] = useState({
        num_rows: 0,
        page_number: 0,
        per_page: 0
    })
    const [uploaded_error, set_uploadError] = useState(false)
    useEffect(() => {
        // Get current date
        const currentDate = new Date();

        // Format date as 'YYYY-MM-DD'
        const formattedDate = currentDate.toISOString().split('T')[0];

        // Set formatted date as the placeholder
        endDateRef.current.placeholder = formattedDate;
        startDateRef.current.placeholder = formattedDate;
    }, []);
    useEffect(() => {
        const scriptUrls = [
            "js/plugins-init/select2-init.js",
            "vendor/select2/js/select2.full.min.js",
            "vendor/bootstrap-material-datetimepicker/js/bootstrap-material-datetimepicker.js",
            "js/plugins-init/material-date-picker-init.js",
            "vendor/bootstrap-daterangepicker/daterangepicker.js",
            "vendor/moment/moment.min.js",
        ];
        scriptUrls.forEach((scriptUrl) => {
            const script = document.createElement("script");
            script.src = scriptUrl;
            script.async = true;
            document.body.appendChild(script);
        });
        return () => {
            scriptUrls.forEach((scriptUrl) => {
                const script = document.querySelector(`[src="${scriptUrl}"]`);
                if (script) {
                    script.remove();
                }
            });
        };
    }, []);
    useEffect(() => {
        if (!authUser) {
            return navigate('/login')
        }
        if (authUser && !authUser?.web_token) {
            return navigate('/login')
        }
        if (authUser && authUser?.is_live !== 1) {
            if (statuses.company_status != "2" || statuses.director_status != "2" || statuses.document_status != "2") {
                return navigate('/account-confirmation')
            }
            return navigate('/account-confirmation')

        }
        getTransactions()
        getIBANList()
    }, [])

    const resetTransactions = async () => {
        setLoading(true)
        const { web_token, user_id } = authUser
        const body = {
            whitelabel_id: whitelabel_id,
            web_token: web_token,
            user_id: user_id,

        }

        const url = API_ENDPOINTS.TRANSACTIONS_LIST
        const method = API_METHODS.POST
        const response = await apiCall(url, method, body)
        if (response?.trx) {
            setLoading(false)
            setSearch(null);
            // $(ibanRef).select2("val", "");
            $(ibanRef.current).val('').trigger('change')
            console.log(ibanRef.current.value, "ibanRef.current.value")
            startDateRef.current.value = '';
            endDateRef.current.value = ''
            // ibanRef.current.value = ''
            setTransactions(response?.trx)
            setPagination({
                num_rows: response?.num_rows,
                page_number: 1,
                per_page: response?.per_page
            })
        } else if (response?.status == 0) {
            setLoading(false)
            console.error(response?.message || 'unable to fetch transactions')
        } else {
            setLoading(false)
            console.error(response?.error || 'unable to fetch transactions')
        }
    }
    const getTransactions = async (page = 1, per_page = 10) => {
        setLoading(true)
        const { web_token, user_id } = authUser
        const body = {
            whitelabel_id: whitelabel_id,
            web_token: web_token,
            user_id: user_id,
            startdate: (filteredTransactions == null || filteredTransactions == true) ? startDateRef.current.value : '',
            enddate: (filteredTransactions == null || filteredTransactions == true) ? endDateRef.current.value : '',
            page: page,
            search: (filteredTransactions == null || filteredTransactions == true) ? search : '',
            per_page: per_page,
            iban_id: (filteredTransactions == null || filteredTransactions == true) ? ibanRef.current.value : ''
        }

        const url = API_ENDPOINTS.TRANSACTIONS_LIST
        const method = API_METHODS.POST
        const response = await apiCall(url, method, body)
        if (response?.trx) {
            setTransactions(response?.trx)
            setPagination({
                num_rows: response?.num_rows,
                page_number: response?.page_number,
                per_page: response?.per_page
            })
            setLoading(false)
        } else if (response?.status == 0) {
            console.error(response?.message || 'unable to fetch transactions')
            setLoading(false)
        } else {
            console.error(response?.error || 'unable to fetch transactions')
            setLoading(false)
        }
    }



    const getTransactionDetails = async (id) => {

        const { web_token, user_id } = authUser
        const body = {
            whitelabel_id: whitelabel_id,
            web_token: web_token,
            user_id: user_id,
            unique_id: id
        }

        const url = API_ENDPOINTS.TRANSACTION_DETAILS
        const method = API_METHODS.POST
        const response = await apiCall(url, method, body)
        if (response?.status == 1) {
            setTrxDetails(response?.trx)
        } else if (response?.status == 0) {
            console.error(response?.message || 'unable to fetch transactions')
        } else {
            console.error(response?.error || 'unable to fetch transactions')
        }
    }
    const navigateToDetails = () => {
        closeRef.current.click();
        navigate('/transactions-details')
    }
    const uploadDocument = async () => {
        if (!trxDocument) {
            return set_uploadError(true);
        } else {
            set_uploadError(false);
        }
        const { web_token, user_id } = authUser
        const formData = new FormData;
        formData.append('whitelabel_id', whitelabel_id)
        formData.append('web_token', web_token)
        formData.append('user_id', user_id)
        formData.append('trx_id', selectedTransaction)
        if (trxDocument.type.startsWith('image/') || trxDocument.type === 'application/pdf') {
            formData.append('trx_document', trxDocument);
        } else {
            return toastr.error('Invalid document format. Please select an image or PDF file.');
        }
        setUploadTrxDocLoading(true)
        const url = API_ENDPOINTS.IBAN_UPLOAD_TRX_DOC
        const method = API_METHODS.POST
        const response = await apiCall(url, method, formData, true)
        if (response?.status == 1) {
            getTransactions(pagination?.page_number)
            setUploadTrxDocLoading(false)
            docCloseRef.current.click()
            toastr.success(response?.message || 'Uploaded Successfully')
        } else if (response?.status == 0) {
            console.error(response?.message || 'unable to upload document.')
            setUploadTrxDocLoading(false)
            getTransactions(pagination?.page_number)
            docCloseRef.current.click()
        } else {
            console.error(response?.error || 'unable to upload document.')
            setUploadTrxDocLoading(false)
            getTransactions(pagination?.page_number)
            docCloseRef.current.click()
        }
    }
    const downloadInvoice = async (trxId) => {
        setDownloadInvoiceLoading(trxId)
        const { web_token, user_id } = authUser
        const body = {
            whitelabel_id: whitelabel_id,
            web_token: web_token,
            user_id: user_id,
            trx_id: trxId
        }

        const url = API_ENDPOINTS.DOWNLOAD_INVOICE
        const method = API_METHODS.POST
        const response = await apiCall(url, method, body)
        if (response?.status == 1) {
            toastr.success(response?.message)
            setDownloadInvoiceLoading('')
        } else if (response?.status == 0) {
            console.error(response?.message || 'unable to upload document.')
            toastr.success(response?.message || 'unable to download')
            setDownloadInvoiceLoading('')

        } else {
            console.error(response?.error || 'unable to upload document.')
            toastr.success(response?.message || 'unable to download')
            setDownloadInvoiceLoading('')

        }
    }
    const downloadBankStatementPDF = async () => {
        setDownloadPDFLoading(true)
        const { web_token, user_id } = authUser
        const body = {
            whitelabel_id: whitelabel_id,
            web_token: web_token,
            user_id: user_id,
            startdate: startDateRef.current.value,
            enddate: endDateRef.current.value,
            iban_id: ibanRef.current.value
        }

        const url = API_ENDPOINTS.DOWNLOAD_BANK_STATEMENT
        const method = API_METHODS.POST
        const response = await apiCall(url, method, body)
        if (response?.status == 1) {
            toastr.success(response?.message)
            setDownloadPDFLoading(false)
        } else if (response?.status == 0) {
            console.error(response?.message || 'unable to download')
            toastr.error(response?.message || 'unable to download')
            setDownloadPDFLoading(false)

        } else {
            console.error(response?.error || 'unable to download')
            toastr.error(response?.message || 'unable to download')
            setDownloadPDFLoading(false)

        }
    }
    const getIBANList = async () => {
        setIbanLoading(true)
        const { web_token, user_id } = authUser
        const body = {
            whitelabel_id: whitelabel_id,
            web_token: web_token,
            user_id: user_id,
        }

        const url = API_ENDPOINTS.IBAN_LIST
        const method = API_METHODS.POST
        const response = await apiCall(url, method, body)
        if (response?.status == 1) {
            setIbanList(response?.ibans)
            setIbanLoading(false)
        } else if (response?.status == 0) {
            console.error(response?.message || 'unable to fetch ibans')
            setIbanLoading(false)
        } else {
            console.error(response?.error || 'unable to fetch ibans')
            setIbanLoading(false)
        }
    }
    return (
        <>
            <div className="d-flex justify-content-start mb-4">
                <h3>Transactions History</h3>

            </div>
            <div className="col-lg-12">
                <div className="card">
                    <div class="mt-5 mx-5">
                        <div class="row">
                            <div class="col-xl-2 col-md-4 col-lg-4 ">
                                <span className="text-muted">Select IBAN</span>
                                <select ref={ibanRef} class="default-placeholder form-control" id="iban-select" placeholder="Select IBAN">
                                    <option disabled selected value={""}>Select IBAN</option>
                                    {ibansList?.length > 0 && ibansList?.map((i) => {
                                        return <option value={i.iban_id}>{i.label}</option>
                                    })}

                                </select>

                            </div>
                            <div class="col-xl-2 col-md-4 col-lg-4">
                                <span className="text-muted">Transaction ID</span>
                                <input value={search || ''} onChange={(e) => { setSearch(e.target.value) }} type="text" class="form-control input-default " placeholder="Enter Transaction Id " />

                            </div>
                            <div class="col-xl-2 col-md-4 col-lg-4">
                                <div class="form-material">
                                    <span className="text-muted">select start date</span>
                                    <input type="text" ref={startDateRef} class="form-control" placeholder="2017-06-04" id="mdate" onChange={(e) => { console.log(e) }} />
                                    {/* {errors?.startDate && */}
                                    {/* } */}
                                </div>
                            </div>


                            <div class="col-xl-2 col-md-4 col-lg-4">
                                <div class="form-material">
                                    <span className="text-muted">select end date</span>
                                    <input value={endDateRef.current?.value || ''} type="text" ref={endDateRef} class="form-control" placeholder="2017-06-04" id="mdate2" onChange={(e) => { console.log(e) }} />
                                </div>
                            </div>
                            <div class="col-md-6 col-xl-4 col-lg-6 col-sm-12 col-12 mt-2 mt-md-0 d-flex">
                                <div>
                                    <span className="text-muted"></span> <br />
                                    <button disabled={loading} onClick={() => { getTransactions(pagination?.page_number, pagination?.per_page); setFilteredTransactions(true) }} type="button" class="btn btn-success me-2 mt-auto mb-2" >Search</button>
                                    {filteredTransactions && <button onClick={() => { resetTransactions(); setFilteredTransactions(null) }} type="button" class="btn btn-dark mt-auto me-2 mb-2" >Reset</button>}
                                    <button disabled={downloadPDFLoading} onClick={() => { downloadBankStatementPDF() }} type="button" class="btn btn-success mb-2">PDF
                                        <span >
                                            {!downloadPDFLoading ? <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-file-earmark-pdf ms-2" viewBox="0 0 16 16">
                                                <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2M9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z" />
                                                <path d="M4.603 14.087a.8.8 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.7 7.7 0 0 1 1.482-.645 20 20 0 0 0 1.062-2.227 7.3 7.3 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.188-.012.396-.047.614-.084.51-.27 1.134-.52 1.794a11 11 0 0 0 .98 1.686 5.8 5.8 0 0 1 1.334.05c.364.066.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.86.86 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.7 5.7 0 0 1-.911-.95 11.7 11.7 0 0 0-1.997.406 11.3 11.3 0 0 1-1.02 1.51c-.292.35-.609.656-.927.787a.8.8 0 0 1-.58.029m1.379-1.901q-.25.115-.459.238c-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361q.016.032.026.044l.035-.012c.137-.056.355-.235.635-.572a8 8 0 0 0 .45-.606m1.64-1.33a13 13 0 0 1 1.01-.193 12 12 0 0 1-.51-.858 21 21 0 0 1-.5 1.05zm2.446.45q.226.245.435.41c.24.19.407.253.498.256a.1.1 0 0 0 .07-.015.3.3 0 0 0 .094-.125.44.44 0 0 0 .059-.2.1.1 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a4 4 0 0 0-.612-.053zM8.078 7.8a7 7 0 0 0 .2-.828q.046-.282.038-.465a.6.6 0 0 0-.032-.198.5.5 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822q.036.167.09.346z" />
                                            </svg> : <div class="spinner-border ms-2" style={{ height: "20px", width: "20px" }} role="status">
                                                <span class="sr-only">Loading...</span>
                                            </div>}
                                        </span>
                                    </button>
                                </div>  </div>


                        </div>
                    </div>
                    <div class="mt-2 mx-5">
                        <div class="d-flex justify-content-start align-items-center gap-2">
                            {/* <div class=" mb-md-1 mb-1">
                                <button disabled={downloadPDFLoading} onClick={() => { downloadBankStatementPDF() }} type="button" class="btn btn-success">PDF
                                    <span class="btn-icon-end">
                                        {!downloadPDFLoading ? <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-file-earmark-pdf" viewBox="0 0 16 16">
                                            <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2M9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z" />
                                            <path d="M4.603 14.087a.8.8 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.7 7.7 0 0 1 1.482-.645 20 20 0 0 0 1.062-2.227 7.3 7.3 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.188-.012.396-.047.614-.084.51-.27 1.134-.52 1.794a11 11 0 0 0 .98 1.686 5.8 5.8 0 0 1 1.334.05c.364.066.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.86.86 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.7 5.7 0 0 1-.911-.95 11.7 11.7 0 0 0-1.997.406 11.3 11.3 0 0 1-1.02 1.51c-.292.35-.609.656-.927.787a.8.8 0 0 1-.58.029m1.379-1.901q-.25.115-.459.238c-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361q.016.032.026.044l.035-.012c.137-.056.355-.235.635-.572a8 8 0 0 0 .45-.606m1.64-1.33a13 13 0 0 1 1.01-.193 12 12 0 0 1-.51-.858 21 21 0 0 1-.5 1.05zm2.446.45q.226.245.435.41c.24.19.407.253.498.256a.1.1 0 0 0 .07-.015.3.3 0 0 0 .094-.125.44.44 0 0 0 .059-.2.1.1 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a4 4 0 0 0-.612-.053zM8.078 7.8a7 7 0 0 0 .2-.828q.046-.282.038-.465a.6.6 0 0 0-.032-.198.5.5 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822q.036.167.09.346z" />
                                        </svg> : <div class="spinner-border" style={{ height: "20px", width: "20px" }} role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>}
                                    </span>
                                </button>
                            </div> */}
                            {/* <div class=" mb-md-1 mb-1">
                                <button type="button" class="btn btn-success">CSV <span class="btn-icon-end"><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-filetype-csv" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5zM3.517 14.841a1.13 1.13 0 0 0 .401.823q.195.162.478.252.284.091.665.091.507 0 .859-.158.354-.158.539-.44.187-.284.187-.656 0-.336-.134-.56a1 1 0 0 0-.375-.357 2 2 0 0 0-.566-.21l-.621-.144a1 1 0 0 1-.404-.176.37.37 0 0 1-.144-.299q0-.234.185-.384.188-.152.512-.152.214 0 .37.068a.6.6 0 0 1 .246.181.56.56 0 0 1 .12.258h.75a1.1 1.1 0 0 0-.2-.566 1.2 1.2 0 0 0-.5-.41 1.8 1.8 0 0 0-.78-.152q-.439 0-.776.15-.337.149-.527.421-.19.273-.19.639 0 .302.122.524.124.223.352.367.228.143.539.213l.618.144q.31.073.463.193a.39.39 0 0 1 .152.326.5.5 0 0 1-.085.29.56.56 0 0 1-.255.193q-.167.07-.413.07-.175 0-.32-.04a.8.8 0 0 1-.248-.115.58.58 0 0 1-.255-.384zM.806 13.693q0-.373.102-.633a.87.87 0 0 1 .302-.399.8.8 0 0 1 .475-.137q.225 0 .398.097a.7.7 0 0 1 .272.26.85.85 0 0 1 .12.381h.765v-.072a1.33 1.33 0 0 0-.466-.964 1.4 1.4 0 0 0-.489-.272 1.8 1.8 0 0 0-.606-.097q-.534 0-.911.223-.375.222-.572.632-.195.41-.196.979v.498q0 .568.193.976.197.407.572.626.375.217.914.217.439 0 .785-.164t.55-.454a1.27 1.27 0 0 0 .226-.674v-.076h-.764a.8.8 0 0 1-.118.363.7.7 0 0 1-.272.25.9.9 0 0 1-.401.087.85.85 0 0 1-.478-.132.83.83 0 0 1-.299-.392 1.7 1.7 0 0 1-.102-.627zm8.239 2.238h-.953l-1.338-3.999h.917l.896 3.138h.038l.888-3.138h.879z" />
                                </svg></span>
                                </button>
                            </div> */}
                        </div>
                    </div>
                    {!loading ? <div className="card-body">
                        {transactions?.length > 0 ? <div className="table-responsive">
                            <table className="table table-responsive-md">
                                <thead>
                                    <tr>
                                        <th style={{ whiteSpace: 'wrap' }}>
                                            <strong>Transaction Id</strong>
                                        </th>

                                        <th>
                                            <strong>WALLET</strong>
                                        </th>
                                        <th>
                                            <strong>TYPE</strong>
                                        </th>
										<th>
                                            <strong>Payment Mode</strong>
                                        </th>
                                        <th>
                                            <strong>TOTAL</strong>
                                        </th>
                                        <th>
                                            <strong>Fee</strong>
                                        </th>
										
                                        <th style={{ whiteSpace: 'wrap' }}>
                                            <strong>BENEFICIARY NAME</strong>
                                        </th>
                                        <th style={{ whiteSpace: 'wrap' }}>
                                            <strong>PAYMENT REASON</strong>
                                        </th>
										<th>
                                            <strong>Desc</strong>
                                        </th>
                                        <th>
                                            <strong>STATUS</strong>
                                        </th>
                                        <th>
                                            <strong>DATE</strong>
                                        </th>

                                    </tr>
                                </thead>
                                <tbody>

                                    {transactions?.length > 0 && (transactions).map((trx) => {
                                        return <tr >
                                            <td>{trx?.transaction_id}</td>
                                            <td>{trx?.label}</td>
                                            <td>
                                                <span class="badge light badge-dark text-white d-flex align-items-center" >
                                                    {trx?.type == 'credit' ?
                                                        <i class="bi bi-arrow-down-circle me-2 text-success" style={{ fontSize: '16px' }}></i> :
                                                        <i class="bi bi-arrow-up-circle me-2 text-danger" style={{ fontSize: '16px' }}></i>
                                                    }
                                                    {trx?.type}</span>
                                                {/* <button style={{cursor: 'default'}} type="button" class="btn light btn-light btn-xxs d-flex align-items-center">
                                                    <i class="bi bi-arrow-up-circle me-2 text-danger" style={{ fontSize: '16px' }}></i>
                                                    {trx?.type}
                                                </button> */}
                                            </td>
                                            <td>{trx?.payment_mode}</td>
                                            <td>{trx?.amount}</td>
                                            <td>
                                                {trx?.fees}
                                            </td>
                                            <td>
                                                {trx?.beneficiary_name}
                                            </td>
                                            <td>
                                                {trx?.reason_payment}
                                            </td>
											 <td>
                                                {trx?.description}
                                            </td>
                                            <td style={{ whiteSpace: 'nowrap' }}>
                                                {trx?.status == 'Pending' ? <i class="bi bi-hourglass-split me-1 text-info"></i> :
                                                    <i class="bi bi-check2-circle me-1 text-success"></i>
                                                }

                                                {trx?.status}
                                            </td>
                                            <td style={{ whiteSpace: 'nowrap' }}>
                                                {trx?.transaction_date}
                                            </td>
                                            <td className="d-flex gap-2">

                                                <button onClick={() => getTransactionDetails(trx?.trx_id)} type="button" class="btn  btn-info btn-xxs d-flex align-items-center" data-bs-toggle="modal" data-bs-target="#transactionView">
                                                    <i class="bi bi-eye me-2" style={{ fontSize: '16px' }}></i>
                                                    View</button>
                                                {/* <button onClick={() => { setSelectedTransaction(trx) }} type="button" class="btn  btn-light btn-xxs d-flex align-items-center" data-bs-toggle="modal" data-bs-target="#uploadTransactionDocument">
                                                <i class="fa fa-upload color-success"  style={{ fontSize: '16px' }}></i>
                                                    Upload Document</button> */}
                                                {trx?.is_upload_document === "1" && < button onClick={() => setSelectedTransaction(trx?.trx_id)} type="button" class="btn btn-xxs btn-success  d-flex align-items-center " data-bs-toggle="modal" data-bs-target="#uploadTransactionDocument">
                                                    <i class="fa fa-upload color-success me-2" ></i>
                                                    Document
                                                </button>}
                                                <button onClick={() => downloadInvoice(trx?.trx_id)} type="button" class="btn  btn-success btn-xxs">
                                                    {downloadInvoiceLoading == trx?.trx_id ? <div class="spinner-border" style={{ height: "12px", width: "12px" }} role="status">
                                                        <span class="sr-only">Loading...</span>
                                                    </div> : <i class="fa fa-download color-warning"></i>}
                                                </button>
                                            </td>

                                        </tr>
                                    })}



                                </tbody>
                            </table>
                        </div> :

                            <div class="container h-100">
                                <div class="row justify-content-center h-100 align-items-center">
                                    <div class="col-md-7">
                                        <div class="form-input-content text-center error-page">
                                            {/* <h1 class="error-text fw-bold">OOPS</h1> */}
                                            <h4><i class="fa fa-exclamation-triangle text-warning"></i>You have not made a transaction</h4>
                                            <p>Make some transactions to you beneficiaries to view the transactions history .</p>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        }
                    </div> :
                        <div className="text-center my-5 ">
                            <div class="spinner-border" style={{ height: "50px", width: "50px" }} role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                    }
                </div>

                {!loading && <div className="text-end float-right d-flex justify-content-between flex-wrap gap-4">
                    <div className="">
                        <a onClick={() => { getTransactions(pagination.page_number, 10); setPagination({ ...pagination, per_page: 10 }) }} href="javascript:void(0)" class="badge badge-circle badge-info me-2 pt-2" style={{ minWidth: '40px', minHeight: '40px' }}>10</a>
                        <a onClick={() => { getTransactions(pagination.page_number, 20); setPagination({ ...pagination, per_page: 20 }) }} href="javascript:void(0)" class="badge badge-circle badge-info me-2 pt-2" style={{ minWidth: '40px', minHeight: '40px' }}>20</a>
                        <a onClick={() => { getTransactions(pagination.page_number, 50); setPagination({ ...pagination, per_page: 50 }) }} href="javascript:void(0)" class="badge badge-circle badge-info me-2 pt-2" style={{ minWidth: '40px', minHeight: '40px' }}>50</a>
                        <a onClick={() => { getTransactions(pagination.page_number, 100); setPagination({ ...pagination, per_page: 100 }) }} href="javascript:void(0)" class="badge badge-circle badge-info me-2 pt-2" style={{ minWidth: '40px', minHeight: '40px' }}>100</a>
                    </div>
                    <Pagination
                        totalPages={Math.ceil(pagination.num_rows / pagination.per_page)}
                        currentPage={pagination.page_number}
                        onPageChange={getTransactions}
                    />
                    {/* <nav >
                        <ul class="pagination pagination-gutter pagination-primary no-bg">
                            <li class="page-item page-indicator">
                                <a class="page-link" href="javascript:void(0)">
                                    <i class="la la-angle-left"></i></a>
                            </li>
                            <li class="page-item active"><a class="page-link" href="javascript:void(0)">1</a>
                            </li>
                            <li class="page-item "><a class="page-link" href="javascript:void(0)" onClick={() => { getTransactions(2) }}>2</a></li>
                            <li class="page-item"><a class="page-link" href="javascript:void(0)">3</a></li>
                            <li class="page-item"><a class="page-link" href="javascript:void(0)">4</a></li>
                            <li class="page-item"><a class="page-link" href="javascript:void(0)">4</a></li>
                            <li class="page-item"><a class="page-link" href="javascript:void(0)">4</a></li>
                            <li class="page-item"><a class="page-link" href="javascript:void(0)">4</a></li>
                            <li class="page-item"><a class="page-link" href="javascript:void(0)">4</a></li>
                            <li class="page-item"><a class="page-link" href="javascript:void(0)">4</a></li>
                            <li class="page-item"><a class="page-link" href="javascript:void(0)">4</a></li>
                            <li class="page-item page-indicator">
                                <a class="page-link" href="javascript:void(0)">
                                    <i class="la la-angle-right"></i></a>
                            </li>
                        </ul>
                    </nav> */}
                </div>}

                {/* transaction view Modal */}
                <div class="modal fade" id="transactionView">
                    <div class="modal-dialog modal-dialog-centered" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title">Transaction detail</h5>
                                <button ref={closeRef} type="button" class="btn-close" data-bs-dismiss="modal" onClick={() => { setTrxDetails(null); }}>
                                </button>
                            </div>
                            {trxDetails ? <div class="modal-body">
								<div className="d-flex justify-content-between">
                                    <span >Transaction Type</span>
                                    <span >{trxDetails?.type}</span>
                                </div>								
                                <hr />
								<div className="d-flex justify-content-between">
                                    <span >Payment Mode</span>
                                    <span >{trxDetails?.payment_mode}</span>
                                </div>
                                <hr />
                                <div className="d-flex justify-content-between">
                                    <span >Transaction ID</span>
                                    <span >{trxDetails?.transaction_id}</span>
                                </div>
                                <hr />
                                <div className="d-flex justify-content-between">
                                    <span >Label</span>
                                    <span >{trxDetails?.label}</span>
                                </div>
                                <hr />
								
                                <div className="d-flex justify-content-between">
                                    <span >Beneficiary Name</span>
                                    <span >{trxDetails?.beneficiary_name}</span>
                                </div>
                                <hr />
                                <div className="d-flex justify-content-between">
                                    <span >Payment Reason</span>
                                    <span >{trxDetails?.reason_payment}</span>
                                </div>
                                <hr />
                                 <div className="d-flex justify-content-between gap-5">
                                    <span >Description</span>
                                    <span >{trxDetails?.description}</span>
                                </div>	
								<hr />	
								{trxDetails?.payment_mode == 'sepa' ? 
									<div className="d-flex justify-content-between"><span >Amount</span><span >{trxDetails?.amount}</span></div> : '' 
								}
								{trxDetails?.payment_mode == 'sepa' ? 
									<div className="d-flex justify-content-between"><span >Fee </span><span >{trxDetails?.fees}</span></div> : '' 
								}
								
								{trxDetails?.payment_mode == 'swift' ? 
									<div className="d-flex justify-content-between"><span >Amount</span><span >{trxDetails?.amount}</span></div> : '' 
								}
								{trxDetails?.payment_mode == 'swift' ? 
									<div className="d-flex justify-content-between"><span >Fee </span><span >{trxDetails?.fees}</span></div> : '' 
								}
								{trxDetails?.payment_mode == 'swift' ? 
									<hr />: '' 
								}
								{trxDetails?.payment_mode == 'swift' && trxDetails?.type == 'credit' ? 
									<div className="d-flex justify-content-between"><span >Received Amount</span><span >{trxDetails?.received}</span></div> : '' 
								}
								{trxDetails?.payment_mode == 'swift' ? 
									<div className="d-flex justify-content-between"><span >Exchange Amount</span><span >{trxDetails?.exchange_amt}</span></div> : '' 
								}
								{trxDetails?.payment_mode == 'swift' ? 
									<div className="d-flex justify-content-between"><span >Exchange Fee</span><span >{trxDetails?.exchange_fee}</span></div> : '' 
								}
								{trxDetails?.payment_mode == 'swift' ? 
									<div className="d-flex justify-content-between"><span >Swift Fee</span><span >{trxDetails?.swift_fee}</span></div> : '' 
								}
								{trxDetails?.payment_mode == 'swift' && trxDetails?.type == 'debit' ? 
									<div className="d-flex justify-content-between"><span >Total Pay</span><span >{trxDetails?.total_pay}</span></div> : '' 
								}
								
								{trxDetails?.payment_mode == 'swift' && trxDetails?.type == 'credit' ? 
									<div className="d-flex justify-content-between"><span >Received Amount</span><span >{trxDetails?.received}</span></div> : '' 
								}
								
								{trxDetails?.payment_mode == 'swift' && trxDetails?.type == 'credit'  && trxDetails?.account_number != ''  ? 
									<div className="d-flex justify-content-between"><span >Sender Acct.</span><span >{trxDetails?.account_number}</span></div> : '' 
								}
								
								{trxDetails?.payment_mode == 'swift' && trxDetails?.type == 'credit' && trxDetails?.bic != '' ? 
									<div className="d-flex justify-content-between"><span >Sender Amount</span><span >{trxDetails?.bic}</span></div> : '' 
								}
								{trxDetails?.payment_mode == 'swift' && trxDetails?.type == 'credit' && trxDetails?.routing_code != ''? 
									<div className="d-flex justify-content-between"><span >Sender Amount</span><span >{trxDetails?.routing_code}</span></div> : '' 
								}
								{trxDetails?.payment_mode == 'swift' && trxDetails?.type == 'credit' && trxDetails?.sender_address != ''? 
									<div className="d-flex justify-content-between"><span >Sender Address</span><span >{trxDetails?.sender_address}</span></div> : '' 
								}
								{trxDetails?.payment_mode == 'swift' && trxDetails?.type == 'credit' && trxDetails?.sender_country != ''? 
									<div className="d-flex justify-content-between"><span >Sender Address</span><span >{trxDetails?.sender_country}</span></div> : '' 
								}
								
                               
                                <hr />
                                <div className="d-flex justify-content-between">
                                    <span >Status</span>
                                    <span >{trxDetails?.status}</span>
                                </div>
                                <hr />
                                {/* <div className="text-center">

                                    <button className="btn btn-primary btn-sm" onClick={() => { closeRef.current.click() }}>
                                        <Link to={`/transactions-details/${trxDetails?.unique_id}`}>
                                        View Full Details
                                        </Link>
                                    </button>
                                </div> */}
                            </div> :
                                <div class="modal-body text-center">
                                    <div class="spinner-border" style={{ height: "50px", width: "50px" }} role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                            }

                        </div>
                    </div>
                </div>

                {/* transaction view Modal */}


                {/* upload document modal */}
                <div class="modal fade" id="uploadTransactionDocument">
                    <div class="modal-dialog modal-dialog-centered" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title">Upload Transaction Document</h5>
                                <button ref={docCloseRef} type="button" class="btn-close" data-bs-dismiss="modal" >
                                </button>
                            </div>
                            <div class="modal-body">
                                <div className="mb-3 row">
                                    <label
                                        className=" col-form-label"
                                        htmlFor="validationCustom11"
                                    >
                                        Transaction document
                                        <span className="text-danger">*</span>
                                    </label>
                                    <div className="">
                                        <input class="form-control custom-file-input"
                                            onChange={(e) => setTRXDocument(e.target.files[0])}

                                            type="file" id="formFile" />

                                        {uploaded_error && (
                                            <span className="text-danger">Please upload transaction document</span>
                                        )}
                                    </div>
                                </div>
                                <div className="row text-end">
                                    <div>
                                        {/* <button onClick={uploadDocument} type="button" class="btn btn-success">Upload</button> */}
                                        <button disabled={uploadTrxDocLoading} onClick={uploadDocument} type="button" class="btn btn-success mb-2 ">Upload
                                        <span >
                                            {uploadTrxDocLoading && <div class="spinner-border ms-2" style={{ height: "20px", width: "20px" }} role="status">
                                                <span class="sr-only">Loading...</span>
                                            </div>}
                                        </span>
                                    </button>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
                {/* upload document modal */}

                <div className="text-end float-right d-flex justify-content-end">
                    {/* Ant Design Pagination with custom classes */}
                    {/* <Pagination
                        total={pagination.num_rows}
                        pageSize={pagination.per_page}
                        current={pagination.page_number}
                        showSizeChanger={false}
                        showQuickJumper={false}
                        // onChange={onPageChange}
                        className="pagination pagination-gutter pagination-primary no-bg" // Add your custom classes
                        itemRender={(page, type, originalElement) => (
                            type === 'prev' ? (
                                <a className="page-link" href="#">
                                    <i className="la la-angle-left"></i>
                                </a>
                            ) : type === 'next' ? (
                                <a className="page-link" href="#">
                                    <i className="la la-angle-right"></i>
                                </a>
                            ) : (
                                <a className="page-link active" href="#">
                                    {originalElement}
                                </a>
                            )
                        )}
                    /> */}
                </div>
            </div >

        </>
    )
}

const Pagination = ({ totalPages, currentPage, onPageChange }) => {
    const maxPagesToShow = 4;

    const calculatePageNumbers = () => {
        const pageNumbers = [];

        // Calculate the starting and ending page numbers to display
        let startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
        let endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);

        // Adjust if the ending page number is less than the max pages to show
        if (endPage < maxPagesToShow) {
            endPage = Math.min(maxPagesToShow, totalPages);
        }

        // Add page numbers to the array
        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(i);
        }

        return pageNumbers;
    };

    const renderPagination = () => {
        const pageNumbers = calculatePageNumbers();

        return (
            <ul className="pagination pagination-gutter pagination-primary no-bg">
                {/* Previous Page */}
                <li className={`page-item page-indicator ${currentPage === 1 ? 'disabled' : ''}`}>
                    <button className="page-link" onClick={() => onPageChange(currentPage - 1)}>
                        <i className="la la-angle-left"></i>
                    </button>
                </li>

                {/* Page Numbers */}
                {pageNumbers.map((page) => (
                    <li key={page} className={`page-item ${page === currentPage ? 'active' : ''}`}>
                        <button className="page-link" onClick={() => onPageChange(page)}>
                            {page}
                        </button>
                    </li>
                ))}

                {/* Dots */}
                {totalPages > maxPagesToShow && currentPage < totalPages - maxPagesToShow + 1 && (
                    <li className="page-item disabled">
                        <span className="page-link">...</span>
                    </li>
                )}

                {/* Next Page */}
                <li className={`page-item page-indicator ${currentPage === totalPages ? 'disabled' : ''}`}>
                    <button className="page-link" onClick={() => onPageChange(currentPage + 1)}>
                        <i className="la la-angle-right"></i>
                    </button>
                </li>
            </ul>
        );
    };

    return <nav>{renderPagination()}</nav>;
};
export default TransactionsHistory