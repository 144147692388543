import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { API_ENDPOINTS, API_METHODS } from "../../constants/endpoints";
import apiCall from "../../utils/apiCall";
import { whitelabel_id } from "../../constants/ids";
import toastr from 'toastr'
const CompanyShareHolders = () => {
    const formOfWealthDocument = '/assets/sourceOfWealthDeclartation2023.pdf'
    const authUser = JSON.parse(localStorage.getItem('auth_user'))
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [roles, setRoles] = useState([])
    const [inputs, setInputs] = useState({ first_name: '', last_name: '', address: '', zipcode: '', city: '', address: '', percentage: '' })
    const [countries, setCountries] = useState([])
    const [idProofFile, setIdProofFile] = useState(null);
    const [addressProofFile, setAddressProofFile] = useState(null);
    const [proofOfFundFile, setProofOfFundFile] = useState(null);
    const [sourceOfWealthFile, setSourceOfWealthFile] = useState(null);
    const roleRef = useRef()
    const genderRef = useRef()
    const nationalityRef = useRef()
    const countryRef = useRef()
    const dobRef = useRef()
    const incomeRef = useRef()
    const [fieldErrors, setFieldErrors] = useState({});
    const [sourceOfFundList, setSourceOfFundList] = useState([])
    const [uploadingLoading, setUploadingLoading] = useState(false)

    const [inputValidationRules, setValidationRules] = useState({

        first_name: "First Name is required",
        last_name: "Last Name is required",
        address: "Address is required",
        zipcode: "Zip code is required",
        city: "Residence City is required",
        dob: "Date of Birth is required",
        percentage: "Share holder percentage is required",
        nationality: 'Nationality is required',
        idProof: 'Please upload Id Proof',
        addressProof: 'Please upload Address Proof',
        proofOfFund: 'Please upload Proof of Fund',
        sourceOfWealth: 'Please upload Source of Wealth',
        country: 'Residence country is required',
        role: 'Share holder Role is required',
        gender: 'Gender is required',
        income: 'Source of income is required',
    });

    useEffect(() => {
        const scriptUrls = [
            "js/plugins-init/select2-init.js",
            "vendor/select2/js/select2.full.min.js",
            "vendor/bootstrap-material-datetimepicker/js/bootstrap-material-datetimepicker.js",
            "js/plugins-init/material-date-picker-init.js",
            "vendor/bootstrap-daterangepicker/daterangepicker.js",
            "vendor/moment/moment.min.js",
        ];
        scriptUrls.forEach((scriptUrl) => {
            const script = document.createElement("script");
            script.src = scriptUrl;
            script.async = true;
            document.body.appendChild(script);
        });

        return () => {
            scriptUrls.forEach((scriptUrl) => {
                const script = document.querySelector(`[src="${scriptUrl}"]`);
                if (script) {
                    script.remove();
                }

            });
        };


    }, []);
    useEffect(() => {
        if (!authUser) {
            return navigate('/login')
        }
        if (authUser && !authUser?.web_token) {
            return navigate('/login')
        }
        if (authUser && authUser?.is_live == 1) {
            return navigate('/balance')
        }
        getDirectorRoles()
        getCountryList()
        getSourceOfFund()
    }, [])
    const getDirectorRoles = async () => {
        const body = {
            whitelabel_id: whitelabel_id
        }
        const url = API_ENDPOINTS.GET_DIRECTOR_ROLE
        const method = API_METHODS.POST
        const response = await apiCall(url, method, body)
        if (response?.status == 1) {
            setRoles(roleObjectToArray(response.role))
            setLoading(false)
        } else if (response?.status == 0) {
            console.error('Unable to get Director Roles.')
            setLoading(false)
        } else {
            console.error('Unable to get Director Roles.')
        }
    }

    const getCountryList = async () => {
        const body = {
            whitelabel_id: whitelabel_id
        }
        const url = API_ENDPOINTS.GET_COUNTRY_LIST
        const method = API_METHODS.POST
        const response = await apiCall(url, method, body)
        if (response?.status == 1) {
            const sortedCountries = response.country.sort((a, b) => a.name.localeCompare(b.name));
            setCountries(sortedCountries)
            setLoading(false)
        } else if (response?.status == 0) {
            console.error('Unable to get Countries list.')
            setLoading(false)
        } else {
            console.error('Unable to get Countries list.')
        }
    }

    const getSourceOfFund = async () => {
        const body = {
            whitelabel_id: whitelabel_id
        }
        const url = API_ENDPOINTS.SOURCE_OF_FUND
        const method = API_METHODS.POST
        const response = await apiCall(url, method, body)
        if (response?.status == 1) {
            setSourceOfFundList(response?.data)
            setLoading(false)
        } else if (response?.status == 0) {
            console.error('Unable to get source of fund list.')
            setLoading(false)
        } else {
            console.error('Unable to get source of fund list.')
        }
    }



    function roleObjectToArray(roleObject) {
        const roleArray = [];

        for (const role in roleObject) {
            roleArray.push({ value: role, name: roleObject[role] });
        }
        return roleArray;
    }
    const handleFileChange = (file, setFile) => {
        // Logic to handle file change
        setFile(file);
    };

    const validateInputs = () => {
        const errors = {};

        Object.keys(inputs).forEach((field) => {
            if (!inputs[field]) {
                errors[field] = inputValidationRules[field];
            }
        });


        if (!countryRef.current.value) {
            errors.country = inputValidationRules.country;
        }

        if (!roleRef.current.value) {
            errors.role = inputValidationRules.role;
        }
        if (!genderRef.current.value) {
            errors.gender = inputValidationRules.gender;
        }
        if (!nationalityRef.current.value) {
            errors.nationality = inputValidationRules.nationality;
        }
        if (!dobRef.current.value) {
            errors.dob = inputValidationRules.dob;
        }
        if (!incomeRef.current.value) {
            errors.income = inputValidationRules.income;
        }
        if (!idProofFile) {
            errors.idProof = inputValidationRules.idProof;
        }
        if (!addressProofFile) {
            errors.addressProof = inputValidationRules.addressProof;
        }
        if (!proofOfFundFile) {
            errors.proofOfFund = inputValidationRules.proofOfFund;
        }
        if (!sourceOfWealthFile) {
            errors.sourceOfWealth = inputValidationRules.sourceOfWealth;
        }

        setFieldErrors(errors);
        return Object.keys(errors).length === 0;
    };
    const addShareHolder = async () => {
        setFieldErrors({});
        if (!validateInputs()) {
            return;
        }
        setUploadingLoading(true)
        const authUser = JSON.parse(localStorage.getItem("auth_user"))
        const { web_token, user_id } = authUser
        const url = API_ENDPOINTS.ADD_SHARE_HOLDER
        const formData = new FormData();
        formData.append('whitelabel_id', whitelabel_id)
        formData.append('web_token', web_token)
        formData.append('user_id', user_id)
        formData.append('first_name', inputs?.first_name)
        formData.append('last_name', inputs?.last_name)
        formData.append('zipcode', inputs?.zipcode)
        formData.append('address', inputs?.address)
        formData.append('city', inputs?.city)
        formData.append('shareholder_percentage', inputs?.percentage)
        formData.append('dob', dobRef?.current?.value)
        formData.append('role', roleRef?.current?.value)
        formData.append('nationality', nationalityRef?.current?.value)
        formData.append('address_country', countryRef?.current?.value)
        formData.append('gender', genderRef?.current?.value)
        formData.append('id_proof', idProofFile)
        formData.append('address_proof', addressProofFile)
        formData.append('proof_of_fund', proofOfFundFile)
        formData.append('source_of_wealth', sourceOfWealthFile)
        formData.append('source_of_income', incomeRef?.current.value)

        const response = await apiCall(url, API_METHODS.POST, formData, true)
        if (response?.status == 1) {
            setUploadingLoading(false)
            toastr.success(response?.message || 'Share Holder Added successfully.')
            navigate('/company-share-holders-list')

        } else if (response?.status == 0) {
            toastr.error(response?.message || "Unable to add share holder.")
            setUploadingLoading(false)

        } else {
            toastr.error('Unable to add share holder.')
        }
        setUploadingLoading(false)
    }
    return (
        <>
            <div class="row page-titles">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item active"><Link to={"/account-confirmation"}>Home</Link></li>
                    <li class="breadcrumb-item"><a href="javascript:void(0)">Add Company Share Holders</a></li>
                </ol>
            </div>
            <div>
                <h2>Company Share Holders</h2>
            </div>

            <div class="d-flex align-items-center mb-4 flex-wrap">
                {/* <div class="card-tabs mt-3 mt-sm-0 me-auto">
                    <ul class="nav nav-tabs trans-tab" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link underline active" data-bs-toggle="tab" href="#corporate-documents" role="tab">Share Holder Detail</a>
                        </li>

                    </ul>
                </div> */}




            </div>


            <div className="row">

                <div className="col-12 col-lg-12 col-md-12">

                    <div className="card">
                        <div className="card-header">
                            <h4>Share Holder</h4>
                        </div>
                        <div className="card-body">
                            <div className="text-start">
                                <h5>Add Company Share Holder Details</h5>
                            </div>

                            <div class="basic-form">
                                <form>

                                    <div class="row">
                                        <div class="mb-3 col-md-6">
                                            <label class="form-label">First Name</label>
                                            <input onChange={(e) => setInputs((prevInputs) => ({ ...prevInputs, first_name: e.target.value }))} type="text" class="form-control" placeholder="Enter first name" />
                                            {fieldErrors.first_name && (
                                                <div className="text-danger">{fieldErrors.first_name}</div>
                                            )}
                                        </div>
                                        <div class="mb-3 col-md-6">
                                            <label class="form-label">Last Name</label>
                                            <input onChange={(e) => setInputs((prevInputs) => ({ ...prevInputs, last_name: e.target.value }))} type="text" class="form-control" placeholder="Enter last name" />
                                            {fieldErrors.last_name && (
                                                <div className="text-danger">{fieldErrors.last_name}</div>
                                            )}
                                        </div>
                                        <div class="mb-3 col-md-6">
                                            <label class="form-label">Role</label>
                                            <select ref={roleRef} class="default-placeholder " placeholder="Select Role">
                                                <option selected disabled value={""}>Select Role</option>
                                                {roles?.length > 0 && roles.map((r) => {
                                                    return <option value={r.value}>{r.name}</option>
                                                })}
                                            </select>
                                            {fieldErrors.role && (
                                                <div className="text-danger">{fieldErrors.role}</div>
                                            )}
                                        </div>
                                        <div class="mb-3 col-md-6">
                                            <label class="form-label">Date of Birth</label>
                                            <input ref={dobRef} name="mdate" class="datepicker-default form-control" id="mdate" placeholder="2023-06-24" />
                                            {fieldErrors.dob && (
                                                <div className="text-danger">{fieldErrors.dob}</div>
                                            )}
                                        </div>
                                        <div class="mb-3 col-md-6">
                                            <label class="form-label">Gender</label>
                                            <select ref={genderRef} class="default-placeholder " placeholder="Select Role">
                                                <option selected disabled value={""}>Select Gender</option>
                                                <option value={"male"}>Male</option>
                                                <option value={"female"}>Female</option>
                                            </select>
                                            {fieldErrors.gender && (
                                                <div className="text-danger">{fieldErrors.gender}</div>
                                            )}
                                        </div>
                                        <div class="mb-3 col-md-6">
                                            <label class="form-label">Nationality</label>
                                            <select ref={nationalityRef} class="default-placeholder " placeholder="Select Role">
                                                <option selected disabled value={""}>Select Country</option>
                                                {countries?.length > 0 && countries.map((c) => {
                                                    return <option value={c.id}>{c.name}</option>
                                                })}
                                            </select>
                                            {fieldErrors.nationality && (
                                                <div className="text-danger">{fieldErrors.nationality}</div>
                                            )}
                                        </div>
                                        <div class="mb-3 col-12">
                                            <label class="form-label">Residence Address</label>
                                            <textarea onChange={(e) => setInputs((prevInputs) => ({ ...prevInputs, address: e.target.value }))} class="form-control" id="address" rows="2" placeholder="Detailed Residence Address?" required></textarea>
                                            {fieldErrors.address && (
                                                <div className="text-danger">{fieldErrors.address}</div>
                                            )}
                                        </div>
                                        <div class="mb-3 col-12">
                                            <label class="form-label">Residence Country</label>
                                            <select ref={countryRef} class="default-placeholder " placeholder="Select Role">
                                                <option selected disabled value={""}>Select Country</option>
                                                {countries?.length > 0 && countries.map((c) => {
                                                    return <option value={c.id}>{c.name}</option>
                                                })}
                                            </select>
                                            {fieldErrors.country && (
                                                <div className="text-danger">{fieldErrors.country}</div>
                                            )}
                                        </div>
                                        <div class="mb-3 col-md-6">
                                            <label class="form-label">Residence City</label>
                                            <input onChange={(e) => setInputs((prevInputs) => ({ ...prevInputs, city: e.target.value }))} type="text" class="form-control" placeholder="Enter residence city" />
                                            {fieldErrors.city && (
                                                <div className="text-danger">{fieldErrors.city}</div>
                                            )}
                                        </div>
                                        <div class="mb-3 col-md-6">
                                            <label class="form-label">Zip</label>
                                            <input onChange={(e) => setInputs((prevInputs) => ({ ...prevInputs, zipcode: e.target.value }))} type="text" class="form-control" />
                                            {fieldErrors.zipcode && (
                                                <div className="text-danger">{fieldErrors.zipcode}</div>
                                            )}
                                        </div>
                                        <div class="mb-3 col-md-12">
                                            <label class="form-label">Share Holder percentage</label>
                                            <input onChange={(e) => setInputs((prevInputs) => ({ ...prevInputs, percentage: e.target.value }))} type="text" class="form-control" placeholder="Enter share holder percentage" />
                                            {fieldErrors.percentage && (
                                                <div className="text-danger">{fieldErrors.percentage}</div>
                                            )}
                                        </div>
                                        <div class="mb-3 col-md-6">
                                            <label class="form-label">ID Proof</label>
                                            <input class="form-control" type="file" id="idProofFile" onChange={(e) => handleFileChange(e.target.files[0], setIdProofFile)} />
                                            {fieldErrors.idProof && (
                                                <div className="text-danger">{fieldErrors.idProof}</div>
                                            )}

                                        </div>
                                        <div class="mb-3 col-md-6">
                                            <label class="form-label">Address Proof</label>
                                            <input class="form-control" type="file" id="addressProofFile" onChange={(e) => handleFileChange(e.target.files[0], setAddressProofFile)} />
                                            {fieldErrors.addressProof && (
                                                <div className="text-danger">{fieldErrors.addressProof}</div>
                                            )}

                                        </div>
                                        <div class="mb-3 col-md-6">
                                            <label class="form-label">Proof of Fund</label>
                                            <input class="form-control" type="file" id="proofOfFundFile" onChange={(e) => handleFileChange(e.target.files[0], setProofOfFundFile)} />
                                            {fieldErrors.proofOfFund && (
                                                <div className="text-danger">{fieldErrors.proofOfFund}</div>
                                            )}

                                        </div>
                                        <div class="mb-3 col-md-6">
                                            <label class="form-label">Source of Income</label>
                                            <select ref={incomeRef} class="default-placeholder " placeholder="Select Role">
                                                <option selected disabled value={""}>Select source</option>
                                                {sourceOfFundList?.length > 0 && sourceOfFundList.map((r) => {
                                                    return <option value={r}>{r}</option>
                                                })}
                                            </select>
                                            {fieldErrors.income && (
                                                <div className="text-danger">{fieldErrors.income}</div>
                                            )}
                                        </div>
                                        <div class="mb-3 col-md-12 ">
                                            <small className="m-0 p-0 ">Please download the source of wealth declaration form from the link below and upload the filled form in Source of Wealth</small>
                                            <div className="d-flex justify-content-start">
                                                <a href={formOfWealthDocument} download className="text-decoration-underline">
                                                    <p>Download Form</p>
                                                </a></div>
                                        </div>
                                        <div class="mb-3 col-md-12">
                                            <label class="form-label">Source of Wealth</label>
                                            <input class="form-control" type="file" id="sourceOfWealthFile" onChange={(e) => handleFileChange(e.target.files[0], setSourceOfWealthFile)} />

                                            {fieldErrors.sourceOfWealth && (
                                                <div className="text-danger">{fieldErrors.sourceOfWealth}</div>
                                            )}
                                        </div>
                                    </div>

                                    <button disabled={uploadingLoading} onClick={() => addShareHolder()} type="button" class="btn btn-default d-flex gap-2 align-items-center">
                                        Save
                                        {uploadingLoading && <div class="spinner-border" style={{ height: "16px", width: "16px" }} role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>}
                                    </button>
                                </form>
                            </div>
                        </div>

                    </div>
                </div>
            </div>


        </>
    )
}
export default CompanyShareHolders;