import React from 'react';

const BigCircleWithTick = () => {
   
  return (
    
      <i class="bi bi-check-circle bi-3x" style={{color:'#4EF30C', fontSize: '4rem' }}></i>
 
  );
};

export default BigCircleWithTick;
