import { useEffect } from "react";
import { useTheme } from "../../Theme/themeContext";

const Section2R = ({ content }) => {
    const { lightTheme, toggleTheme } = useTheme();
    useEffect(() => {

        const scriptUrls = [
            "js/custom.min.js",
            "js/dlabnav-init.js",

        ];

        scriptUrls.forEach((scriptUrl) => {
            const script = document.createElement("script");
            script.src = scriptUrl;
            script.async = true;
            document.body.appendChild(script);
        });
        // Load each script asynchronously

        return () => {
            // Clean up: Remove the script elements when the component unmounts
            scriptUrls.forEach((scriptUrl) => {
                const script = document.querySelector(`[src="${scriptUrl}"]`);
                if (script) {
                    script.remove();
                }
            });
        };
    }, []);
    return (
        <div style={{ marginTop: '200px', backgroundColor: lightTheme ? 'rgba(207, 207, 207, 0.6)' : "#4C2FBA" }} className=" mx-auto cardColor business-container pt-3 ">
            <div className=" text-start ms-5 mt-5"

            >
                <h1 className="mt-5">{content?.section_1_title} </h1>
            </div>
            <div className="row mx-auto mt-5">
                <div className="col-lg-4 col-md-12 col-sm-12">
                    <div className="card mx-auto p-5  card-animate"
                    // style={{ minHeight: '400px', paddingTop: '50px', paddingBottom: '60px', paddingRight: '50px', paddingLeft: '50px' }}
                    >
                        <img className="bounce-image" style={{ width: '50px', height: '50px' }} src="https://codegopay.com/business/assets/business_panel/img/service/service-icon-1.png" alt="na" />

                        <h4 style={{ fontSize: "28px", lineHeight: '30px', marginTop: '50px' }} >{content?.section_1_text_1_upper}</h4>
                        <div className="text-white " style={{ marginTop: '50px' }}>
                            {content?.section_1_text_1_lower}
                        </div>

                    </div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12">
                    <div className="card mx-auto p-5  card-animate "
                    // style={{ minHeight: '400px', paddingTop: '50px', paddingBottom: '60px', paddingRight: '50px', paddingLeft: '50px' }}
                    >
                        <img className="bounce-image" style={{ width: '50px', height: '50px' }} src="https://codegopay.com/business/assets/business_panel/img/service/service-icon-2.png" alt="na" />

                        <h4 style={{ fontSize: "28px", lineHeight: '30px', marginTop: '50px' }} >{content?.section_1_text_2_upper}</h4>
                        <div className="text-white " style={{ marginTop: '50px' }}>
                            {content?.section_1_text_2_lower}
                        </div>

                    </div>
                </div><div className="col-lg-4 col-md-12 col-sm-12">
                    <div className="card mx-auto p-5  card-animate "
                    // style={{ minHeight: '400px', paddingTop: '50px', paddingBottom: '60px', paddingRight: '50px', paddingLeft: '50px' }}
                    >
                        <img className="bounce-image" style={{ width: '50px', height: '50px' }} src="https://codegopay.com/business/assets/business_panel/img/service/service-icon-3.png" alt="na" />

                        <h4 style={{ fontSize: "28px", lineHeight: '30px', marginTop: '50px' }} > {content?.section_1_text_3_upper}</h4>
                        <div className="text-white " style={{ marginTop: '50px' }}>
                            {content?.section_1_text_3_lower}
                        </div>

                    </div>
                </div>

            </div>
        </div>
    )
}
export default Section2R;