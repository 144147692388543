import { useEffect, useState } from "react";
import { API_ENDPOINTS, API_METHODS } from "../../constants/endpoints";
import { useNavigate } from "react-router-dom";
import { whitelabel_id } from "../../constants/ids";
import apiCall from "../../utils/apiCall";
import toastr from 'toastr'
const NotificationApproval = () => {
    const authUser = JSON.parse(localStorage.getItem('auth_user'))
    const [seconds, setSeconds] = useState(60);
    const navigate = useNavigate()
    useEffect(() => {
        const intervalId = setInterval(() => {
            if (seconds > 0) {
                setSeconds((prevSeconds) => prevSeconds - 1);
            } else {
                clearInterval(intervalId);
            }
        }, 1000);


        return () => clearInterval(intervalId);
    }, [seconds]);
    useEffect(() => {
        if (!authUser) {
            return navigate('/login')
        }
        if (authUser && authUser?.is_live == 1 && authUser?.web_token) {
            return window.location.href = '/balance'
        }
        if (authUser && authUser?.is_live != 1) {
            return navigate('/login')
        }

       
        const intervalId = setInterval(() => {
            verify();
        }, 3000);

        return () => clearInterval(intervalId);
    }, [])
    const verify = async () => {


        const url = API_ENDPOINTS.WEB_VERIFY_LOGIN
        const { token } = authUser
        const body = {
            whitelabel_id: whitelabel_id,
            // user_id: authUser?.user_id,
            // unique_id: authUser?.unique_id,
            token: token
        }

        const response = await apiCall(url, API_METHODS.POST, body)
        if (response?.status == 3) {
           
            return;
        } else if (response?.status == 2) {
            localStorage.removeItem('auth_user')
            toastr.error(response?.message)
            return navigate('/login')
        } else if (response?.status == 1) {
            const authUser = {
                company_name: response.company_name,
                email: response.email,
                user_id: response.user_id,
                web_token: response.web_token,
                is_live: response?.is_live,
                whitelabel_id: response?.whitelabel_id
            }
            localStorage.setItem("auth_user", JSON.stringify(authUser))
            sessionStorage.setItem('auth_user', JSON.stringify(authUser))
            toastr.success(response?.message || 'Verified Successfully')
            window.location.href = '#/balance'

        } else {
        }
    }
   
    const resendPush = async () => {
if(seconds>0){
   return toastr.warning(`you can try after ${seconds}`)
}
setSeconds(60)
        const url = API_ENDPOINTS.RESEND_PUSH
        const { token } = authUser
        const body = {
            whitelabel_id: whitelabel_id,
            // user_id: authUser?.user_id,
            // unique_id: authUser?.unique_id,
            token: token
        }

        const response = await apiCall(url, API_METHODS.POST, body)
		toastr.success(response?.message || 'Push sent Successfully')
    }
    return (
        <div>

            <div className="row mx-auto " style={{ marginTop: "200px" }}>

                <div className="col-10 col-md-10 col-lg-10 mx-auto">
                    <div className="card">
                        <div class="card-body">
                            <h2>To continue, approve the notification sent to:</h2>
                            <div className="mt-3">
                                <strong>Name:</strong> {authUser?.company_name}
                            </div>
                            <div className="mt-5">
                                <strong>Application:</strong> {authUser?.device_model}
                            </div>
                            <div className="mt-5">
                                <h4 className="m-0">Did not receive it?</h4>
                                <small onClick={resendPush} className="text-success" style={{ cursor: seconds > 0 ? 'default' : ' pointer' }}>
                                    Try again notification({seconds})</small><br />
                                <span>You may now request an additional notification, if needed</span><br/>
                                <span>OR</span><br/>
                                <span>If you do not receive a push, please check your email and verify.</span>
                            </div>
                            <div className="mt-5">
                                <h4 className="m-0">Did you already responded?</h4>
                                <small style={{ cursor: ' pointer' }} className="text-success" onClick={()=>{window.location.reload()}}>Click here to refresh the page</small>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}
export default NotificationApproval;