import { useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import { whitelabel_id } from "../../../constants/ids"
import { API_ENDPOINTS, API_METHODS } from "../../../constants/endpoints"
import apiCall from "../../../utils/apiCall"
import toastr from 'toastr'
const AddBeneficiary = () => {
    const navigate = useNavigate()
    const statuses = JSON.parse(localStorage.getItem('statuses'))
    const authUser = JSON.parse(localStorage.getItem('auth_user'))
    const ibanCountryRef = useRef()
    const addressCountryRef = useRef()
    const beneficiaryTypeRef = useRef()
    const accountTypeRef = useRef()
    const profileImageRef = useRef()
    const termsRef = useRef()
    const imageREf2 = useRef(null);
    const [termsCheck, setTermsCheck] = useState(false)
    const [countries, setCountries] = useState([])
    const [errors, setErrors] = useState({})
    const [profile_image, setProfile_image] = useState(null)
    const [loading, setLoading] = useState(false)
    const [loadingScripts, setLoadingScripts] = useState(false)
    const [selectedBeneficiary, setSelectedBeneficiary] = useState('personal')
    const [validationRules, setValidationRules] = useState({
        iban_account: "IBAN Account  is required",
        beneficiary_first_name: "Beneficiary First Name is required",
        beneficiary_last_name: "Beneficiary Last Name is required",
        beneficiary_email: "Beneficiary Email is required",
        beneficiary_city: "Beneficiary City is required",
        beneficiary_address: 'Beneficiary Address is reqruied',
        bic: 'BIC is reqruied',
        company_name: 'Company Name is reqruied',
    });
    const [selectValidationRules, setSelectValidationRules] = useState({
        iban_country: "IBAN country is required",
        address_country: "Address Country is required",
        beneficiary_type: "Beneficiary type is required",
        account_type: "Beneficiary Account type is required",
        profile_image: 'profile image is required',
        terms: 'You must accept the terms and condition.',

    })
    const [inputs, setInputs] = useState({
        iban_account: '',
        beneficiary_first_name: '',
        beneficiary_last_name: '',
        beneficiary_email: '',
        beneficiary_city: '',
        beneficiary_address: '',
        company_name: '',
        bic: ''
    })
    useEffect(() => {
        if (!authUser) {
            return navigate('/login')
        }
        if (authUser && !authUser?.web_token) {
            return navigate('/login')
        }
        if (authUser && authUser?.is_live !== 1) {
            if (statuses.company_status != "2" || statuses.director_status != "2" || statuses.document_status != "2") {
                return navigate('/account-confirmation')
            }
            return navigate('/account-confirmation')
        }
        getCountryList()
    }, [])

    useEffect(() => {
        const intervalId = setInterval(() => {
            const currentBeneficiaryType = beneficiaryTypeRef.current?.value;

            if (currentBeneficiaryType) {
                setSelectedBeneficiary(prevSelectedBeneficiary => {
                    if (prevSelectedBeneficiary !== currentBeneficiaryType) {
                        return currentBeneficiaryType;
                    }
                    return prevSelectedBeneficiary;
                });
            }
        }, 500);

        return () => clearInterval(intervalId);
    }, [beneficiaryTypeRef, setSelectedBeneficiary]);

    useEffect(() => {
        const scriptUrls = [
            "js/plugins-init/select2-init.js",
            "vendor/select2/js/select2.full.min.js",
        ];
        scriptUrls.forEach((scriptUrl) => {
            const script = document.createElement("script");
            script.src = scriptUrl;
            script.async = true;
            document.body.appendChild(script);
        });

        return () => {
            scriptUrls.forEach((scriptUrl) => {
                const script = document.querySelector(`[src="${scriptUrl}"]`);
                if (script) {
                    script.remove();
                }

            });
        };


    }, []);
    const getCountryList = async () => {
        const body = {
            whitelabel_id: whitelabel_id
        }
        const url = API_ENDPOINTS.GET_COUNTRY_LIST
        const method = API_METHODS.POST
        const response = await apiCall(url, method, body)
        if (response?.status == 1) {
            setCountries(response?.country)
        } else if (response?.status == 0) {
            console.error(response?.message || 'unable to fetch countries')
        } else {
            console.error(response?.error || 'unable to fetch countries')
        }
    }

    const addBeneficiary = async () => {
        setErrors({});
        if (!validateInputs()) {
            return;
        }
        setLoading(true)


        const { web_token, user_id } = authUser
        const formData = new FormData;
        formData.append('whitelabel_id', whitelabel_id)
        formData.append('web_token', web_token)
        formData.append('user_id', user_id)
        formData.append('type', beneficiaryTypeRef?.current?.value)
        formData.append('account', inputs?.iban_account)
        // formData.append('bic', inputs?.bic)
        formData.append('first_name', inputs?.beneficiary_first_name)
        formData.append('last_name', inputs?.beneficiary_last_name)
        formData.append('company_name', inputs?.company_name)
        formData.append('email', inputs?.beneficiary_email)
        if(profile_image){
            formData.append('beneficiary_image', profile_image)
        }
        // formData.append('account_type', accountTypeRef?.current?.value)
        const url = API_ENDPOINTS.ADD_BENEFICIARY
        const method = API_METHODS.POST
        const response = await apiCall(url, method, formData, true)
        if (response?.status == 1) {
            toastr.success(response?.message || 'Beneficiary added Successfully')
            setInputs({
                iban_account: '',
                beneficiary_first_name: '',
                beneficiary_last_name: '',
                beneficiary_email: '',
                beneficiary_city: '',
                beneficiary_address: '',
                company_name: '',
                bic: ''
            })
            setProfile_image(null)
            imageREf2.current.key = Date.now();
            setLoading(false)
        } else if (response?.status == 0) {
            toastr.error(response?.message || 'unable to add Successfully')
            setLoading(false)
        } else {
            console.error(response?.error || 'unable to add Successfully')
            setLoading(false)
        }
    }
    const validateInputs = () => {
        if (!selectedBeneficiary) {
            return toastr.error('Please selecte Beneficiary type to continue')
        }
        const errors = {};
        if (selectedBeneficiary == 'personal') {
            if (!inputs?.beneficiary_first_name) {
                errors['beneficiary_first_name'] = validationRules['beneficiary_first_name'];
            }

            if (!inputs?.beneficiary_last_name) {
                errors['beneficiary_last_name'] = validationRules['beneficiary_last_name'];
            }

        } else {
            if (!inputs?.company_name) {
                errors['company_name'] = validationRules['company_name'];
            }
        }
        if (!inputs?.iban_account) {
            errors['iban_account'] = validationRules['iban_account'];
        }
        // if (!inputs?.beneficiary_email) {
        //     errors['beneficiary_email'] = validationRules['beneficiary_email'];

        // }
        if (inputs.beneficiary_email) {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(inputs?.beneficiary_email)) {
              errors['beneficiary_email'] = 'Please enter a valid email address';
            }
          }

        // Object.keys(validationRules).forEach((field) => {
        //     if (!inputs[field]) {
        //         errors[field] = validationRules[field];
        //     }
        // });

        // if (!ibanCountryRef.current.value) {
        //     errors.iban_country = selectValidationRules.iban_country;
        // }
        // if (!addressCountryRef.current.value) {
        //     errors.address_country = selectValidationRules.address_country;
        // }
        if (!beneficiaryTypeRef.current.value) {
            errors.beneficiary_type = selectValidationRules.beneficiary_type;
        }
        // if (!profile_image) {
        //     errors.profile_image = selectValidationRules.profile_image;
        // }
      

        setErrors(errors);
        return Object.keys(errors).length === 0;
    };
    return (

        <div className="row">
            {!loadingScripts ? <div className="col-lg-12">
                <div className="card">
                    <div className="card-header">
                        <h4 className="card-title">Add Beneficiary</h4>
                    </div>
                    <div className="card-body">
                        <div >

                            <div className="row">
                                <div className="col-xl-6">
                                    <div className="mb-3 row">
                                        <label
                                            className="col-lg-4 col-form-label"
                                            htmlFor="validationCustom05"
                                        >
                                            Beneficiary Type
                                            <span className="text-danger">*</span>
                                        </label>
                                        <div className="col-lg-6">
                                            <select defaultValue={"personal"} onChange={() => { console.log('change') }} class="default-placeholder form-control" ref={beneficiaryTypeRef} placeholder="Select Country">
                                                <option disabled selected value={""}>Select Beneficiary Type</option>
                                                <option value={"personal"}>Personal</option>
                                                <option value={"Business"}>Business</option>

                                            </select>
                                            {errors.beneficiary_type && (
                                                <span className="text-danger">{errors.beneficiary_type}</span>
                                            )}

                                        </div>
                                    </div>
                                  
                                    <div className="mb-3 row">
                                        <label
                                            className="col-lg-4 col-form-label"
                                            htmlFor="validationCustom09"
                                        >
                                            IBAN Account <span className="text-danger">*</span>
                                        </label>
                                        <div className="col-lg-6">
                                            <input
                                                type="text"
                                                value={inputs?.iban_account || ''}
                                                onChange={(e) => setInputs((prevInputs) => ({ ...prevInputs, iban_account: e.target.value }))}
                                                className="form-control"
                                                placeholder={"Enter IBAN Account number"}
                                            />
                                            {errors.iban_account && (
                                                <span className="text-danger">{errors.iban_account}</span>
                                            )}
                                        </div>
                                    </div>
                                
                                    {selectedBeneficiary == 'Business' && <div class="mb-3 row">
                                        <label class="col-lg-4 col-form-label" >Company Name
                                            <span class="text-danger">*</span>
                                        </label>
                                        <div class="col-lg-6">
                                            <input
                                                value={inputs?.company_name || ""}
                                                onChange={(e) => setInputs((prevInputs) => ({ ...prevInputs, company_name: e.target.value }))}
                                                type="text" class="form-control" placeholder="Enter company name.." />
                                            {errors.company_name && (
                                                <span className="text-danger">{errors.company_name}</span>
                                            )}
                                        </div>
                                    </div>}
                                    {selectedBeneficiary == 'personal' && <div class="mb-3 row">
                                        <label class="col-lg-4 col-form-label" >Beneficiary First Name
                                            <span class="text-danger">*</span>
                                        </label>
                                        <div class="col-lg-6">
                                            <input
                                                value={inputs?.beneficiary_first_name || ''}
                                                onChange={(e) => setInputs((prevInputs) => ({ ...prevInputs, beneficiary_first_name: e.target.value }))}
                                                type="text" class="form-control" placeholder="Enter beneficiary name.." />
                                            {errors.beneficiary_first_name && (
                                                <span className="text-danger">{errors.beneficiary_first_name}</span>
                                            )}
                                        </div>
                                    </div>}
                                    {selectedBeneficiary == 'personal' && <div class="mb-3 row">
                                        <label class="col-lg-4 col-form-label" >Beneficiary Last Name
                                            <span class="text-danger">*</span>
                                        </label>
                                        <div class="col-lg-6">
                                            <input
                                                value={inputs?.beneficiary_last_name || ''}
                                                onChange={(e) => setInputs((prevInputs) => ({ ...prevInputs, beneficiary_last_name: e.target.value }))}
                                                type="text" class="form-control" placeholder="Enter beneficiary name.." />
                                            {errors.beneficiary_last_name && (
                                                <span className="text-danger">{errors.beneficiary_last_name}</span>
                                            )}
                                        </div>
                                    </div>}
                                    <div class="mb-3 row">
                                        <label class="col-lg-4 col-form-label" >Beneficiary Email
                                            
                                        </label>
                                        <div class="col-lg-6">
                                            <input
                                                value={inputs?.beneficiary_email || ''}
                                                onChange={(e) => setInputs((prevInputs) => ({ ...prevInputs, beneficiary_email: e.target.value }))}
                                                type="text" class="form-control" placeholder="Enter beneficiary email.." />
                                            {errors.beneficiary_email && (
                                                <span className="text-danger">{errors.beneficiary_email}</span>
                                            )}
                                        </div>
                                    </div>
                                 
                                </div>
                                <div className="col-xl-6">
                             

                                    <div className="mb-3 row">
                                        <label
                                            className="col-lg-4 col-form-label"
                                            htmlFor="validationCustom11"
                                        >
                                            Profile Image
                                            
                                        </label>
                                        <div className="col-lg-6">
                                            <input class="form-control"
                                                key={imageREf2.current?.key || 'defaultKey'}
                                                ref={imageREf2}
                                                onChange={(e) => setProfile_image(e.target.files[0])}
                                                // ref={profileImageRef}
                                                type="file" id="formFile" />

                                            {errors.profile_image && (
                                                <span className="text-danger">{errors.profile_image}</span>
                                            )}
                                        </div>
                                    </div>

                                    
                                </div>
                                <div className="mb-3 row">

                                </div>
                                <div className="d-flex justify-content-end float-right">
                                    
                                    <button disabled={loading} type="button" onClick={addBeneficiary} class="btn btn-default d-flex justify-content-center align-items-center gap-2">
                                        Add Beneficiary
                                        {loading && <div class="spinner-border" style={{ height: "16px", width: "16px" }} role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>}
                                    </button>
                                </div>
                            </div>


                        </div>

                    </div>

                </div>

            </div> :
                <div className="text-center my-5 ">
                    <div class="spinner-border" style={{ height: "50px", width: "50px" }} role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
            }



        </div>


    )
}
export default AddBeneficiary