import { useNavigate, useParams } from "react-router-dom";
import { whitelabel_id } from "../../constants/ids";
import { API_ENDPOINTS, API_METHODS } from "../../constants/endpoints";
import apiCall from "../../utils/apiCall";
import { useEffect, useState } from "react";
import toastr from 'toastr'

const AcceptPlan = () => {
    const [loading, setLoading] = useState(false)
    const { unique_id } = useParams();
    const navigate = useNavigate()
    useEffect(() => {
        if (unique_id) {
            acceptPlanUID()
        } else {
            navigate('/')
        }
    }, [])
    const acceptPlanUID = async () => {
        setLoading(true)
       
        const body = {
            whitelabel_id: whitelabel_id,
            unique_id: unique_id,
            type: 'accept'
        }
        const url = API_ENDPOINTS.ACCEPT_DECLINE_PLAN
        const method = API_METHODS.POST
        const response = await apiCall(url, method, body)

        if (response?.status == 1) {
            setLoading(false)
            toastr.success(response?.message || 'success')
            navigate('/')
        } else if (response?.status == 0) {
            setLoading(false)
            toastr.error(response?.message || 'success')
            navigate('/')
        }
    }
    return (
        <div class="container h-100" style={{ paddingTop: '100px' }}>
            <div className="my-5 text-center">
                <h2>Verifying your request</h2>
            </div>
            <div class="row justify-content-center h-100 align-items-center">
                {loading && <div className="text-center my-5 ">
                    <div class="spinner-border" style={{ height: "150px", width: "150px", paddingTop: "100px", marginTop: '200px' }} role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>}
            </div>
        </div>
    )
}
export default AcceptPlan;