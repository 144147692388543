import { Link } from "react-router-dom"
const Section2 = ({ lowPlans }) => {
    return (
        // <div className="row  cardsContainer mx-auto" style={{ marginTop: "5%", minWidth: '500px' }}>
        //    
            <div className="col-xl-4 col-lg-6 col-md-6">
                {lowPlans.length > 0 && lowPlans.map((plan) => {
                    return   <div >
                         <h1>Low Risk Plans</h1>
                    <div class="card card-animate-pricing p-3">
                    <div className="d-flex justify-content-between p-4">
                        <i class="fas fa-list rounded-circle bg-primary bounce-image p-2 d-flex align-items-center justify-content-center" style={{ minWidth: '50px', minHeight: '50px' }}></i>
                        <span style={{ fontSize: "14px", alignItems: "center", fontWeight: "400" }} className="d-flex text-uppercase">{plan?.plan_name}</span>
                    </div>
                    <div className="ms-4">
                        <div style={{ fontSize: '35px', color: "white" }} className="lh-1">
                            €{plan?.eur_plan_price}
                        </div>
                        <div style={{ fontSize: '10px' }} className="">
                            Opening account
                        </div>
                    </div>
                    <div className="mt-4 ms-4">
                        <ul className="unorder-list">
                            <li className="pt-3">€ {plan?.monthly_account} per month</li>
                            <li className="pt-3">SEPA SCT In € {plan?.sepa_in_fee} + {plan?.sepa_in_fee_percentage}%</li>
                            <li className="pt-3">SEPA SCT Out Fee € {plan?.sepa_out_fee} + {plan?.sepa_out_fee_percentage}%</li>
                            <li className="pt-3">SEPA Instant Out Fee € {plan?.sepa_instant_out_fee} + {plan?.sepa_instant_out_fee_percentage}%</li>
                            <li className="pt-3">SEPA Instant In  € {plan?.sepa_instant_in_fee} + {plan?.sepa_instant_in_fee_percentage}%</li>
                        </ul>
                    </div>
                    <div className="my-4 d-flex justify-content-center">
                       <Link to={"/login"} class="btn light btn-primary" style={{ width: "90%" }}>Find out more</Link>
                    </div>
                    </div>
                </div>
                })}
                {/* <div className="col-lg-4 col-md-6">
                    <div class="card  card-animate-pricing  p-3">
                        <div className="d-flex justify-content-between p-4">
                            <i class="fas fa-star rounded-circle bg-primary bounce-image p-2 d-flex align-items-center justify-content-center" style={{ width: '50px', height: '50px' }}></i>
                            <span style={{ fontSize: "18px", alignItems: "center", fontWeight: "400" }} className="d-flex text-uppercase">Gold<br /> low</span>
                        </div>
                        <div className="ms-4">
                            <div style={{ fontSize: '35px', color: "white" }} className="lh-1">
                                €10.00
                            </div>
                            <div style={{ fontSize: '10px' }} className="">
                                Opening account
                            </div>
                        </div>
                        <div className="mt-4 ms-4">
                            <ul className="unorder-list">
                                <li className="pt-3">€ 25.00 per month</li>
                                <li className="pt-3">SEPA SCT In Fee Free</li>
                                <li className="pt-3">SEPA SCT Out Fee € 0.50 </li>
                                <li className="pt-3">SEPA Instant Out Fee € 1.00</li>
                                <li className="pt-3">SEPA Instant In Fee Free</li>
                            </ul>
                        </div>
                        <div className="my-4 d-flex justify-content-center">
                            <button type="button" class="btn light btn-primary" style={{ width: "90%" }}>Find out more</button>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6">
                    <div class="card  card-animate-pricing  p-3">
                        <div className="d-flex justify-content-between p-4">
                            <i class="fas fa-badge-check rounded-circle bg-primary bounce-image p-2 d-flex align-items-center justify-content-center" style={{ width: '50px', height: '50px' }}></i>
                            <span style={{ fontSize: "18px", alignItems: "center", fontWeight: "400" }} className="d-flex text-uppercase">Silver<br /> low</span>
                        </div>
                        <div className="ms-4">
                            <div style={{ fontSize: '35px', color: "white" }} className="lh-1">
                            €100.00
                            </div>
                            <div style={{ fontSize: '10px' }} className="">
                                Opening account
                            </div>
                        </div>
                        <div className="mt-4 ms-4">
                            <ul className="unorder-list">
                                <li className="pt-3">€ 49.00 per month</li>
                                <li className="pt-3">SEPA SCT In Fee Free</li>
                                <li className="pt-3">SEPA SCT Out Fee € 0.25</li>
                                <li className="pt-3">SEPA Instant Out Fee € 0.40</li>
                                <li className="pt-3">SEPA Instant In Fee Free</li>
                            </ul>
                        </div>
                        <div className="my-4 d-flex justify-content-center">
                            <button type="button" class="btn light btn-primary" style={{ width: "90%" }}>Find out more</button>
                        </div>
                    </div>
                </div> */}
            </div>
        // </div>
    )
}
export default Section2