import { useEffect, useRef, useState } from "react";
import { whitelabel_id } from "../../constants/ids";
import { API_ENDPOINTS, API_METHODS } from "../../constants/endpoints";
import apiCall from "../../utils/apiCall";
import { Form, Select, Space, Radio } from "antd";
import toastr from "toastr";
import { Link, useNavigate } from "react-router-dom";

const optionsWithDisabled = [
    {
        label: 'Yes',
        value: 'yes',
    },
    {
        label: 'No',
        value: 'no',
    }
];
const CompanyProfile = () => {
    const authUser = JSON.parse(localStorage.getItem('auth_user'))
    const statuses = JSON.parse(localStorage.getItem('statuses'))
    const companyTypeRef = useRef();
    const registrationCountryRef = useRef();
    const countryRef = useRef();
    const actualCountryRef = useRef();
    const industryRef = useRef();
    const selectPhoneRef = useRef();
    const dateRef = useRef();
    const companySizeRef = useRef();
    const [company, setCompany] = useState({})
    const [loading, setLoading] = useState(false)
    const [loadingData, setLoadingData] = useState(false)
    const [countries, setCountries] = useState([])
    const [phoneCodes, setPhoneCodes] = useState([])
    const [industries, setIndustries] = useState([])
    const [companySizeList, setCompanySizeList] = useState([])
    const [fieldErrors, setFieldErrors] = useState({});
    const [userInfo, setUserInfo] = useState({})
    const [sameActualAddress, setSameActualAddress] = useState('no');
    const [companyTypes,setCompanyTypes]=useState([])
    const navigate = useNavigate()
    const onChange4 = ({ target: { value } }) => {

        setSameActualAddress(value);
    };


    const [validationRules, setValidationRules] = useState({
        // company_name: "Company Name is required",
        // company_email: "Email is required",
        phone: "Phone number is required",
        vat_number: "VAT number is required",
        website: "Company website is required",
        purpose_of_account: 'Purpose of Account is reqruied',
        registration_address1: 'Company registration address 1 is required',
        // registration_address2: 'Company registration address 2 is required',
        registration_city: 'Company registration city is required',
        registration_region: 'Company registration region is required',
        registration_zip: 'Registration Zip is required',
        registration_number: 'Registration Number is required',
        // no_employees: "Number of employees is required",
        actual_city: 'Actual City is required',
        actual_region: 'Actual Region is required',
        actual_zip: "Actual Zip is required",
        actual_address1: "Actual Address is required",
    });

    const [selectValidationRules, setSelectValidationRules] = useState({
        company_country: "Company country is required",
        actual_country: "Actual country is required",
        industry: "Industry is required",
        company_type: "Company type is required",
        company_size: 'company size is required',
        date_of_incorporation: 'Date of incorporation is required',
        phonecode: 'Phone code is required',
        registration_country: 'Registration Country is required'
    })


    const [inputs, setInputs] = useState({
        // company_name: "",
        // company_email: '',
        // date_of_incorporation: "",
        registration_number: "",
        vat_number: "",
        industry: "",
        website: "",
        // no_employees: "",
        purpose_of_account: "",
        registration_country: "",
        registration_address1: "",
        // registration_address2: "",
        registration_city: "",
        registration_region: "",
        registration_zip: "",
        // actual_country: "",
        actual_address1: "",
        actual_city: "",
        actual_region: "",
        actual_zip: "",
        company_country: "",
        company_type: "",
        phonecode: "",
        phone: '',
        web_token: '',
        user_id: ''

    })
    useEffect(() => {
        if (!authUser) {
            return navigate('/login')
        }
        if (authUser && !authUser?.web_token) {
            return navigate('/login')
        }
        if (authUser && authUser?.is_live == 1) {
            // if (statuses.company_status != "2" || statuses.director_status != "2" || statuses.document_status != "2") {
            //     return navigate('/account-confirmation')
            // }
            return navigate('/balance')
        }
        getCountryList()
    }, [])

    useEffect(() => {
        const scriptUrls = [
            "js/plugins-init/select2-init.js",
            "vendor/select2/js/select2.full.min.js",
            "vendor/bootstrap-material-datetimepicker/js/bootstrap-material-datetimepicker.js",
            "js/plugins-init/material-date-picker-init.js",
            "vendor/bootstrap-daterangepicker/daterangepicker.js",
            "vendor/moment/moment.min.js",
        ];
        scriptUrls.forEach((scriptUrl) => {
            const script = document.createElement("script");
            script.src = scriptUrl;
            script.async = true;
            document.body.appendChild(script);
        });

        return () => {
            scriptUrls.forEach((scriptUrl) => {
                const script = document.querySelector(`[src="${scriptUrl}"]`);
                if (script) {
                    script.remove();
                }

            });
        };


    }, [sameActualAddress]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const [countryListResponse, phoneCodeResponse, industriesResponse, companySizeListResponse] = await Promise.all([
                    getCountryList(),
                    getPhoneCode(),
                    getIndustries(),
                    getCompanySizeList()
                    
                ]);
                if (countryListResponse?.status === 1 && phoneCodeResponse?.status === 1 && industriesResponse?.status === 1, companySizeListResponse.status === 1) {
                    const sortedCountries = countryListResponse.country.sort((a, b) => a.name.localeCompare(b.name));
                    setCountries(sortedCountries)
                    setPhoneCodes(phoneCodeResponse.countries)
                    setIndustries(extractIndustries(industriesResponse.industries))
                    setCompanySizeList(JSON.parse(companySizeListResponse.dropdown))
                } else {
                    console.error("One or more API calls failed");
                }
            } catch (error) {
                console.error("Error during API calls", error);
            }
        };

        fetchData();
        getCompanyDetails()
    }, []);
    useEffect(()=>{
        getCompanyTypes()
    },[])
    const getCompanyDetails = async () => {
        setLoadingData(true)
        const authUser = JSON.parse(localStorage.getItem("auth_user"))
        const { web_token, user_id } = authUser
        const url = API_ENDPOINTS.GET_COMPANY_STATUS
        const body = {
            whitelabel_id: whitelabel_id,
            web_token: web_token,
            user_id: user_id,
        }
        const response = await apiCall(url, API_METHODS.POST, body)
        if (response?.status == 1) {
            setCompany(response?.company)
            setUserInfo(response?.user)
            setInputs({
                registration_address1: response?.company?.registration_address1,
                actual_address1: response?.company?.actual_address1,
                registration_address2: response?.company?.registration_address2,
                registration_number: response?.company?.registration_number,
                phone: response?.user.phone_number?.split("-")[1],
                vat_number: response?.company?.vat_number,
                website: response?.company?.website,
                no_employees: response?.company?.no_employees,
                purpose_of_account: response?.company?.about_company,
                registration_city: response?.company?.registration_city,
                registration_region: response?.company?.registration_region,
                registration_zip: response?.company?.registration_zip,
                registration_country: response?.company?.registration_country,
                actual_city: response?.company?.actual_city,
                actual_region: response?.company?.actual_region,
                actual_zip: response?.company?.actual_zip,
            })
            setLoadingData(false)
        } else if (response?.status == 0) {
            toastr.error(response?.message)
            setLoadingData(false)

        } else {
            toastr.error('Unable to Get Details')
        }
    }

    const getCountryList = async () => {
        const body = {
            whitelabel_id: whitelabel_id
        }
        const url = API_ENDPOINTS.GET_COUNTRY_LIST
        const method = API_METHODS.POST
        return await apiCall(url, method, body)
        // if (response?.status == 1) {

        // } 
    }
    const getPhoneCode = async () => {
        const body = {
            whitelabel_id: whitelabel_id
        }
        const url = API_ENDPOINTS.GET_PHONE_CODE
        const method = API_METHODS.POST
        return await apiCall(url, method, body)
        // if (response?.status == 1) {

        // } 
    }
    const getIndustries = async () => {
        const body = {
            whitelabel_id: whitelabel_id
        }
        const url = API_ENDPOINTS.GET_INDUSTRIES
        const method = API_METHODS.POST
        return await apiCall(url, method, body)
    }
    const getCompanyTypes = async () => {
        const body = {
            whitelabel_id: whitelabel_id
        }
        const url = API_ENDPOINTS.GET_COMPANY_TYPE_LIST
        const method = API_METHODS.POST
        const response = await apiCall(url, method, body)
        if(response?.status == 1){
            setCompanyTypes(JSON.parse(response?.dropdown))
        }else{
            console.error('unable to fetch types')
        }
    }

    const getCompanySizeList = async () => {
        const body = {
            whitelabel_id: whitelabel_id
        }
        const url = API_ENDPOINTS.GET_COMPANY_SIZE_LIST
        const method = API_METHODS.POST
        return await apiCall(url, method, body)
    }

    const validateInputs = () => {
        const errors = {};

        Object.keys(validationRules).forEach((field) => {

            if (field === 'actual_city' && sameActualAddress === 'yes') {
                return;
            }
            if (field === 'actual_region' && sameActualAddress === 'yes') {
                return
            }
            if (field == "actual_zip" && sameActualAddress === 'yes') {
                return
            }
            if (field == "actual_address1" && sameActualAddress === 'yes') {
                return
            }
            if (!inputs[field]) {
                errors[field] = validationRules[field];
            }
        });

        const websiteRegex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i;
        if (inputs.website && !websiteRegex.test(inputs.website)) {
            errors.website = 'Invalid website format';
        }
        const phoneRegex = /^\d{10}$/;
        if (inputs.phone && !phoneRegex.test(inputs.phone)) {
            errors.phone = 'Invalid phone number format. It should be 10 digits.';
        }
        if (!countryRef.current.value) {
            errors.company_country = selectValidationRules.company_country;
        }
        if (sameActualAddress === 'no') {
            if (!actualCountryRef.current.value) {
                errors.actual_country = selectValidationRules.actual_country;
            }
        }

        if (!selectPhoneRef.current.value) {
            errors.phonecode = selectValidationRules.phonecode;
        }
        if (!companyTypeRef.current.value) {
            errors.company_type = selectValidationRules.company_type;
        }

        // if (!companyTypeRef.current.value) {
        //     errors.company_type = selectValidationRules.company_type;
        // }
        if (!industryRef.current.value) {
            errors.industry = selectValidationRules.industry;
        }
        if (!registrationCountryRef.current.value) {
            errors.registration_country = selectValidationRules.registration_country;
        }
        if (!dateRef.current.value) {
            errors.date_of_incorporation = selectValidationRules.date_of_incorporation;
        }
        if (!companySizeRef.current.value) {
            errors.company_size = selectValidationRules.company_size;
        }
       
        setFieldErrors(errors);
        return Object.keys(errors).length === 0;
    };
    const onFinish = async () => {
     
        setFieldErrors({});
        if (!validateInputs()) {
            return;
        }
        setLoading(true)
        const authUser = JSON.parse(localStorage.getItem("auth_user"))
        const { web_token, user_id } = authUser

        const body = {
            whitelabel_id: whitelabel_id,
            user_id: user_id,
            web_token: web_token,
            date_of_incorporation: dateRef.current.value,
            vat_number: inputs.vat_number,
            industry: industryRef.current.value,
            website: inputs.website,
            no_employees: companySizeRef?.current.value,
            purpose_of_account: inputs.purpose_of_account,
            registration_number: inputs.registration_number,
            registration_country: registrationCountryRef.current.value,
            registration_address1: inputs.registration_address1,
            registration_city: inputs.registration_city,
            registration_region: inputs.registration_region,
            registration_zip: inputs.registration_zip,
            actual_country: sameActualAddress == 'yes' ? registrationCountryRef.current.value : actualCountryRef.current.value,
            actual_address1: sameActualAddress == 'yes' ? inputs.registration_address1 : inputs.actual_address1,
            actual_city: sameActualAddress == 'yes' ? inputs.registration_city : inputs.actual_city,
            actual_region: sameActualAddress == 'yes' ? inputs.registration_region : inputs.actual_region,
            actual_zip: sameActualAddress == 'yes' ? inputs.registration_zip : inputs.actual_zip,
            company_country: countryRef.current.value,
            company_type: companyTypeRef.current.value,
            phonecode: selectPhoneRef.current.value,
            phone: inputs.phone
        }
        const response = await apiCall(API_ENDPOINTS.UPDATE_COMPANY_DETAILS, API_METHODS.POST, body)
        if (response.status == 1) {
            getCompanyDetails()
            setLoading(false)
            toastr.success(response?.message || 'Company Profile updated successfully.')
            navigate('/account-confirmation')

        } else if (response.status == 0) {
            toastr.error(response?.message)
            setLoading(false)

        } else {
            toastr.error('Unable to update.')
        }
    }
    return (
        <>
            <div class="row page-titles">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item active"><Link to={"/account-confirmation"}>Home</Link></li>
                    <li class="breadcrumb-item"><a href="javascript:void(0)">Company Profile</a></li>
                </ol>
            </div>
            <div>
                <h2>Complete Company Profile</h2>
            </div>

            <div class="d-flex align-items-center mb-4 flex-wrap">
                <div class="card-tabs mt-3 mt-sm-0 me-auto">
                    <ul class="nav nav-tabs trans-tab" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link underline active" data-bs-toggle="tab" href="#corporate-documents" role="tab">Company</a>
                        </li>
                        {/* <li class="nav-item">
                            <a class="nav-link underline" data-bs-toggle="tab" href="#address-details" role="tab">Address Details</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link underline" data-bs-toggle="tab" href="#pending" role="tab">Director Details</a>
                        </li> */}

                    </ul>
                </div>




            </div>


            <div className="row">

                <div className="col-12 col-lg-12 col-md-12">

                    <div className="card">
                        <div className="card-header">
                            <h4>Company Information</h4>
                        </div>
                        <div className="card-body">
                            <div className="text-start">
                                <h5>Company Deatils</h5>
                            </div>

                            <div class="basic-form">

                                <div class="row">

                                    <div class="mb-3">
                                        <label class="form-label">Company Name</label>
                                        <input
                                            disabled
                                            value={company?.company_name}
                                            onChange={(e) => setInputs((prevInputs) => ({ ...prevInputs, company_name: e.target.value }))}
                                            type="text" name="name" class="form-control" placeholder="Enter company name" />
                                        {fieldErrors.company_name && (
                                            <span className="text-danger">{fieldErrors.company_name}</span>
                                        )}
                                    </div>

                                    {/* <div class="mb-3 col-md-6">
                                        <label class="form-label">Email</label>
                                        <input onChange={(e) => setInputs((prevInputs) => ({ ...prevInputs, company_email: e.target.value }))} type="email" class="form-control" placeholder="Enter company email" required />
                                        {fieldErrors.company_email && (
                                            <span className="text-danger">{fieldErrors.company_email}</span>
                                        )}
                                    </div> */}
                                    <div class=" row m-0 p-0">
                                        <label class="form-label">Company Contact Number</label>
                                        <div class=" mb-3 col-md-3">

                                            <select
                                                // defaultValue={userInfo?.phone_number?.split("-")[0] || ""}
                                                class="default-placeholder " ref={selectPhoneRef} placeholder="Select phone code">
                                                <option disabled selected value={""}>Select Phone Code</option>
                                                {phoneCodes?.length > 0 && phoneCodes.map((p) => {
                                                    return <option selected={userInfo?.phone_number?.split("-")[0] == p.phonecode} value={p.phonecode}>{p.phonecode}</option>
                                                })}
                                            </select>
                                            {fieldErrors.phonecode && (
                                                <span className="text-danger">{fieldErrors.phonecode}</span>
                                            )}
                                        </div>
                                        <div class=" mb-3  col-md-9">
                                            <input defaultValue={userInfo?.phone_number?.split("-")[1] || ""} onChange={(e) => setInputs((prevInputs) => ({ ...prevInputs, phone: e.target.value }))} type="number" class="form-control" placeholder="3213123123" />
                                            {fieldErrors.phone && (
                                                <span className="text-danger">{fieldErrors.phone}</span>
                                            )}
                                        </div>
                                    </div>

                                    <div class="mb-3 col-md-6 form-material">
                                        <label class="form-label">Date of incorporation</label>
                                        {/* <input type="text" class="form-control" placeholder="Enter Date of incorporation" /> */}
                                        <input defaultValue={(company?.date_of_incorporation !== "0000-00-00") ? company?.date_of_incorporation : ''} type="text" ref={dateRef} class="form-control" placeholder="2017-06-04" id="mdate" onChange={(e) => { console.log(e) }} />
                                        {fieldErrors.date_of_incorporation && (
                                            <span className="text-danger">{fieldErrors.date_of_incorporation}</span>
                                        )}
                                        {/* <input onChange={(e) => setInputs((prevInputs) => ({ ...prevInputs, date_of_incorporation: e.target.value }))} type="text" class="form-control" placeholder="2017-06-04" id="mdate" /> */}
                                    </div>
                                    <div class="mb-3 col-md-6">
                                        <label class="form-label">Registration Number</label>
                                        <input defaultValue={company?.registration_number} type="email" onChange={(e) => setInputs((prevInputs) => ({ ...prevInputs, registration_number: e.target.value }))} class="form-control" placeholder="Enter registration number" />
                                        {fieldErrors.registration_number && (
                                            <span className="text-danger">{fieldErrors.registration_number}</span>
                                        )}
                                    </div>
                                    <div class="mb-3 col-md-6">
                                        <label class="form-label">VAT Number</label>
                                        <input defaultValue={company?.vat_number} type="text" onChange={(e) => setInputs((prevInputs) => ({ ...prevInputs, vat_number: e.target.value }))} class="form-control" placeholder="Enter VAT number" />
                                        {fieldErrors.vat_number && (
                                            <span className="text-danger">{fieldErrors.vat_number}</span>
                                        )}
                                    </div>
                                    <div class="mb-3 col-md-6">
                                        <label class="form-label">Company Website</label>
                                        <input defaultValue={company?.website} type="email" onChange={(e) => setInputs((prevInputs) => ({ ...prevInputs, website: e.target.value }))} class="form-control" placeholder="www.xyz.com" />
                                        {fieldErrors.website && (
                                            <span className="text-danger">{fieldErrors.website}</span>
                                        )}
                                    </div>
                                    {/* <div class="mb-3 col-md-6">
                                        <label class="form-label">Number of employees</label>
                                        <input defaultValue={company?.no_employees} type="number" onChange={(e) => setInputs((prevInputs) => ({ ...prevInputs, no_employees: e.target.value }))} class="form-control" placeholder="Enter number of employees" />
                                        {fieldErrors.no_employees && (
                                            <span className="text-danger">{fieldErrors.no_employees}</span>
                                        )}
                                    </div> */}

                                    <div class="mb-3 ">
                                        <label class="form-label">Company Country</label>
                                        <select
                                            // value={company?.company_country}
                                            class="default-placeholder form-control" ref={countryRef} placeholder="Select Country">
                                            <option disabled selected value={""}>Select Country</option>
                                            {countries?.length > 0 && countries.map((c) => {
                                                return <option selected={company?.company_country == c.id} value={c.id}>{c.name}</option>
                                            })}
                                        </select>
                                        {fieldErrors.company_country && (
                                            <span className="text-danger">{fieldErrors.company_country}</span>
                                        )}
                                    </div>

                                    {/* <div class="mb-3 ">
                                        <label class="form-label">Company Type</label>
                                        <select value={company?.company_type} ref={companyTypeRef} class="default-placeholder form-control">
                                            <option selected disabled value={""}>Select company Type</option>
                                            <option value={1}>1</option>
                                            <option value={2}>2</option>
                                            <option value={3}>3</option>
                                        </select>
                                        {fieldErrors.company_type && (
                                            <span className="text-danger">{fieldErrors.company_type}</span>
                                        )}
                                    </div> */}
                                    <div class="mb-3 col-md-6">
                                        <label class="form-label">Industry</label>
                                        <select
                                            // defaultValue={company?.industry}
                                            ref={industryRef} class="default-placeholder form-control" >
                                            <option selected disabled value={""}>Select Industry</option>
                                            {industries?.length > 0 && industries.map((i) => {
                                                return <option selected={company?.industry == i.value} value={i.value}>{i.value}</option>
                                            })}
                                        </select>
                                        {fieldErrors.industry && (
                                            <span className="text-danger">{fieldErrors.industry}</span>
                                        )}
                                    </div>
                                    <div class="mb-3 col-md-6">
                                        <label class="form-label">Company Type</label>
                                        <select
                                            // defaultValue={company?.industry}
                                            ref={companyTypeRef} class="default-placeholder form-control" >
                                            <option selected disabled value={""}>Select Company Type</option>
                                            {companyTypes?.length > 0 && companyTypes.map((type) => {
                                                return <option selected={company?.company_type == type.id} value={type.id}>{type.name}</option>
                                            })}
                                        </select>
                                        {fieldErrors.company_type && (
                                            <span className="text-danger">{fieldErrors.company_type}</span>
                                        )}
                                    </div>
                                    <div class="mb-3 ">
                                        <label class="form-label">Company Size</label>
                                        <select defaultValue={company?.no_employees} ref={companySizeRef} class="default-placeholder form-control" >
                                            <option selected disabled value={""}>Select Company Size</option>
                                            {companySizeList?.length > 0 && companySizeList.map((c) => {
                                                return <option selected={c.id == company?.no_employees} value={c.id}>{c.name}</option>
                                            })}
                                        </select>
                                        {fieldErrors.company_size && (
                                            <span className="text-danger">{fieldErrors.company_size}</span>
                                        )}
                                    </div>
                                    <div class="mb-3 col-12">
                                        {/* <label class="form-label ">Residence Address</label> */}
                                        <label class="form-label ">Purpose of Account</label>
                                        <p className="m-0"><small className="m-0">Provide information about purpose of IBAN account</small></p>
                                        <textarea defaultValue={company?.about_company} class="form-control" onChange={(e) => setInputs((prevInputs) => ({ ...prevInputs, purpose_of_account: e.target.value }))} id="validationCustom04" rows="2" placeholder="Enter the purpose of IBAN account" ></textarea>
                                        {fieldErrors.purpose_of_account && (
                                            <span className="text-danger">{fieldErrors.purpose_of_account}</span>
                                        )}
                                    </div>

                                    <h5>Company Registration Address</h5>
                                    <div class="mb-3 col-12">
                                        <label class="form-label ">Company Registration Address</label>
                                        <textarea defaultValue={company?.registration_address1} class="form-control" onChange={(e) => setInputs((prevInputs) => ({ ...prevInputs, registration_address1: e.target.value }))} id="validationCustom04" rows="2" placeholder="Enter Company Registration address ?" ></textarea>
                                        {fieldErrors.registration_address1 && (
                                            <span className="text-danger">{fieldErrors.registration_address1}</span>
                                        )}
                                    </div>
                                    {/* <div class="mb-3 col-12">
                                        <label class="form-label ">Company Registration Address 2</label>
                                        <textarea defaultValue={company?.registration_address2} class="form-control" onChange={(e) => setInputs((prevInputs) => ({ ...prevInputs, registration_address2: e.target.value }))} id="validationCustom04" rows="2" placeholder="Enter Company Registration address 2?" ></textarea>
                                        {fieldErrors.registration_address2 && (
                                            <span className="text-danger">{fieldErrors.registration_address2}</span>
                                        )}
                                    </div> */}
                                    <div class="mb-3 col-md-6">
                                        <label class="form-label">Registration Country</label>
                                        <select class="default-placeholder form-control" ref={registrationCountryRef} placeholder="Select Country">
                                            <option disabled selected value={""}>Select Country</option>
                                            {countries?.length > 0 && countries.map((c) => {
                                                return <option selected={company?.registration_country == c.id} value={c.id}>{c.name}</option>
                                            })}
                                        </select>
                                        {fieldErrors.registration_country && (
                                            <span className="text-danger">{fieldErrors.registration_country}</span>
                                        )}
                                    </div>
                                    <div class="mb-3 col-md-6">
                                        <label class="form-label">Company Registration City</label>
                                        <input defaultValue={company?.registration_city} type="text" onChange={(e) => setInputs((prevInputs) => ({ ...prevInputs, registration_city: e.target.value }))} class="form-control" placeholder="City" />
                                        {fieldErrors.registration_city && (
                                            <span className="text-danger">{fieldErrors.registration_city}</span>
                                        )}
                                    </div>
                                    <div class="mb-3 col-md-6">
                                        <label class="form-label">Company Registration Region</label>
                                        <input defaultValue={company?.registration_region} type="text" onChange={(e) => setInputs((prevInputs) => ({ ...prevInputs, registration_region: e.target.value }))} class="form-control" placeholder="Region" />
                                        {fieldErrors.registration_region && (
                                            <span className="text-danger">{fieldErrors.registration_region}</span>
                                        )}
                                    </div>
                                    <div class="mb-3 col-md-6">
                                        <label class="form-label">Company Registration Postal Code</label>
                                        <input defaultValue={company?.registration_zip} type="text" onChange={(e) => setInputs((prevInputs) => ({ ...prevInputs, registration_zip: e.target.value }))} class="form-control" placeholder="e.g 12344" />
                                        {fieldErrors.registration_zip && (
                                            <span className="text-danger">{fieldErrors.registration_zip}</span>
                                        )}
                                    </div>
                                    {/* <div class="mb-3 col-md-6">
                                        <label class="form-label">Company Registration Number</label>
                                        <input type="text" onChange={(e) => setInputs((prevInputs) => ({ ...prevInputs, registration_number: e.target.value }))} class="form-control" placeholder="e.g 12344" />
                                    </div> */}
                                    <div class="mb-3 col-md-12">
                                        <label class="form-label">Actual address same as Company Registration address</label>

                                        {/* <div class="col-sm-9">
                                            <div class="form-check">
                                                <input class="form-check-input"  type="radio" name="gridRadios" value="option1" onClick={()=>{setSameActualAddress(true)}} />
                                                <label class="form-check-label">
                                                    Yes
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" checked type="radio" name="gridRadios" value="option2" onClick={()=>{setSameActualAddress(false)}}  />
                                                <label class="form-check-label">
                                                    No
                                                </label>
                                            </div>

                                        </div> */}
                                        <div>
                                            <Radio.Group
                                                options={optionsWithDisabled}
                                                onChange={onChange4}
                                                value={sameActualAddress}
                                                optionType="button"
                                                buttonStyle="solid"
                                            />
                                        </div>
                                    </div>

                                    {sameActualAddress == 'no' && <h5>Actual Address</h5>}
                                    {sameActualAddress == 'no' && <div className="row">
                                        <div class="mb-3 col-md-6">
                                            <label class="form-label">Actual Country</label>
                                            <select class="default-placeholder form-control" ref={actualCountryRef} placeholder="Select Country">
                                                <option disabled selected value={""}>Select Country</option>
                                                {countries?.length > 0 && countries.map((c) => {
                                                    return <option selected={company?.actual_country == c.id} value={c.id}>{c.name}</option>
                                                })}
                                            </select>
                                            {fieldErrors.actual_country && (
                                                <span className="text-danger">{fieldErrors.actual_country}</span>
                                            )}
                                        </div>
                                        <div class="mb-3 col-md-6">
                                            <label class="form-label">Actual City</label>
                                            <input defaultValue={company?.actual_city} type="text" onChange={(e) => setInputs((prevInputs) => ({ ...prevInputs, actual_city: e.target.value }))} class="form-control" placeholder="City" />
                                            {fieldErrors.actual_city && (
                                                <span className="text-danger">{fieldErrors.actual_city}</span>
                                            )}
                                        </div>
                                        <div class="mb-3 col-md-6">
                                            <label class="form-label">Actual Region</label>
                                            <input defaultValue={company?.actual_region} type="text" onChange={(e) => setInputs((prevInputs) => ({ ...prevInputs, actual_region: e.target.value }))} class="form-control" placeholder="Region" />
                                            {fieldErrors.actual_region && (
                                                <span className="text-danger">{fieldErrors.actual_region}</span>
                                            )}
                                        </div>
                                        <div class="mb-3 col-md-6">
                                            <label class="form-label">Actual Zip</label>
                                            <input defaultValue={company?.actual_zip} type="text" onChange={(e) => setInputs((prevInputs) => ({ ...prevInputs, actual_zip: e.target.value }))} class="form-control" placeholder="Zip" />
                                            {fieldErrors.actual_zip && (
                                                <span className="text-danger">{fieldErrors.actual_zip}</span>
                                            )}
                                        </div>
                                        <div class="mb-3 col-12">
                                            <label class="form-label ">Actual Address</label>
                                            <textarea defaultValue={company?.actual_address1} class="form-control" onChange={(e) => setInputs((prevInputs) => ({ ...prevInputs, actual_address1: e.target.value }))} id="validationCustom04" rows="2" placeholder="Enter Actual address ?" ></textarea>
                                            {fieldErrors.actual_address1 && (
                                                <span className="text-danger">{fieldErrors.actual_address1}</span>
                                            )}
                                        </div>
                                    </div>}

                                </div>

                                <button disabled={loading} type="button" onClick={onFinish} class="btn btn-default d-flex justify-content-center align-items-center gap-2">
                                    Save
                                    {loading && <div class="spinner-border" style={{ height: "16px", width: "16px" }} role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>}
                                </button>



                            </div>
                        </div>

                    </div>
                </div>
            </div>


        </>
    )
}
function extractIndustries(industries) {
    const industriesObject = industries;
    const industriesArray = [];

    for (const industry in industriesObject) {
        industriesArray.push({ value: industriesObject[industry] });
    }

    return industriesArray;
}


export default CompanyProfile;