import { useEffect } from "react"
import { useNavigate } from "react-router-dom"

const DirectorDetails = ({ director }) => {
    const navigate = useNavigate()
    const statuses = JSON.parse(localStorage.getItem('statuses'))
    const authUser = JSON.parse(localStorage.getItem('auth_user'))

    useEffect(() => {
        if (!authUser) {
            navigate('/login')
        }
        if (authUser && authUser?.is_live !== 1) {
            if (statuses.company_status != "2" || statuses.director_status != "2" || statuses.document_status != "2") {
                navigate('/account-confirmation')
            }
        }
    })
    return (
        <>
            <div className="col-lg-8 col-md-10 col-sm-12">
                <div className="card">
                    <div className="card-header border-0">

                        <h3>Director Details</h3>
                    </div>
                    <div className="card-body">
                    <div className="d-flex justify-content-between">
                            <span >First Name </span>
                            <span >{director?.first_name}</span>
                        </div>
                        <hr /><div className="d-flex justify-content-between">
                            <span >Last Name</span>
                            <span >{director?.last_name}</span>
                        </div>
                        <hr />
                        <div className="d-flex justify-content-between">
                            <span >Address</span>
                            <span >{director?.address}</span>
                        </div>
                        <hr />
                        <div className="d-flex justify-content-between">
                            <span >City</span>
                            <span >{director?.city}</span>
                        </div>
                        <hr />
                        <div className="d-flex justify-content-between">
                            <span >Zip code</span>
                            <span >{director?.zipcode}</span>
                        </div>
                        <hr />
                        <div className="d-flex justify-content-between">
                            <span >Gender</span>
                            <span >{director?.gender == 'm' ? 'Male':'Female'}</span>
                        </div>
                        <hr />
                        <div className="d-flex justify-content-between">
                            <span >Role</span>
                            <span >{director?.role}</span>
                        </div>
                        <hr />
                        <div className="d-flex justify-content-between">
                            <span >Date of Birth</span>
                            <span >{director?.dob}</span>
                        </div>
                        <hr />

                    </div>
                </div>
            </div>
        </>
    )
}
export default DirectorDetails