import { useEffect, useState } from "react";
import { API_ENDPOINTS, API_METHODS } from "../../constants/endpoints";
import apiCall from "../../utils/apiCall";
import { whitelabel_id } from "../../constants/ids";
import toastr from "toastr";
import BigCircleWithCross from "../icons/statusCircle";
import BigCircleWithTick from "../icons/statusOkcircle";
import { Link, useNavigate } from "react-router-dom";

const CorporateDocuments = () => {
    const navigate =useNavigate()
    const [loading, setLoading] = useState(false)
    const [loadingData, setLoadingData] = useState(false)
    const [companyDocumentsStatus, setCompanyDocumentsStatus] = useState({})
    const [businessRegistrationFile, setBusinessRegistrationFile] = useState(null);
    const [ownershipStructureFile, setOwnershipStructureFile] = useState(null);
    const [financialDocumentsFile, setFinancialDocumentsFile] = useState(null);
    const [errors, setErrors] = useState({})
    const handleFileChange = (file, setFile) => {
        // Logic to handle file change
        setFile(file);
    };


    useEffect(() => {
        getCompanyDocumentStatus()
    }, [])

    const getCompanyDocumentStatus = async () => {
        setLoadingData(true)
        const authUser = JSON.parse(localStorage.getItem("auth_user"))
        const { web_token, user_id } = authUser
        const url = API_ENDPOINTS.GET_COMPANY_DOCUMENTS_STATUS
        const body = {
            whitelabel_id: whitelabel_id,
            web_token: web_token,
            user_id: user_id,
        }
        const response = await apiCall(url, API_METHODS.POST, body)
        if (response?.status == 1) {
            setCompanyDocumentsStatus(response?.company_document)
            setLoadingData(false)
        } else if (response?.status == 0) {
            console.error(response?.message)
            setLoadingData(false)

        } else {
            console.error('Unable to Fetch.')
        }
    }

    const uploadDocs = async () => {
        setErrors({})
        if (!validateDocs()) {
            return;
        }
        setLoading(true)
        const authUser = JSON.parse(localStorage.getItem("auth_user"))
        const { web_token, user_id } = authUser
        const url = API_ENDPOINTS.UPLOAD_COMPANY_DOCUMENTS
        const formData = new FormData();
        formData.append('whitelabel_id', whitelabel_id)
        formData.append('web_token', web_token)
        formData.append('user_id', user_id)
        if (businessRegistrationFile) {
            formData.append('business_reg_document', businessRegistrationFile);
        }
        if (ownershipStructureFile) {
            formData.append('ownership_structure_document', ownershipStructureFile);
        }
        if (financialDocumentsFile) {
            formData.append('financial_document', financialDocumentsFile);
        }

        const response = await apiCall(url, API_METHODS.POST, formData, true)
        if (response?.status == 1) {
            setLoading(false)
            getCompanyDocumentStatus()
            toastr.success(response?.message || 'Documents uploaded successfully.')
            navigate('/account-confirmation')

        } else if (response?.status == 0) {
            toastr.error(response?.message)
            setLoading(false)

        } else {
            toastr.error('Unable to upload documents.')
        }
        
        setLoading(false)
    }
    const validateDocs = () => {
        const newErrors = {};
        if (companyDocumentsStatus.length <= 0 || companyDocumentsStatus?.business_registration?.status == "2") {
           
            if (!businessRegistrationFile) {
                newErrors.businessRegistrationFile = "Business Registration Documents are required";
            }
        }
        if (companyDocumentsStatus.length <= 0 || companyDocumentsStatus?.Ownership?.status == "2") {
            if (!ownershipStructureFile) {
                newErrors.ownershipStructureFile = "Ownership Structure Documents are required";
            }
        }
        if (companyDocumentsStatus.length <= 0 || companyDocumentsStatus?.financial?.status == "2") {
            if (!financialDocumentsFile) {
                newErrors.financialDocumentsFile = "Financial Documents are required";
            }
        }
       
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return false;
        }
        return true;
    };
    return (
        <>
            <div class="row page-titles">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item active"><Link to={"/account-confirmation"}>Home</Link></li>
                    <li class="breadcrumb-item"><a href="javascript:void(0)">Corporate Documents</a></li>
                </ol>
            </div>
            <div>
                <h2>Corporate Documents </h2>
            </div>

            <div class="d-flex align-items-center mb-4 flex-wrap">
                <div class="card-tabs mt-3 mt-sm-0 me-auto">
                    <ul class="nav nav-tabs trans-tab" role="tablist">
                        {/* <li class="nav-item">
                            <a class="nav-link underline active" data-bs-toggle="tab" href="#corporate-documents" role="tab">KYC DOCUMENTS</a>
                        </li> */}
                        {/* <li class="nav-item">
                            <a class="nav-link underline" data-bs-toggle="tab" href="#address-details" role="tab">Address Details</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link underline" data-bs-toggle="tab" href="#pending" role="tab">Director Details</a>
                        </li> */}

                    </ul>
                </div>




            </div>


            {!loadingData ? <div className="row">

                <div className="col-12 col-lg-12 col-md-12">

                    <div className="card">
                        <div className="card-header">
                            <h4>Corporate documents</h4>
                        </div>
                        <div class="card-body text-center">
                            <h5>Upload Corporate Documents</h5>
                            <div className="row ">
                                <div className=" card col-11 col-md-7 col-lg-8 col-sm-11 col-xs-11 text-start mx-auto mx-2">
                                    <div className="card-body">
                                        <h4 className="text-center">Business Registration Documents</h4>
                                        {companyDocumentsStatus?.business_registration?.status == "2" && <div className="d-flex justify-content-center"> <BigCircleWithCross /></div>}
                                        {companyDocumentsStatus?.business_registration?.status == "1" && <div className="d-flex justify-content-center"> <BigCircleWithTick /></div>}
                                        {(!companyDocumentsStatus) ?
                                            <div>
                                                <span>Extract (certificate) of business license, proof of registered office and tax registration documents</span>
                                            </div>
                                            : companyDocumentsStatus?.business_registration?.status == "0" ?
                                                <span>Business registration documents are under verification</span> :
                                                companyDocumentsStatus?.business_registration?.status == "1" ?
                                                    <div className="text-success text-center">
                                                        Business Registration Documents are Verified
                                                    </div> :
                                                    companyDocumentsStatus?.business_registration?.status == "2" ?
                                                        <div>
                                                            <div className="text-danger text-center">
                                                                Business Registration Documents are rejected. please submit again
                                                            </div>
                                                            <span>Extract (certificate) of business license, proof of registered office and tax registration documents</span>
                                                        </div> :
                                                        <div>

                                                            <span>Extract (certificate) of business license, proof of registered office and tax registration documents</span>
                                                        </div>
                                        }


                                        {(companyDocumentsStatus?.business_registration?.status == "2" || !companyDocumentsStatus?.business_registration) &&
                                            <div className="mt-4">
                                                <input
                                                    class="form-control"
                                                    type="file"
                                                    id="ownershipStructureFile"
                                                    onChange={(e) => handleFileChange(e.target.files[0], setBusinessRegistrationFile)}
                                                />

                                                <div className="text-danger">
                                                    only jpg, png and pdf files are allowed.
                                                </div>
                                                {errors.businessRegistrationFile && (
                                                    <div className="text-danger">{errors.businessRegistrationFile}</div>
                                                )}
                                            </div>}
                                    </div>
                                </div>
                            </div>
                            <div className="row text-center">
                                <div className="card col-11 col-md-7 col-lg-8 col-sm-11 col-xs-11 text-start mx-auto mx-2">
                                    <div className="card-body">
                                        <h4 className="text-center">Ownership structure Documents</h4>
                                        {companyDocumentsStatus?.Ownership?.status == "2" && <div className="d-flex justify-content-center"> <BigCircleWithCross /></div>}
                                        {companyDocumentsStatus?.Ownership?.status == "1" && <div className="d-flex justify-content-center"> <BigCircleWithTick /></div>}
                                        {!companyDocumentsStatus ?
                                            <span>Extract (certificate) of business license, proof of registered office and tax registration documents</span>
                                            : companyDocumentsStatus?.Ownership?.status == "0" ?
                                                <span>Ownership Structure Documents are under verification</span> :
                                                companyDocumentsStatus?.Ownership?.status == "1" ?
                                                    <div className="text-success text-center">
                                                        Ownership Structure Documents are Verified
                                                    </div> :
                                                    companyDocumentsStatus?.Ownership?.status == "2" ?
                                                        <div>
                                                            <div className="text-danger text-center">
                                                                Ownership Structure Documents are rejected. please submit again
                                                            </div>
                                                            <span>Extract (certificate) of business license, proof of registered office and tax registration documents</span>
                                                        </div>

                                                        :

                                                        <div>

                                                            <span>Extract (certificate) of business license, proof of registered office and tax registration documents</span>
                                                        </div>
                                        }
                                        {(companyDocumentsStatus?.Ownership?.status == "2" || !companyDocumentsStatus?.Ownership) && <div className="mt-4">
                                            <input
                                                class="form-control"
                                                type="file"
                                                id="ownershipStructureFile"
                                                onChange={(e) => handleFileChange(e.target.files[0], setOwnershipStructureFile)}
                                            />

                                            <div className="text-danger">
                                                only jpg, png and pdf files are allowed.
                                            </div>
                                            {errors.ownershipStructureFile && (
                                                <div className="text-danger">{errors.ownershipStructureFile}</div>
                                            )}
                                        </div>}
                                    </div>
                                </div>
                            </div>
                            <div className="row ">

                                <div className="card col-11 col-md-7 col-lg-8 col-sm-11 col-xs-11 text-start mx-auto mx-2 ">

                                    <div className="card-body">
                                        <h4 className="text-center ">Financial Documents</h4>
                                        {companyDocumentsStatus?.financial?.status == "2" && <div className="d-flex justify-content-center"> <BigCircleWithCross /></div>}
                                        {companyDocumentsStatus?.financial?.status == "1" && <div className="d-flex justify-content-center"> <BigCircleWithTick /></div>}
                                        {!companyDocumentsStatus ?
                                            <span>Extract (certificate) of business license, proof of registered office and tax registration documents</span>

                                            : companyDocumentsStatus?.financial?.status == "0" ?
                                                <span>Financial documents are under verification</span> :
                                                companyDocumentsStatus?.financial?.status == "1" ?
                                                    <div className="text-success text-center">
                                                        Financial documents are Verified
                                                    </div> :
                                                    companyDocumentsStatus?.financial?.status == "2" ?
                                                        <div>
                                                            <div className="text-danger text-center">
                                                                Financial documents are rejected. please submit again
                                                            </div>
                                                            <span>Extract (certificate) of business license, proof of registered office and tax registration documents</span>
                                                        </div> :
                                                        <div>
                                                            <span>Extract (certificate) of business license, proof of registered office and tax registration documents</span>
                                                        </div>
                                        }
                                        {(companyDocumentsStatus?.financial?.status == "2" || !companyDocumentsStatus.financial) && <div className="mt-4">
                                            <input
                                                class="form-control"
                                                type="file"
                                                id="financialDocumentsFile"
                                                onChange={(e) => handleFileChange(e.target.files[0], setFinancialDocumentsFile)}
                                            />
                                            <div className="text-danger">
                                                only jpg, png and pdf files are allowed.
                                            </div>
                                            {errors.financialDocumentsFile && (
                                                <div className="text-danger">{errors.financialDocumentsFile}</div>
                                            )}
                                        </div>}
                                    </div>
                                </div>
                            </div>
                            {(!companyDocumentsStatus?.financial || companyDocumentsStatus?.financial?.status == "2" ||
                                !companyDocumentsStatus?.Ownership || companyDocumentsStatus?.Ownership?.status == "2" ||
                                !companyDocumentsStatus?.business_registration || companyDocumentsStatus?.business_registration?.status == "2") && <div className="d-flex justify-content-center">
                                    <button disabled={loading} type="button" onClick={uploadDocs} class="btn btn-default d-flex justify-content-center align-items-center gap-2">
                                        Upload
                                        {loading && <div class="spinner-border" style={{ height: "16px", width: "16px" }} role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>}
                                    </button>
                                </div>}

                        </div>
                    </div>
                </div>
            </div> :
                <div className="text-center my-5 ">
                    <div class="spinner-border" style={{ height: "50px", width: "50px" }} role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>}


        </>
    )
}
export default CorporateDocuments;