import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Section1R = ({ content }) => {
    const [configSettings, setConfigSettings] = useState('')
    useEffect(() => {

        const scriptUrls = [
            "js/custom.min.js",
            "js/dlabnav-init.js",

        ];

        scriptUrls.forEach((scriptUrl) => {
            const script = document.createElement("script");
            script.src = scriptUrl;
            script.async = true;
            document.body.appendChild(script);
        });
        // Load each script asynchronously

        return () => {
            // Clean up: Remove the script elements when the component unmounts
            scriptUrls.forEach((scriptUrl) => {
                const script = document.querySelector(`[src="${scriptUrl}"]`);
                if (script) {
                    script.remove();
                }
            });
        };
    }, []);

    useEffect(() => {
        const config = JSON.parse(sessionStorage.getItem('configSettings')) 
        setConfigSettings(config)
    },[])
    const handleRedirect = (link) => {
        window.location.href = link;
        window.location.reload();

    };
    return (
        <div className="row business-container mt-5 mx-auto" style={{ paddingTop: "100px" }}>
            <div className="col-xl-6 mb-5">
                <h1
                    // style={{ fontSize: '80px', lineHeight: '90px', width: "90%", minWidth: '450px' }}
                    className="business-home-banner-text">
                    {content?.banner_title}
                </h1>
                <p
                    // style={{ fontSize: '18px', minWidth: '450px' }}
                    className="w-75 business-home-banner-subtitle">
                    {content?.banner_subtitle}
                </p>
                <div>
                    <Link to={"/open-account"}>  <button type="button" class="btn light btn-primary">OPEN ACCOUNT</button></Link>
                </div>
                <div className="d-flex flex-wrap gap-2 mt-3">
                    <a href={configSettings?.android_app_link} rel="noopener noreferrer">  <button type="button" class="btn p-0 light btn-primary"><img src="https://res.cloudinary.com/durotechindustries-com-au/image/upload/v1708345007/btws0c2yggjzfffdx8gy.png" alt="" /></button></a>
                    <a href={configSettings?.ios_app_link} rel="noopener noreferrer">  <button type="button" class="btn p-0 light btn-primary"><img src="https://res.cloudinary.com/durotechindustries-com-au/image/upload/v1708345008/yp8xdidc0cs3f0pe5t9s.png" alt="" /></button></a>
                </div>
            </div>
            <div className="col-xl-6">
                <div
                    className="
                    business-banner-image
                     bounce-animation mt-12 rounded-full mx-auto  d-flex justify-content-between align-items-center"
                // style={{
                //     borderStyle: "dashed", borderWidth: '2px', width: '500px',
                //     height: '500px', borderColor: '#3B82F6', borderRadius: '9999px', marginRight: 'auto'
                // }}
                >
                    <div className=" business-banner-image-top"
                    // style={{ position: 'relative', width: '450px', height: "300px", marginRight: 'auto' }}
                    >
                        <img className="business-banner-image-small-image1  "
                            // style={{ width: '450px', height: "300px" }}
                            src={content?.banner_image} alt=""
                        />
                        <img className=" business-banner-image-small-image2"
                            // style={{ position: 'absolute', left: "0px", bottom: "-2.5rem", zIndex: -10 }}
                            src="https://codegopay.com/business/assets/business_panel/img/hero/hero-shape-3-2.png" alt=""></img>
                        <img className="business-banner-image-small-image3"
                            // style={{ position: 'absolute', right: 0, top: "1.25rem", zIndex: -10 }}
                            src="https://codegopay.com/business/assets/business_panel/img/hero/hero-shape-3-1.png" alt=""></img>
                    </div>

                </div>
            </div>
        </div >
    )
}
export default Section1R