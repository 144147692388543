export const cardBackground = {
  backgroundColor: 'rgba(250, 249, 246, 0.8)'

  // Add other light mode styles as needed
};
export const cardBackground2 = {
  backgroundColor: '#F2F2F2',

  // Add other light mode styles as needed
};

export const circleBGLight = {
  background: '#F2F2F2'
}

export const textColor = {
  color: '#605E60',
}

export const cardDarkBG = {
  backgroundColor: "#B7B4B7"
}

export const sideBarBG = {
  backgroundColor: "white"
}