import { useEffect, useRef, useState } from "react"
import { API_ENDPOINTS, API_METHODS } from "../../../constants/endpoints"
import { whitelabel_id } from "../../../constants/ids"
import apiCall from "../../../utils/apiCall"

const Section1 = ({ selectedCountry, countries }) => {
    // const [countries, setCountries] = useState([])
    const [loading, setLoading] = useState(false)
    const selectRef = useRef()
    // useEffect(() => {
    //     getCountryList()
    // }, [])
    useEffect(() => {
        const scriptUrls = [
            "js/plugins-init/select2-init.js",
            "vendor/select2/js/select2.full.min.js",
            "vendor/bootstrap-material-datetimepicker/js/bootstrap-material-datetimepicker.js",
            "js/plugins-init/material-date-picker-init.js",
            "vendor/bootstrap-daterangepicker/daterangepicker.js",
            "vendor/moment/moment.min.js",
        ];
        scriptUrls.forEach((scriptUrl) => {
            const script = document.createElement("script");
            script.src = scriptUrl;
            script.async = true;
            document.body.appendChild(script);
        });

        return () => {
            scriptUrls.forEach((scriptUrl) => {
                const script = document.querySelector(`[src="${scriptUrl}"]`);
                if (script) {
                    script.remove();
                }

            });
        };


    }, []);
    // const getCountryList = async () => {
    //     const body = {
    //         whitelabel_id: whitelabel_id
    //     }
    //     const url = API_ENDPOINTS.GET_COUNTRY_LIST
    //     const method = API_METHODS.POST
    //     const response = await apiCall(url, method, body)
    //     if (response?.status == 1) {
    //         setCountries(response.country)
    //         setLoading(false)
    //     } else if (response?.status == 0) {
    //         console.error('Unable to get Countries list.')
    //         setLoading(false)
    //     } else {
    //         console.error('Unable to get Countries list.')
    //     }
    // }
    useEffect(() => {
        const intervalId = setInterval(() => {
            if (selectRef.current.value) {
                selectedCountry(selectRef.current.value)
            }
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);
    return (
        <div className="row mt-5 business-container mx-auto" style={{ paddingTop: "100px", }}>
            <div className="text-center">
                <h1 className="mx-auto">
                    Pricing to suite all size of business
                </h1>
                <div className=" mx-auto">
                    <span style={{ fontSize: '12px' }} className="mx-auto priceContainer">
                        *We help companies of all sizes.Get started with an online business account under your registered business name. You can upgrade or cancel your plan any time.
                    </span>
                </div>
                <div  className="mx-auto mt-5 priceContainer">
                    <select ref={selectRef} class="default-placeholder form-control" placeholder="Select Country">
                        <option value={0}>Select Country</option>
                        {countries?.length > 0 && countries.map((c) => {
                            return <option value={c.risk_level}>{c.name}</option>
                        })}
                    </select>
                </div>
            </div>

        </div >
    )
}
export default Section1